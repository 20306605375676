import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"
import SearchNav from "../features/search/SearchNav"
import SearchPage from "../features/search/SearchPage"

export default function AdvancedSearchRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="search" header={<SearchNav activeTab="search" />} body={<SearchPage view="search" recordSelected={true} />} sidebarType="clients" />
            </Route>
        </Switch>
    )
}
