import React from "react"
import { Form, Input, Row, Col, Switch } from "antd"
import ReportAssignee from "./ReportAssignee"
import { useSelector } from "react-redux"
import { selectGeneratedReport } from "./reportsSlice"

export default function ReportGlobals({ form, actionName }) {
    const generatedReport = useSelector(selectGeneratedReport)

    return (
        <div>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Title" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <ReportAssignee form={form} record={generatedReport} />

            {actionName === "edit" && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Regenerate Report" name="regenerate">
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </div>
    )
}
