import React from "react"

import CostParameterTable from "./components/CostParameterTable"
import CostParameterForm from "./components/CostParameterForm"

export default function CostParameterPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <CostParameterTable />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <CostParameterForm actionName="edit" />
        }

        return <CostParameterForm actionName="new" />
    }

    return <>{renderView()}</>
}
