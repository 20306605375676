import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import { Table, Tooltip, Skeleton, Button, Dropdown, Menu } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, DownOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import nodePath from "path"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"

import { fetchServices, selectServices, deleteService, selectTotal, selectPagination, selectLoading } from "../servicesSlice"

import Role from "../../userRoles/Role"

export default function ServiceTable({ scopedByClient }) {
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const services = useSelector(selectServices)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            // scopedByClient
            //     ? dispatch(fetchClientServices(clientId, pagination, nodePath.join(url, "")))
            //     : dispatch(fetchServices(pagination, nodePath.join(url, "")))
            dispatch(fetchServices(pagination, nodePath.join(url, "")))
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteService, id);
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        dispatch(fetchServices(newPagination, nodePath.join(url, "")))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Services</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <Role action="create" model="service">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                {/* <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a>

                                <a href="javascript:;" onClick={() => console.log("Export")}>
                                    <DownloadOutlined />
                                    Export
                                </a> */}
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={services} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column title="Sort Order" key="rank" dataIndex="rank" />
                            <Table.Column title="Service Name 1" key="service_name_1" dataIndex="service_name_1" />
                            <Table.Column title="Service Name 2" key="service_name_2" dataIndex="service_name_2" />
                            <Table.Column title="Meter Number" key="meter_number" dataIndex="meter_number" />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="service">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={`${url}/${record.id}/edit`}>Edit Service</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="destroy" model="service">
                                                        <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                            Delete Service
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
