import React from "react"
import { useSelector } from "react-redux"
import { selectResults } from "../searchSlice"
import { Table } from "antd"
import { titleize } from "../../../lib/string"
import { FilePdfOutlined } from "@ant-design/icons"

export default function AdvancedSearchResults() {
    const results = useSelector(selectResults)

    return (
        <div>
            <h1 className="search-heading">Search Results</h1>
            <Table dataSource={results} rowKey={(r) => `${r.type}-${r.id}`}>
                <Table.Column
                    title="Account Number"
                    key="account_number"
                    dataIndex="account_number"
                    sorter={{ compare: (a, b) => a.account_number?.localeCompare(b.account_number) }}
                    showSorterTooltip={false}
                    render={(account_number, account) =>
                        (<a href={`/clients/${account.client_id}/${account.type}/${account.id}/bills`} target="_blank">
                            {account_number}
                        </a>)
                    }
                />
                <Table.Column title="Account Name" key="account_name" dataIndex="name" sorter={{ compare: (a, b) => a.name?.localeCompare(b.name) }} showSorterTooltip={false} />

                <Table.Column
                    title="Client Name"
                    key="client_name"
                    dataIndex="client_name"
                    showSorterTooltip={false}
                    render={(client_name, account) =>
                        (<a href={`/clients/${account.client_id}/dashboard`} target="_blank">
                            {client_name}
                        </a>)
                    }
                />

                <Table.Column title="Vendor" key="vendor_name" dataIndex="vendor_name" sorter={{ compare: (a, b) => a.vendor_name?.localeCompare(b.vendor_name) }} showSorterTooltip={false} />

                <Table.Column title="Type" key="type" dataIndex="type" sorter={{ compare: (a, b) => a.type?.localeCompare(b.type) }} showSorterTooltip={false} render={(i) => titleize(i)} />

                <Table.Column title="Services" key="services" dataIndex="services" sorter={{ compare: (a, b) => a.services?.localeCompare(b.services) }} showSorterTooltip={false} />

                <Table.Column
                    title="Facility Code"
                    key="facility_code"
                    dataIndex="facility_code"
                    sorter={{ compare: (a, b) => a.facility_code?.localeCompare(b.facility_code) }}
                    showSorterTooltip={false}
                />

                <Table.Column
                    title="Facility Name"
                    key="facility_name"
                    dataIndex="facility_name"
                    sorter={{ compare: (a, b) => a.facility_name?.localeCompare(b.facility_name) }}
                    showSorterTooltip={false}
                />

                <Table.Column
                    title="Last Bill Date"
                    key="last_bill_date"
                    dataIndex="last_bill_date"
                    sorter={{ compare: (a, b) => a.last_bill_date?.localeCompare(b.last_bill_date) }}
                    showSorterTooltip={false}
                />

                <Table.Column
                    title="Bill Image"
                    key="last_bill_file_url"
                    dataIndex="last_bill_file_url"
                    showSorterTooltip={false}
                    render={(url) =>
                        url ? (
                            <a href={url} target="_blank">
                                <FilePdfOutlined />
                            </a>
                        ) : null
                    }
                />
            </Table>
        </div>
    )
}
