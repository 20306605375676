import React, { useEffect, useState } from "react"
import { Row, Col } from "antd"
import { fetchAuditLog, selectFilter, selectPagination, reset } from "./auditLogSlice"
import AuditLogFilter from "./components/AuditLogFilter"
import AuditLogTimeline from "./components/AuditLogTimeline"
import { useDispatch, useSelector } from "react-redux"

export default function AuditLogPage() {
    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)
    const filter = useSelector(selectFilter)

    const [loaded, setLoaded] = useState(false)
    const [fetchRecords, setFetchRecords] = useState(false)

    /**
     * The initial useEffect that runs based on the `loaded` state variable. This will
     * reset the auditLogSlice attributes, and then fetch the records with the initialState attrs
     */
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(reset())
            setFetchRecords(true)
        }
    }, [loaded, dispatch])

    /**
     * The useEffect that runs when fetchRecords is true... when the initial state
     * has been set and the auditLogSlice has been reset.
     */
    useEffect(() => {
        if (fetchRecords) {
            setFetchRecords(false)
            dispatch(fetchAuditLog(pagination, filter))
        }
    }, [fetchRecords, dispatch, pagination, filter])

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <div className="card">
                        <div className="card-body" style={{ alignItems: "normal" }}>
                            <AuditLogFilter />
                        </div>
                    </div>
                </Col>

                <Col span={2}></Col>

                <Col span={16}>
                    <AuditLogTimeline />
                </Col>
            </Row>
        </>
    )
}
