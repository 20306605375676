import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {  ArrowDownOutlined, LoadingOutlined, RollbackOutlined, SearchOutlined, } from "@ant-design/icons"
import { useParams, useRouteMatch } from "react-router-dom"
import { Table, Skeleton,  Checkbox, Form, InputNumber, Button, notification, Spin } from "antd"
import {
    selectBills,
    selectTotal,
    selectPagination,
    selectLoading,
    fetchGlobalBills,
    filterBatch,
    selectSumTotal,
    setSumTotal,
    selectCheckedBills,
    setCheckedBills,
    generateCsv,
    submitGlobalBatch,
    getPaymentBatchInfo,
    selectBatchCreationStatus,
    selectBankValue,
} from "../globalBatchSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnDateFilter from "../../../components/utilityComponents/table/ColumnDateFilter"
import GlobalBatchFilters from "./GlobalBatchFilters"
import requestNotification from "../../../lib/notifications"
import CheckedBillsTable from "./CheckedBillsTable"
import { tableColInfo, tableColInfo2 } from "../tableFormats/tables"
import { disabledDate, formattedDateRange } from "../consts/date"
import { selectUnpaid, selectFilteredFormat, selectFilteredDateRange, setFilteredClients, setFilteredPaymentMethods } from "../globalBatchFilterSlice"

export default function GlobalBatchTable() {
    const { url } = useRouteMatch()
    const { globalBatchId } = useParams()
    /////*** redux start ***/////
    const dispatch = useDispatch()

    const globalBatch = useSelector(selectBills)
    const sumTotal = useSelector(selectSumTotal)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const unpaid = useSelector(selectUnpaid)
    const tableFormat = useSelector(selectFilteredFormat)
    const checkedBills = useSelector(selectCheckedBills)
    const filteredDateRange = useSelector(selectFilteredDateRange)
    const batchCreationStatus = useSelector(selectBatchCreationStatus)
    const checkedBillsDivRef = useRef(null)
    const bankValue = useSelector(selectBankValue)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({ due_date_lteq: formattedDateRange[1] })
    const [sort, setSort] = useState({
        column: "due_date",
        direction: "asc",
    })
    const [form] = Form.useForm()
    const [currPaymentType, setCurrPaymentType] = useState('')
    const [paymentBatchId, setPaymentBatchId] = useState(null)
    const [paymentBatchBills, setPaymentBatchBills] = useState([])
    const [payTypeForAddingBills, setPayTypeForAddingBills] = useState('')
    // const [checkedBills, setCheckedBills] = useState([])
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            if (!globalBatchId) handleFetchInitialBatch()
        }
    }, [loaded, dispatch, pagination]) // eslint-disable-line

    useEffect(() => {
        async function getBatchInfo(){
            const batchInfo = await dispatch(getPaymentBatchInfo(globalBatchId))
            const { client_id, batch_date, bills_due_date, payment_method, payment_batch_id, bills, temp_pay_method } = batchInfo
            setPaymentBatchId(payment_batch_id)
            setPaymentBatchBills(bills)
            setPayTypeForAddingBills(temp_pay_method ? temp_pay_method : payment_method)
            
            const batchFilter = { due_date_lteq: bills_due_date, client_ids: `${client_id},`, payment_method_names: payment_method }
            setFilters(batchFilter)
            await dispatch(setFilteredClients({ value: client_id }))
            await dispatch(setFilteredPaymentMethods({ value: payment_method }))
            await dispatch(filterBatch({ filters: batchFilter, sort: sort, pagination: pagination }, true))
        }
        if(globalBatchId){
            getBatchInfo()
        }
    }, [globalBatchId])
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    const handleFetchInitialBatch = () => {
        dispatch(fetchGlobalBills(pagination, sort))
    }

    const handleFilterBatch = (newPagination = null, newSort = null) => {
        // console.log(filters)
        let filterJson = {
            filters: filters,
            pagination: !!newPagination ? newPagination : pagination,
            sort: !!newSort ? newSort : sort,
        }
        if (unpaid) filterJson.unpaid = true
        return dispatch(
            filterBatch(filterJson)
        )
    }

    const handleSortBatchChange = (column) => () => {
        // console.log(sort.column, sort.direction, column)
        let newDirection = 'asc'

        if(sort.column == column){
            newDirection = sort.direction === 'asc' ? "desc" : 'asc'
        }

        // newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterBatch(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        // console.log(newPagination, sorter)
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchGlobalBills(newPagination))
        } else {
            handleFilterBatch(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const globalBatchCsv = async (type = 'Avid') => {
        if(currPaymentType == '') return
        else if(currPaymentType != 'Avid') type = 'other'
        let billIds = checkedBills
            .map((bill, i) => {
                return bill.id
            })
            .join(",")
        notification.open({
            message: "Global batch bills export started",
            duration: 0.8,
        })

        dispatch(generateCsv(billIds, type))
    }

    const renderColumnHeaderTitle = (title, key = '') => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortBatchChange} sort={sort} replacementTitle={key} />
    }

    const updateBatch = async (values) => {
        const billIds = []
        const clientIds = []
        const bills_attributes = []

        for (let i = 0; i < checkedBills.length; i++) {
            const { id, client_id, amount_paid } = checkedBills[i]
            billIds.push(id)
            clientIds.push(client_id)
            bills_attributes.push({ id: id, amount_paid: amount_paid })
        }
        
        const response = await dispatch(submitGlobalBatch({ bill_ids: billIds, bills_attributes: bills_attributes, clientIds: clientIds }, globalBatchId))

        requestNotification(response)
    }

    // const renderResetFilters = () => {
    //     if (allObjectValuesEmpty(filters)) {
    //         return null
    //     }
    //     return (
    //         <div className={"actions-alternate-buttons"}>
    //             <a
    //                 href="#"
    //                 onClick={() => {
    //                     handleFetchInitialBatch()
    //                     setFilters({})
    //                     setSort({ column: "client_name", direction: "asc" })
    //                 }}>
    //                 <FilterOutlined />
    //                 Reset Filters
    //             </a>
    //         </div>
    //     )
    // }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    function AmountPaidInputRender(record){
        {
            const { id, batch_id, client_id, client_name, vendor_name, vendor_short_code, payment_date, due_date, current_charges, vendor_payment_methods, vendor_id } = record
            const value = getAmountPaid(record)
            return (
                <InputNumber
                    value={value}
                    defaultValue={value}
                    precision={2}
                    controls={false}
                    disabled={record.paid}
                    style={{ float: "right" }}
                    //   onChange={(e) => console.log(e, 'here')}
                    onBlur={(e) => {
                        const twoDecimalNumRegex = /^\d+(\.\d{0,2})?$/
                        const val = e.target.value
                        const newVal = twoDecimalNumRegex.test(val) ? parseFloat(val) : parseFloat(value)
                        const newSum = checkForBill(record.id).length > 0 ? sumTotal - value + newVal : sumTotal + newVal
                        if (newVal != value) {
                            dispatch(setSumTotal(newSum))
                            dispatch(
                                setCheckedBills({
                                    value: {
                                        id: id,
                                        amount_paid: newVal,
                                        batch_id: batch_id,
                                        client_id: client_id,
                                        client_name: client_name,
                                        vendor_name: vendor_name,
                                        vendor_short_code: vendor_short_code,
                                        payment_date: payment_date,
                                        due_date: due_date,
                                        current_charges: current_charges,
                                        vendor_payment_methods: vendor_payment_methods,
                                        vendor_id: vendor_id,
                                    },
                                    id: id,
                                })
                            )
                        }
                    }}
                />
            )
        }
    }

    // Component which represents a column in the table. The column props are in globalBatchSlice.js
    function TableCol(info) {
        const { header, key, dataIndex, filterColumn, filterType, filterIcon, columnFilterType, render, filterDropdown = true, title } = info
        if(filterDropdown){
            return (
                <Table.Column
                    title={title ? title : renderColumnHeaderTitle(key, header)} // Header second incase the title is different from db table column name
                    key={key}
                    showSorterTooltip={false}
                    dataIndex={dataIndex}
                    filterDropdown={({ visible }) => (
                        <>
                            {columnFilterType == "search" && (
                                <ColumnSearchFilter
                                    handleFetchInitial={handleFetchInitialBatch}
                                    filterAction={filterBatch}
                                    filterColumn={filterColumn}
                                    filterType={filterType}
                                    filters={filters}
                                    pagination={pagination}
                                    setFilters={setFilters}
                                    sort={sort}
                                    visible={visible}
                                />
                            )}
                            {columnFilterType == "date" && (
                                <ColumnDateFilter
                                    handleFetchInitial={handleFetchInitialBatch}
                                    filterAction={filterBatch}
                                    filterColumn={filterColumn}
                                    filterType={filterType}
                                    filters={filters}
                                    pagination={pagination}
                                    setFilters={setFilters}
                                    sort={sort}
                                    setSort={setSort}
                                    visible={visible}
                                    disabledDate={disabledDate}
                                />
                            )}
                        </>
                    )}
                    filterIcon={() => renderSearchOutlinedButton(filterIcon)}
                    render={
                        key == "amount_paid"
                            ? (text, record, index) => {
                                  return AmountPaidInputRender(record)
                              }
                            : render
                    }
                />
            )
        }else{
            return (
                <Table.Column
                    title={title ? title : renderColumnHeaderTitle(key, header)} // Header second incase the title is different from db table column name
                    key={key}
                    showSorterTooltip={false}
                    dataIndex={dataIndex}
                    render={
                        key == "amount_paid"
                            ? (text, record, index) => {
                                  return AmountPaidInputRender(record)
                              }
                            : render
                    }
                />
            )
        }
    }

    function getTable(format){ 
        switch(format){
            case 2:
                return tableColInfo2
            default:
                return tableColInfo
        }
    }

    function checkForBill(id){
        return checkedBills.filter((bill, i) => bill.id === id)
    }

    function getAmountPaid(record) {
        const { id, amount_paid, current_charges } = record
        const bill = checkForBill(id)
        if (bill.length == 0) {
            return amount_paid ? amount_paid : current_charges
        } else {
            return bill[0].amount_paid
        }
    }

    const updateChecked = (e, bill, amount_paid, array = false, total = false) => {
        // console.log(checkedBills)
        if (e.target.checked) {
            const { id, batch_id, client_id, client_name, vendor_name, vendor_short_code, payment_date, due_date, current_charges, vendor_payment_methods, vendor_id } = bill
            dispatch(setSumTotal(sumTotal + parseFloat(total ? total : amount_paid))) // TODO: Make sure this should be amount and not amount_paid. FOR NOW: Putting amount_paid seems more logical
            dispatch(
                setCheckedBills(
                    checkedBills.concat(
                        !array
                            ? {
                                  id: id,
                                  amount_paid: parseFloat(amount_paid),
                                  batch_id: batch_id,
                                  client_id: client_id,
                                  client_name: client_name,
                                  vendor_name: vendor_name,
                                  vendor_short_code: vendor_short_code,
                                  payment_date: payment_date,
                                  due_date: due_date,
                                  current_charges: current_charges,
                                  vendor_payment_methods: vendor_payment_methods,
                                  vendor_id: vendor_id,
                              }
                            : array
                    )
                )
            )
        } else {
            if (!array) {
                const { id } = bill
                dispatch(setSumTotal(sumTotal - parseFloat(amount_paid)))
                dispatch(setCheckedBills(checkedBills.filter((bill) => bill.id !== id)))
            } else {
                let totalPaid = 0
                const idArr = []
                for (let i = 0; i < array.length; i++) {
                    const amountPaid = parseFloat(array[i].amount_paid)
                    const id = array[i].id
                    totalPaid += amountPaid
                    idArr.push(id)
                }
                dispatch(setSumTotal(sumTotal - totalPaid))
                dispatch(setCheckedBills(checkedBills.filter((bill) => !idArr.includes(bill.id))))
            }
        }
    }

    const checkBillId = (checkId) => {
        for(let i = 0; i < checkedBills.length; i++){
            const id = checkedBills[i].id
            if(id == checkId) return true
        }
        return false
    }

    return (
        <div>
            <GlobalBatchFilters filters={filters} setFilters={setFilters} sort={sort} loading={loading} />
            <div className="muc-table card" style={{ marginBottom: "50px" }}>
                <div className="card-body">
                    <div>
                        <div className="card-body-heading">
                            <div>
                                <div className="badged-header">
                                    <h2>Global Batch</h2>
                                    <div className="badge">{total}</div>
                                </div>
                                <div className="badged-header" style={{ gap: 10, color: "white" }}>
                                    <h4>Amount Due: </h4>
                                    <div className="global-batch-filter-label-text">${sumTotal.toLocaleString()}</div>
                                </div>
                            </div>
                            <div className="actions">
                                <button
                                    onClick={() => {
                                        dispatch(setCheckedBills([]))
                                        dispatch(setSumTotal(0))
                                    }}>
                                    <RollbackOutlined style={{ paddingRight: "10px" }} />
                                    Reset selected bills
                                </button>
                                <button onClick={() => scrollToSection(checkedBillsDivRef)}>
                                    <ArrowDownOutlined style={{ paddingRight: "10px" }} />
                                    Go to selected bills
                                </button>
                            </div>
                        </div>

                        {!bankValue.locked ? (
                            <div style={{ fontSize: 16 }}>{bankValue.value == 0 ? "Must input and lock initial bank value before creating batches." : "Bank value must be locked to continue batching."}</div>
                        ) : (
                            <Skeleton active loading={loading}>
                                <Table
                                    dataSource={globalBatch}
                                    pagination={{ ...pagination, showSizeChanger: true, total: total, pageSizeOptions: [10, 20, 50, 100, 1000] }}
                                    onChange={handleTableChange}
                                    rowKey={(r) => r.id}>
                                    <Table.Column
                                        key="in"
                                        dataIndex="in"
                                        title={
                                            <Checkbox
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        const updateArr = []
                                                        let total = 0
                                                        for (let i = 0; i < globalBatch.length; i++) {
                                                            const currBatch = globalBatch[i]
                                                            const amountPaid = getAmountPaid(currBatch)
                                                            const {
                                                                id,
                                                                batch_id,
                                                                client_id,
                                                                client_name,
                                                                vendor_name,
                                                                vendor_short_code,
                                                                payment_date,
                                                                due_date,
                                                                current_charges,
                                                                vendor_payment_methods,
                                                                vendor_id,
                                                            } = currBatch

                                                            // If batch is not already selected, select batch
                                                            if (!checkBillId(id)) {
                                                                total += parseFloat(amountPaid)
                                                                updateArr.push({
                                                                    id: id,
                                                                    amount_paid: parseFloat(amountPaid),
                                                                    batch_id: batch_id,
                                                                    client_id: client_id,
                                                                    client_name: client_name,
                                                                    vendor_name: vendor_name,
                                                                    vendor_short_code: vendor_short_code,
                                                                    payment_date: payment_date,
                                                                    due_date: due_date,
                                                                    current_charges: current_charges,
                                                                    vendor_payment_methods: vendor_payment_methods,
                                                                    vendor_id: vendor_id,
                                                                })
                                                            }
                                                        }
                                                        updateChecked(e, globalBatch, null, updateArr, total)
                                                    } else {
                                                        const updateArr = []
                                                        for (let i = 0; i < globalBatch.length; i++) {
                                                            const currBatch = globalBatch[i]
                                                            const amountPaid = parseFloat(getAmountPaid(currBatch))
                                                            const { id } = currBatch

                                                            // If batch is already selected, de-select batch
                                                            if (checkBillId(id)) {
                                                                updateArr.push({
                                                                    id: id,
                                                                    amount_paid: amountPaid,
                                                                })
                                                            }
                                                        }
                                                        updateChecked(e, null, null, updateArr)
                                                    }
                                                }}
                                            />
                                        }
                                        render={(text, record, index) => {
                                            return (
                                                <Checkbox
                                                    defaultChecked={false}
                                                    checked={checkBillId(record.id)}
                                                    onChange={(e) => {
                                                        const amountPaid = getAmountPaid(record)
                                                        const payMethod = record.vendor_payment_methods
                                                        if (e.target.checked) {
                                                            if (currPaymentType == "" || currPaymentType == payMethod) {
                                                                updateChecked(e, record, amountPaid)
                                                                // if (currPaymentType == "") setCurrPaymentType(payMethod)
                                                            } else {
                                                                notification.open({
                                                                    message: "All selected bills must have the same payment type",
                                                                    duration: 5,
                                                                    description: `Your selection has a payment type of ${payMethod}, while your other selection(s) are of type ${currPaymentType}`,
                                                                })
                                                            }
                                                        } else {
                                                            // if (checkedBills.length == 1) setCurrPaymentType("")
                                                            updateChecked(e, record, amountPaid)
                                                        }
                                                    }}
                                                    // disabled={paymentBatch.closed}
                                                />
                                            )
                                        }}
                                    />
                                    {getTable(tableFormat)?.map((info, i) => TableCol(info))}
                                </Table>
                            </Skeleton>
                        )}
                    </div>
                </div>
            </div>
            <div ref={checkedBillsDivRef}>
                <CheckedBillsTable
                    bills={checkedBills}
                    updateChecked={updateChecked}
                    sumTotal={sumTotal}
                    setSumTotal={setSumTotal}
                    setCheckedBills={setCheckedBills}
                    dispatch={dispatch}
                    getAmountPaid={getAmountPaid}
                    filteredDateRange={filteredDateRange}
                    loading={loading}
                    paymentBatchId={paymentBatchId}
                    paymentBatchBills={paymentBatchBills}
                    payTypeForAddingBills={payTypeForAddingBills}
                />
            </div>
        </div>
    )
}
