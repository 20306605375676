import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Select, Switch, DatePicker, InputNumber } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { fetchPaymentBatch, setPaymentBatch, selectPaymentBatch, submitPaymentBatch } from "../paymentBatchesSlice"
import { fetchVendorSelectOptions, selectVendorSelectOptions } from "../../../vendors/vendorsSlice"
import { fetchClientDivisionSelectOptions, selectDivisionSelectOptions } from "../../../divisions/divisionsSlice"
import { selectScopedClient } from "../../../../stores/appSlice"

import { requestNotification } from "../../../../lib/notifications"
import DateMask from "../../../forms/components/DateMask"

const { Option } = Select
const dateFormat = "MM/DD/YYYY"
const { TextArea } = Input

export default function PaymentBatchForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const client = useSelector(selectScopedClient)
    const paymentBatch = useSelector(selectPaymentBatch)
    const vendorSelectOptions = useSelector(selectVendorSelectOptions)
    const divisionSelectOptions = useSelector(selectDivisionSelectOptions)
    const { paymentBatchId, clientId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [payerSelectOptions, setPayerSelectOptions] = useState([])

    useEffect(() => {
        if (paymentBatchId) {
            dispatch(fetchPaymentBatch(paymentBatchId))
        } else {
            dispatch(setPaymentBatch({}))
        }
    }, [dispatch, paymentBatchId])

    useEffect(() => {
        form.resetFields()
        dispatch(fetchVendorSelectOptions(clientId))
        dispatch(fetchClientDivisionSelectOptions(clientId))

        if (paymentBatch && paymentBatch.payer_type === "Division") {
            setPayerSelectOptions(divisionSelectOptions)
        } else {
            if (client && client.name) {
                setPayerSelectOptions([{ id: parseInt(clientId), name: client.name }])
            }
        }
    }, [dispatch, paymentBatch, client]) //eslint-disable-line

    const handlePayerTypeSelect = (value) => {
        form.setFieldsValue({ payer_id: null, payer_type: value })

        if (value === "Division") {
            setPayerSelectOptions(divisionSelectOptions)
        } else {
            setPayerSelectOptions([{ id: parseInt(clientId), name: client.name }])
            form.setFieldsValue({ payer_id: parseInt(clientId) })
        }
    }

    const submit = async (values) => {
        let paymentBatchValuesParams = { ...values, client_id: clientId }
        paymentBatchValuesParams.batch_date = format_date(paymentBatchValuesParams.batch_date);
        paymentBatchValuesParams.bills_due_date = format_date(paymentBatchValuesParams.bills_due_date);
        const response = await dispatch(submitPaymentBatch(paymentBatchValuesParams, paymentBatchId, clientId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    function format_date(date){
        return moment.isMoment(date) ? date.format('YYYY-MM-DD') : moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
    }

    // console.log(paymentBatch)

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}

            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form
                layout="vertical"
                form={form}
                initialValues={{ ...paymentBatch, batch_date: moment(paymentBatch.batch_date || new Date()), bills_due_date: moment(paymentBatch.bills_due_date || new moment().add(14, "d")) }}
                onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Payer Type" name="payer_type" rules={[{ required: true }]}>
                                        <Select showArrow={true} onChange={handlePayerTypeSelect} disabled={actionName === "edit"}>
                                            <Option key={1} value="Client">
                                                Client
                                            </Option>

                                            <Option key={2} value="Division">
                                                Division
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Payer" name="payer_id" rules={[{ required: true }]}>
                                        <Select
                                            showArrow={true}
                                            disabled={actionName === "edit"}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {payerSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.name}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Closed" name="closed" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Vendor" name="vendor_ids">
                                        <Select placeholder={"Automatically added from bills"} showArrow={true} mode="multiple" disabled>
                                            {vendorSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Batch Date" name="batch_date" rules={[{ required: true }]}>
                                        <DatePicker
                                            format={dateFormat}
                                            style={{ width: "100%" }}
                                            allowClear={false}
                                            onChange={(date) => {
                                                let dueDate = moment(date).add(14, "d")
                                                form.setFieldsValue({ bills_due_date: dueDate })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Bills Due on or Before" name="bills_due_date" rules={[{ required: true }]}>
                                        <DatePicker format={dateFormat} style={{ width: "100%" }} allowClear={false} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Notes" name="notes" rules={[{ required: false }]}>
                                        <TextArea />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Batch Total" name="batch_total" rules={[{ required: false }]}>
                                        <InputNumber disabled={true} precision={2} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
