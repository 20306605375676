import React from "react"
import { useSelector } from "react-redux"
import { Form, Input, InputNumber, Select, Radio } from "antd"

import { selectFacilitySelectOptions } from "../../../../facilities/facilitiesSlice"

function SiteSurveysGeneralForm(props) {
    /////*** redux start ***/////
    const facilities = useSelector(selectFacilitySelectOptions)
    /////*** redux end ***/////

    /////*** render methods start ***/////
    const renderFacilitySelectOptions = () => {
        return facilities.map((facility) => {
            return (
                <Select.Option key={facility.id} value={facility.id} label={facility.name}>
                    {facility.name}
                </Select.Option>
            )
        })
    }
    /////*** render methods end ***/////

    return (
        <div id={"site-surveys-general-form"} className={"card"}>
            <Form.Item name={"facility_id"} label={"Facility"} rules={[{ required: true }]}>
                <Select showSearch filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                    {renderFacilitySelectOptions()}
                </Select>
            </Form.Item>

            <Form.Item name={"name"} label={"Survey Name"} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item name={"property_type"} label={"Property Type"}>
                <Radio.Group>
                    <Radio value={0}>Owned</Radio>
                    <Radio value={1}>Leased</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item name={"primary_business"} label={"Primary Business"}>
                <Input />
            </Form.Item>

            <Form.Item name={"property_year"} label={"Property Year"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item name={"number_of_buildings"} label={"Number Of Buildings"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item name={"square_footage"} label={"Square Footage"} rules={[{ type: "number" }]}>
                <InputNumber precision={4} />
            </Form.Item>

            <Form.Item name={"notes"} label={"Notes"}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysGeneralForm
