import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Button, Skeleton, Row, Col } from "antd"
import moment from "moment"
import Role from "../../../userRoles/Role"

import WarningChip from "./WarningChip"
// import BillAccountLink from "../../../components/utilityComponents/linkGenerators/BillAccountLink"
import { fetchLastFiveBillsForService } from "../../../services/servicesSlice"
import ServiceBillServiceLink from "../../../../components/utilityComponents/linkGenerators/ServiceBillServiceLink"
import { roundHumanizedNumbers, formatCurrency } from "../../../../lib/utilities"

export default function BillTable({ serviceId }) {
    const dispatch = useDispatch()
    let { clientId } = useParams()

    const [showHistory, setShowHistory] = useState(false)
    const [serviceHistory, setServiceHistory] = useState(null)
    const [historyTotal, setHistoryTotal] = useState(0)

    const fetchAndShowData = async () => {
        if (!serviceHistory) {
            const reppy = await dispatch(fetchLastFiveBillsForService(serviceId))
            setServiceHistory(reppy.last_five_bills)
            setHistoryTotal(reppy.total)
        }

        setShowHistory(!showHistory)
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Button type="primary" style={{ marginBottom: "20px" }} onClick={fetchAndShowData}>
                        {`${showHistory ? "Hide" : "Show"} History`}
                    </Button>
                </Col>
            </Row>

            {showHistory && (
                <Row gutter={24} style={{ marginBottom: "30px" }}>
                    <Col span={24}>
                        <div className="muc-table card">
                            <div className="card-body">
                                <div>
                                    <Table dataSource={serviceHistory} rowKey={(r) => r.id} pagination={false}>
                                        <Table.Column
                                            title=" "
                                            key="flags"
                                            dataIndex="flags"
                                            render={(flags) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} />}
                                            width={50}
                                        />
                                        <Table.Column
                                            title="Service/Meter"
                                            key="service_id"
                                            dataIndex="service_id"
                                            render={(text, record) => (
                                                <ServiceBillServiceLink
                                                    serviceType={record.service_utility_service_name}
                                                    serviceNumber={record.service_meter_number}
                                                    serviceId={record.service_id}
                                                    accountId={record.billable_account_id}
                                                    clientId={clientId}
                                                />
                                            )}
                                        />
                                        <Table.Column title="Read Date" key="read_date" dataIndex="read_date" render={(text, record, index) => moment(text).format("L")} />
                                        <Table.Column title="Meter Reading" key="meter_reading" dataIndex="meter_reading" />
                                        <Table.Column title="Usage" key="usage" align="right" dataIndex="usage" render={(text, record, index) => roundHumanizedNumbers(text)} />
                                        <Table.Column title="Unit" key="unit_symbol" dataIndex="unit_symbol" />
                                        <Table.Column title="Utility Charges" key="utility_charges" dataIndex="utility_charges" align="right" render={(text, record, index) => formatCurrency(text)} />
                                        <Table.Column title="Utility Taxes" key="utility_taxes" dataIndex="utility_taxes" align="right" render={(text, record, index) => formatCurrency(text)}/>
                                        <Table.Column title="Supply Charges" key="supply_charges" dataIndex="supply_charges" align="right" render={(text, record, index) => formatCurrency(text)}/>
                                        <Table.Column title="Supply Taxes" key="supply_taxes" dataIndex="supply_taxes" align="right" render={(text, record, index) => formatCurrency(text)}/>
                                        <Table.Column title="Other Charge" key="other_charge" dataIndex="other_charge" align="right" render={(text, record, index) => formatCurrency(text)}/>
                                        <Table.Column title="Rec Charge" key="rec_charge" dataIndex="rec_charge" align="right" render={(text, record, index) => formatCurrency(text)}/>
                                        <Table.Column title="Components" key="cost_components" dataIndex="cost_components" />
                                        <Role action="read" model="bill_notes">
                                            <Table.Column title="Notes" key="notes" dataIndex="notes" />
                                        </Role>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}
