import { Button, Checkbox, InputNumber, Modal, notification, Skeleton, Table } from 'antd'
import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { createBatches, selectBatchCreationStatus, setBatchCreationStatus, setLoading, submitGlobalBatch } from '../globalBatchSlice'
import {  CloudUploadOutlined, DownloadOutlined, FilterOutlined, PlusOutlined, RollbackOutlined, SearchOutlined, } from "@ant-design/icons"
import useConfirmGlobalBatchCreationModal from "../../alertsPanel/ShowConfirmGlobalBatchCreation"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from "react-router-dom"
import { error } from 'highcharts'
import requestNotification from '../../../lib/notifications'
import moment from 'moment'

export default function CheckedBillsTable({
    bills,
    updateChecked,
    dispatch,
    sumTotal,
    setSumTotal,
    setCheckedBills,
    getAmountPaid,
    filteredDateRange,
    loading,
    paymentBatchId,
    paymentBatchBills,
    payTypeForAddingBills,
}) {
    const batchCreationStatus = useSelector(selectBatchCreationStatus)
    const { globalBatchId } = useParams()
    const modalContent = {
        empty: { content: [] },
        success: {
            onCancel: () => {
                dispatch(setBatchCreationStatus(""))
                window.location.reload()
                setOpenModal(false)
            },
            content: [<h3 key="success_title">Batches were created successfully!</h3>, <p key="success_content">Would you like to:</p>],
            footer: [
                // <Button type="primary" href={"/global_batch/view"}>
                //     All batches
                // </Button>,
                <Button type="primary" href={"/global_batch/todays_batches"}>
                    Go to Today's Batches
                </Button>,
                <Button
                    type="back"
                    onClick={() => {
                        dispatch(setBatchCreationStatus(""))
                        window.location.reload()
                        setOpenModal(false)
                    }}>
                    Stay on page
                </Button>,
            ],
        },
        error: {
            onCancel: () => {
                dispatch(setBatchCreationStatus(""))
                setOpenModal(false)
            },
            content: [<h3 key="error_title">An error occurred during the creation</h3>, <p key="error_content">Please check that everything is correct.</p>],
            footer: [
                <Button
                    type="back"
                    onClick={() => {
                        dispatch(setBatchCreationStatus(""))
                        setOpenModal(false)
                    }}>
                    Continue
                </Button>,
            ],
        },
    }

    const addToBatchModalContent = {
        empty: { content: [] },
        success: {
            onCancel: () => {
                setOpenModal(false)
                window.location.replace("/global_batch")
            },
            content: [<h3 key="success_title">Bills were added to batch successfully!</h3>, <p key="success_content">Would you like to:</p>],
            footer: [
                <Button
                    key={'todays_batches_for_type'}
                    type="primary"
                    onClick={() => {
                        setOpenModal(false)
                        window.location.replace(`/global_batch/todays_batches/${payTypeForAddingBills}`)
                    }}>
                    View Today's {payTypeForAddingBills} Batches
                </Button>,
                <Button
                    key={'batch_bills'}
                    type="back"
                    onClick={() => {
                        setOpenModal(false)
                        window.location.replace(`/global_batch/view/${globalBatchId}/bills`)
                    }}>
                    View updated batch bills
                </Button>,
            ],
        },
        error: {
            onCancel: () => {
                setOpenModal(false)
            },
            content: [<h3 key="error_title">An error occurred during the process</h3>, <p key="error_content">Please check that everything is correct.</p>],
            footer: [
                <Button
                    type="back"
                    onClick={() => {
                        setOpenModal(false)
                    }}>
                    Continue
                </Button>,
            ],
        },
    }

    const [openModal, setOpenModal] = useState(false)
    const [currModal, setCurrModal] = useState(getModalContent())

    useEffect(() => {
        if (batchCreationStatus !== "") {
            setOpenModal(true)
            setCurrModal(getModalContent())
        }
    }, [batchCreationStatus])

    const checkBillId = (checkId) => {
        for (let i = 0; i < bills.length; i++) {
            const id = bills[i].id
            if (id == checkId) return true
        }
        return false
    }

    const cols = [
        { dataIndex: "client_name", title: "Client Name" },
        { dataIndex: "vendor_name", title: "Vendor Name" },
        { dataIndex: "vendor_short_code", title: "Vendor Short Code" },
        { dataIndex: "payment_date", title: "Payment Date", render: null },
        { dataIndex: "due_date", title: "Due Date", render: null },
        { dataIndex: "current_charges", title: "Amount Due" },
        { dataIndex: "amount_paid", title: "Amount Paid" },
    ]

    function getRender(col) {
        switch (col) {
            case "amount_paid":
                return (text, record, index) => {
                    const { id, batch_id, client_id, client_name, vendor_name, vendor_short_code, payment_date, due_date, current_charges, vendor_payment_methods, vendor_id } = record
                    const value = getAmountPaid(record)
                    return (
                        <InputNumber
                            value={value}
                            defaultValue={value}
                            precision={2}
                            controls={false}
                            disabled={record.paid}
                            style={{ float: "right" }}
                            onBlur={(e) => {
                                const twoDecimalNumRegex = /^\d+(\.\d{0,2})?$/
                                const val = e.target.value
                                const newVal = twoDecimalNumRegex.test(val) ? parseFloat(val) : parseFloat(value)
                                const newSum = bills[index] ? sumTotal - value + newVal : sumTotal + newVal
                                if (newVal != value) {
                                    setLoading(true)
                                    dispatch(setSumTotal(newSum))
                                    dispatch(
                                        setCheckedBills({
                                            value: {
                                                id: id,
                                                amount_paid: newVal,
                                                batch_id: batch_id,
                                                client_id: client_id,
                                                client_name: client_name,
                                                vendor_name: vendor_name,
                                                vendor_short_code: vendor_short_code,
                                                payment_date: payment_date,
                                                due_date: due_date,
                                                current_charges: current_charges,
                                                vendor_payment_methods: vendor_payment_methods,
                                                vendor_id: vendor_id,
                                            },
                                            id: id,
                                        })
                                    )
                                    setLoading(false)
                                }
                            }}
                        />
                    )
                }
            case "payment_date":
                return (text, record, index) => moment(text).format("L")
            case "due_date":
                return (text, record, index) => moment(text).format("L")
            default:
                return null
        }
    }

    const showGlobalBatchConfirm = useConfirmGlobalBatchCreationModal()
    const handleGlobalBatches = (bills, filteredDateRange) => {
        showGlobalBatchConfirm(createBatches, bills, filteredDateRange)
    }

    function getModalContent(response = null) {
        let modalToUse = modalContent
        if (globalBatchId) modalToUse = addToBatchModalContent

        const { empty, success, error } = modalToUse

        if (!globalBatchId) {
            if (batchCreationStatus === "") return empty
            if (batchCreationStatus) return success
            else return error
        } else {
            if (response !== null && response === false) return error
            if (response) return success
            return empty
        }
    }

    async function addBillsToBatch() {
        if (bills.length === 0) {
            notification.error({
                message: "No bill selected.",
                description: "Must select at least one bill to add.",
                duration: 1,
            })
            return
        }

        let billIds = []
        let billsAttributes = []
        let clientId = 0
        for (let i = 0; i < bills.length; i++) {
            const bill = bills[i]
            const { id, amount_paid, client_id } = bill
            if (i === 0) clientId = client_id
            billIds.push(id)
            billsAttributes.push({ id: id, amount_paid: amount_paid })
        }

        const previousBillIds = paymentBatchBills.map((bill, i) => {
            return bill.id
        })

        billIds = billIds.concat(previousBillIds)
        billsAttributes = billsAttributes.concat(paymentBatchBills)

        const response = await dispatch(submitGlobalBatch({ bill_ids: billIds, bills_attributes: billsAttributes }, paymentBatchId, clientId))

        setCurrModal(getModalContent(response.success))
        setOpenModal(true)
        return response.success
    }

    const { content, footer, onCancel } = currModal

    return (
        <div className="muc-table card">
            <Modal open={openModal} footer={footer} onCancel={onCancel}>
                {content.map((c, i) => c)}
            </Modal>
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <h2>Selected Bills</h2>
                        <div className="badge">{bills.length}</div>
                        <div className="actions">
                            <button
                                onClick={() => {
                                    dispatch(setCheckedBills([]))
                                    dispatch(setSumTotal(0))
                                }}>
                                <RollbackOutlined style={{ paddingRight: "10px" }} />
                                Reset selected bills
                            </button>
                            <button
                                disabled={loading}
                                onClick={() => {
                                    globalBatchId ? addBillsToBatch() : handleGlobalBatches(bills, filteredDateRange)
                                }}>
                                <PlusOutlined style={{ paddingRight: "10px" }} />
                                {globalBatchId ? "Add to batch" : "Add New"}
                            </button>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={bills} onChange={() => {}} rowKey={(r) => r.id}>
                            <Table.Column
                                key="in"
                                dataIndex="in"
                                render={(text, record, index) => {
                                    const amountPaid = bills[index] ? bills[index].amount_paid : record.amount_paid ? record.amount_paid : record.current_charges
                                    return (
                                        <Checkbox
                                            defaultChecked={true}
                                            checked={checkBillId(record.id)}
                                            onChange={(e) => {
                                                // if (bills.length == 1) setCurrPaymentType("")
                                                updateChecked(e, record, amountPaid)
                                            }}
                                            // disabled={paymentBatch.closed}
                                        />
                                    )
                                }}
                            />
                            {cols.map((col, i) => {
                                return <Table.Column title={col.title} key={col.dataIndex} showSorterTooltip={false} dataIndex={col.dataIndex} render={getRender(col.dataIndex)} />
                            })}
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
