import React from "react"

import LedgerAccountTable from "./components/LedgerAccountTable"
import LedgerAccountForm from "./components/LedgerAccountForm"
import ImportForm from "../../imports/ImportForm"

export default function LedgerAccountPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "import":
                return renderImport()    
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <LedgerAccountTable />
    }

    const renderImport = () => {
        return <ImportForm dataType="ledger_account" />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <LedgerAccountForm actionName="edit" />
        }

        return <LedgerAccountForm actionName="new" />
    }

    return <>{renderView()}</>
}
