import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import VendorNav from "../features/vendors/VendorNav"
import VendorPage from "../features/vendors/VendorPage"
import PaymentMethodPage from "../features/vendors/paymentMethods/PaymentMethodPage"

export default function VendorRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="list" />} body={<VendorPage view="index" recordSelected={false} />} sidebarType="vendors" />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="list" />} body={<VendorPage view="form" recordSelected={false} />} sidebarType="vendors" />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="list" />} body={<VendorPage view="import" recordSelected={false} />} sidebarType="vendors" />
            </Route>

            <Route path={`${path}/:vendorId/edit`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="list" scopedByVendor={false} />} body={<VendorPage view="form" recordSelected={true} />} sidebarType="vendors" />
            </Route>

            {/*  Payment Method Routes */}

            <Route exact path={`${path}/payment_methods`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="payment_methods" />} body={<PaymentMethodPage view="index" recordSelected={false} />} sidebarType="payment_methods" />
            </Route>

            <Route path={`${path}/payment_methods/new`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="payment_methods" />} body={<PaymentMethodPage view="form" recordSelected={false} />} sidebarType="payment_methods" />
            </Route>


            <Route path={`${path}/payment_methods/:paymentMethodId/edit`}>
                <ConsoleLayout highlightTab="vendors" header={<VendorNav activeTab="payment_methods" scopedByVendor={false} />} body={<PaymentMethodPage view="form" recordSelected={true} />} sidebarType="payment_methods" />
            </Route>

        </Switch>
    )
}
