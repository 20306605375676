import React from "react"
import { Table } from "antd"

export default function AuditLogEventChangeset({ changes }) {
    return (
        <Table
            pagination={false}
            rowKey={(r) => r.field}
            dataSource={Object.keys(changes).map((key) => {
                return {
                    field: key,
                    from: changes[key][0] || "--",
                    to: changes[key][1] || "--",
                }
            })}>
            <Table.Column title="Field" key="field" dataIndex="field" />
            <Table.Column title="From" key="from" dataIndex="from" />
            <Table.Column title="To" key="to" dataIndex="to" />
        </Table>
    )
}
