import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom"
import moment from "moment"

import { selectScopedByClient } from "../../../stores/appSlice"
import { formatCurrency } from "../../../lib/utilities"
import { fetchGlobalBatch, selectGlobalBatch, setGlobalBatch } from "../globalBatchSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import { paymentTypes } from "../consts/paymentTypes"

export default function GlobalBatchNav({ activeTab }) {
    const dispatch = useDispatch()
    const { globalBatchId, type } = useParams()
    const { path } = useRouteMatch()
    const globalBatch = useSelector(selectGlobalBatch)
    // const scopedByClient = useSelector(selectScopedByClient)

    useEffect(() => {
        if (globalBatchId) {
            dispatch(fetchGlobalBatch(globalBatchId))
        }
        else{
            dispatch(setGlobalBatch({}))
        }
    }, [dispatch, globalBatchId])

    useEffect(() => {
        // console.log(allObjectValuesEmpty(globalBatch))
    }, [globalBatch])

    const formatRoute = (newRoute) => {
        return `/global_batch${newRoute || ""}`
    }

    function getPaymentTypeTabs(){
        const res = []
        for(let i = 0; i < paymentTypes.length; i++){
            const type = paymentTypes[i]
            res.push({ route: `/todays_batches/${type == 'CO' ? 'Check - OVERNIGHT' : type}`, tab: `todays_batches_for_${type}`, title: type })
        }
        return res
    }

    const renderHeader = () => {
        const tabs = [
            { route: "", tab: "global_bills", title: "Unswept Bills" },
            // { route: "/view", tab: "batches", title: "All Batches" },
            { route: "/todays_batches", tab: "todays_batches", title: "Today's Batches" },
        ].concat(getPaymentTypeTabs())

        if(activeTab == 'bills'){
            tabs.push({ route: `/view/${globalBatchId}/bills`, tab: 'bills', title: "Batch Bills" })
        }

        // if (scopedByClient) {
        //     return scopedByPaymentBatch ? renderRecordHeader() : renderIndexHeader()
        // }

        return (
            <div key={activeTab}>
                <h2>
                    <Link to={() => `/global_batch`}>Global Batches</Link>
                    <span>
                        {activeTab === "batches" ? " > Created Batches" : ""}
                        {activeTab === "global_bills" ? " > Unswept Bills" : ""}
                        {activeTab === "bills" ? (
                            <>
                                {' > '}<Link to={() => `/global_batch/view`}>Created Batches</Link>{" > Bills"}
                            </>
                        ) : (
                            ""
                        )}
                        {!allObjectValuesEmpty(globalBatch) ? ` for Global Batch ${moment(globalBatch.batch_date).format("L")}: ${formatCurrency(globalBatch.batch_total)}` : ""}
                    </span>
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    {tabs.map((t, i) => {
                        const { route, tab, title } = t
                        return (
                            <li key={i}>
                                <Link to={() => formatRoute(route)}>
                                    <button type="link" className={`${activeTab === tab || (type != undefined && type == title) ? "selected" : ""}`}>
                                        {title}
                                    </button>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    return <>{renderHeader()}</>
}
