import React, { useEffect, useState } from "react"
import GlobalBatchTable from "./components/GlobalBatchTable"

// TODO: Do something with this page
export default function GlobalBatch() {
    return (
        <div>
            <GlobalBatchTable />
        </div>
    )
}
