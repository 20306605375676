import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Select, DatePicker } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { fetchVendorPayment, setVendorPayment, selectVendorPayment, submitVendorPayment } from "../paymentBatchesSlice"

import { requestNotification } from "../../../../lib/notifications"
import DateMask from "../../../forms/components/DateMask"

const dateFormat = "MM/DD/YYYY"

export default function VendorPaymentForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const utilityPayment = useSelector(selectVendorPayment)
    const { utilityPaymentId, clientId } = useParams()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (utilityPaymentId) {
            dispatch(fetchVendorPayment(utilityPaymentId))
        } else {
            dispatch(setVendorPayment({}))
        }
    }, [dispatch, utilityPaymentId])

    useEffect(() => {
        form.resetFields()
    }, [dispatch, utilityPayment]) //eslint-disable-line

    const submit = async (values) => {
        const response = await dispatch(submitVendorPayment({ ...values, client_id: clientId }, utilityPaymentId, clientId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}

            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form form={form} initialValues={{ ...utilityPayment, payment_date: moment(utilityPayment.payment_date || new Date()) }} onFinish={submit} layout="vertical">
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Vendor" name="vendor_name" rules={[{ required: false }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Payment Date" name="payment_date" rules={[{ required: false }]}>
                                        <DatePicker format={dateFormat} style={{ width: "100%" }} onChange={null} disabled={utilityPayment.locked} />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Payment ID" name="check_number" rules={[{ required: false }]}>
                                        <Input disabled={utilityPayment.locked} />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Amount (value is computed from bills)" name="amount" rules={[{ required: false }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
