import { Col, Select } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { filterBatch } from '../globalBatchSlice'
const { Option } = Select


// TODO: Write JSDoc explaining component
export default function MultiSelectRowItem({ props, filters, setFilters, sort, pagination, unpaid }) {
    let { label, value, arr, filterArr, setFilterArr, filterVal, findId = false, hasId = true, disabled = false } = props

    const dispatch = useDispatch()

    let filter = { ...filters }
    let query = { pagination: pagination, sort: sort, unpaid: unpaid }

    function findIdByName(arr, name) {
        let id = ""
        let val = ""

        // Get object keys for any given object.
        // Each array should be composed of object with one prop similar to: {value}_id.
        Object.entries(arr[0]).forEach(([key, value]) => {
            const split = key.split("_")
            if (split[1] === "id") id = split.join("_")
            else val = split.join("_")
        })

        for (let i = 0; i < arr.length; i++) {
            if (name === arr[i][val]) return arr[i][id]
        }
    }

    function onDeselect(filteredItems, itemToRemove) {
        const updatedItems = [...filteredItems]
        const indexToRemove = updatedItems.indexOf(itemToRemove)

        if (indexToRemove !== -1) {
            updatedItems.splice(indexToRemove, 1)
        }

        return updatedItems 
    }

    return (
        <Col span={6}>
            <div className='global-batch-filter-label-text'>{label}</div>
            <Select
                disabled={disabled}
                mode="multiple"
                showSearch
                placeholder={"All"}
                value={value}
                defaultValue={value}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                showArrow={true}
                style={{ width: "100%" }}
                onDeselect={(e) => {
                    const newSelectedItems = findId ? onDeselect(filterArr, findIdByName(arr, e)) : onDeselect(filterArr, e)
                    if (filterVal != "vendor_ids") filter[filterVal] = newSelectedItems
                    else {
                        const idSplit = filter[filterVal]?.split(',')
                        const newItems = idSplit?.filter((item, i) => parseInt(item) != e && item != "")
                        filter[filterVal] = newItems?.join(',')
                    }
                    query.filters = filter
                    setFilters(filter)
                    dispatch(setFilterArr({ value: newSelectedItems, delete: true }))
                    dispatch(filterBatch(query))
                }}
                onSelect={(e) => {
                    const itemToAdd = e + "," + filterArr.join(",")
                    if(filterVal == 'vendor_ids'){
                        if (!filter[filterVal]) filter[filterVal] = itemToAdd
                        else filter[filterVal] += itemToAdd
                    }
                    else filter[filterVal] = itemToAdd
                    query.filters = filter
                    setFilters(filter)
                    dispatch(setFilterArr({ value: e, delete: false }))
                    dispatch(filterBatch(query))
                }}>
                {arr?.map((item, i) => {
                    let id = ""
                    let val = ""
                    let key = ""
                    let value = ""
                    let label = ""

                    if (hasId) {
                        Object.entries(item).forEach(([key, value]) => {
                            // console.log(item, key, value)
                            const split = key.split("_")
                            if (split[1] === "id") id = split.join("_")
                            else val = split.join("_")
                        })
                        value = item[id]
                        label = item[val]
                        key = `${value} - ${label}`
                    } else {
                        key = item
                        value = item
                        label = item
                    }

                    return (
                        <Option key={key} value={value} label={label}>
                            {label}
                        </Option>
                    )
                })}
            </Select>
        </Col>
    )
}
