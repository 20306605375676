import React from "react"
import { useSelector } from "react-redux"
import { can } from "../../lib/roles"
import { selectUserRolesForUser, selectClientOnlyUser } from "./userRolesSlice"

/**
 * Simple wrapper component for checking whether a user can perform `action` on `model` before rendering its children props.
 *
 * @param {*} action The action you want to perform on `model` e.g. read/create/update
 * @param {*} model The model you want to perform `action` on e.g. billable_account/facility. This can be a string for single, or an array for multiple.
 * @param {*} admin Boolean if `children` should only be rendered if the user is NOT a client-only user (admin)
 * @param {*} children The children components that will be rendered if the user has permissions
 * @returns children or null
 */
export default function Role({ action, model, admin, children }) {
    const userRolesForUser = useSelector(selectUserRolesForUser)
    const clientOnlyUser = useSelector(selectClientOnlyUser)

    const hasPermission = () => {
        if (admin) {
            return isAdmin()
        }

        if (Array.isArray(model)) {
            return hasAnyModelPermissions()
        } else {
            return hasSingleModelPermission()
        }
    }

    const isAdmin = () => {
        // for now, the admin=true flag just means we ensure they're not a client-only user.
        return !clientOnlyUser
    }

    const hasAnyModelPermissions = () => {
        return model.map((m) => can(action, m, userRolesForUser)).includes(true)
    }

    const hasSingleModelPermission = () => {
        return can(action, model, userRolesForUser)
    }

    if (hasPermission()) {
        return children
    } else {
        return null
    }
}
