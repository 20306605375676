import React, { useEffect, useState } from "react"
import { Upload, Progress, Row, Col, Form, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import axios from "axios"
import { DirectUpload } from "@rails/activestorage"

import { getClient, getToken, getUid } from "../../../lib/storage"
import { directUploadUrl } from "../../../lib/endpoints"

export default function FileUpload({ form, fileLink, filename }) {
    const [directUploadRoute, setDirectUploadRoute] = useState("")

    useEffect(async () => {
        const res = await axios.get(directUploadUrl, {
            headers: {
                "access-token": getToken(),
                client: getClient(),
                uid: getUid(),
            },
        })

        setDirectUploadRoute(res.data.direct_upload_url)
    }, [])

    const defaultFileList = () => {
        if (fileLink && filename) {
            return [
                {
                    uid: "1",
                    name: filename,
                    status: "done",
                    url: fileLink,
                },
            ]
        } else {
            return null
        }
    }

    const directUploadFile = (options) => {
        const { onSuccess, onError, file, onProgress } = options

        const upload = new DirectUpload(file, directUploadRoute, {
            directUploadWillStoreFileWithXHR(request) {
                request.upload.addEventListener("progress", (event) => {})
            },
        })

        upload.create((error, blob) => {
            if (error) {
                onError("Errors")
            } else {
                form.setFieldsValue({ logo: blob.signed_id })
                onSuccess("Ok")
                // console.log(blob)
            }
        })
    }

    return (
        <Row gutter={24}>
            <Col span={12}>
                <Upload maxCount={1} customRequest={directUploadFile} onRemove={() => form.setFieldsValue({ logo: null })} defaultFileList={defaultFileList()}>
                    <Button type="primary" icon={<UploadOutlined />}>
                        Attach Logo File
                    </Button>
                </Upload>
            </Col>
        </Row>
    )
}
