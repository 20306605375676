import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../../lib/client"
import { facilityTypesUrl, facilityTypeUrl, facilityTypeSelectOptionsUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"

export const facilityTypesSlice = createSlice({
    name: "facilityTypes",
    initialState: {
        facilityType: {},
        facilityTypes: [],
        facilityTypeSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setFacilityType: (state, action) => {
            state.facilityType = action.payload
        },
        setFacilityTypes: (state, action) => {
            state.facilityTypes = action.payload.facility_types
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFacilityTypeSelectOptions: (state, action) => {
            state.facilityTypeSelectOptions = action.payload
        },
        deleteFacilityType: (state, action) => {
            state.facilityTypes = state.facilityTypes.filter((facilityType) => facilityType.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setFacilityType, setFacilityTypes, setFacilityTypeSelectOptions, setPagination, setLoading } = facilityTypesSlice.actions

export function fetchFacilityType(id) {
    return async (dispatch, getState) => {
        client.get(facilityTypeUrl(id)).then((response) => {
            dispatch(setFacilityType(response.data))
        })
    }
}

export function fetchFacilityTypes(pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${facilityTypesUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setFacilityTypes(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function submitFacilityType(values, id = null) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(facilityTypeUrl(id), { facility_type: values })
            return response.data
        } else {
            // post - create

            const response = await client.post(facilityTypesUrl, { facility_type: values })
            return response.data
        }
    }
}

export function deleteFacilityType(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${facilityTypeUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(facilityTypesSlice.actions.deleteFacilityType(id))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export function fetchFacilityTypeSelectOptions(searchTerm, currentPath) {
    return async (dispatch, getState) => {
        client.get(`${facilityTypeSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
            dispatch(setFacilityTypeSelectOptions(response.data.facility_types))
        })
    }
}

export function filterFacilityTypes(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, facilityTypesUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setFacilityTypes(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectFacilityType = (state) => state.facilityTypes.facilityType
export const selectFacilityTypes = (state) => state.facilityTypes.facilityTypes
export const selectFacilityTypeSelectOptions = (state) => state.facilityTypes.facilityTypeSelectOptions
export const selectTotal = (state) => state.facilityTypes.total
export const selectPagination = (state) => state.facilityTypes.pagination
export const selectLoading = (state) => state.facilityTypes.loading

export default facilityTypesSlice.reducer
