import React from "react"
import { Select, Row, Col, Switch, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { selectCompareToLastYear, setCompareToLastYear, selectDateRangeType, setDateRangeType, selectDateRangeValues, submitQuery } from "../../../features/dashboards/dashboardsSlice"

export default function DashboardFilterDropdown({ scopeType, scopeId, clientId, setFilterOpen }) {
    const dispatch = useDispatch()

    const compareToLastYear = useSelector(selectCompareToLastYear)
    const dateRangeType = useSelector(selectDateRangeType)
    const dateRangeValues = useSelector(selectDateRangeValues)

    const submit = () => {
        // @TODO: we shouldn't have to do this, we should be able to just add the date range to our main
        // dashboard.jsx effect dependencies...
        dispatch(submitQuery(null, scopeType, scopeId, clientId))
        setFilterOpen(false)
    }

    return (
        <div className="dashboard-filter-dropdown">
            <Row gutter={24}>
                <Col span={24}>
                    <Select style={{ width: "100%", border: "1px solid lightgrey" }} value={dateRangeType} onChange={(value) => dispatch(setDateRangeType(value))}>
                        <Select.Option value="last_12_months">Last 12 Months to Date</Select.Option>
                        <Select.Option value="last_3_months">Last 3 Months to Date</Select.Option>
                        <Select.Option value="last_6_months">Last 6 Months to Date</Select.Option>
                        <Select.Option value="year_to_date">Year to Date</Select.Option>
                        <Select.Option value="fiscal_year">Fiscal Year</Select.Option>
                    </Select>
                </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: "20px" }}>
                <Col span={24}>
                    <p>
                        {dateRangeValues.dateRangeFrom} - {dateRangeValues.dateRangeTo}
                    </p>
                </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col span={24}>
                    <Switch checked={compareToLastYear} onChange={(checked) => dispatch(setCompareToLastYear(checked))} />
                    &nbsp; Compare to Last Year
                </Col>
            </Row>

            {compareToLastYear && (
                <Row gutter={24}>
                    <Col span={24}>
                        <p>
                            {dateRangeValues.lastYearDateRangeFrom} - {dateRangeValues.lastYearDateRangeTo}
                        </p>
                    </Col>
                </Row>
            )}

            <Row gutter={24}>
                <Col span={24}>
                    <Button style={{ width: "100%" }} type="primary" onClick={submit}>
                        Apply
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
