import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Form, Input, Row, Col, Select, Skeleton } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { debounce, isEmpty } from "lodash"

import PaginatedSelect from "../../forms/components/PaginatedSelect"
import { fetchChannelPartner, setChannelPartner, selectChannelPartner, submitChannelPartner } from "../channelPartnersSlice"
import { fetchClients, searchClients, selectClients, selectClientSearch, selectTotal as selectClientTotal } from "../../clients/clientsSlice"
import FileUpload from "../subcomponents/FileUpload"

const { Option } = Select

export default function ChannelPartnerForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const channelPartner = useSelector(selectChannelPartner)
    const { channelPartnerId } = useParams()
    const history = useHistory()

    const isLoading = () => {
        // This is just to clear the defaultFileList.
        // Situations include going from one channel partner to another with/withouth a logo
        // and going from a channel partner with a logo to a new channel parter
        if (channelPartnerId) {
            return isEmpty(channelPartner) || channelPartner.id.toString() !== channelPartnerId
        } else {
            return !isEmpty(channelPartner)
        }
    }

    useEffect(() => {
        if (channelPartnerId) {
            dispatch(fetchChannelPartner(channelPartnerId))
        } else {
            dispatch(setChannelPartner({}))
        }
    }, [dispatch, channelPartnerId])

    useEffect(() => {
        form.resetFields()
    }, [form, channelPartner])

    const performSearch = debounce((value, callback) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            callback()
        }
    }, 1000)

    const handleClientSearch = (value) => {
        performSearch(value, () => dispatch(searchClients(value)))
    }

    const submit = async (values) => {
        const response = await dispatch(submitChannelPartner(values))

        if (response.success) {
            history.push("/channel_partners")
        }
    }

    return (
        <div id="user-form-container">
            <Link to="/channel_partners">
                <LeftOutlined />
                Go Back
            </Link>
            <Skeleton active loading={isLoading()}>
                <Form form={form} initialValues={{ ...channelPartner, file: channelPartner.signed_file_id }} onFinish={submit} layout="vertical">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item label="Clients" name="client_ids">
                                            <PaginatedSelect
                                                placeholder="Search clients..."
                                                onSearch={handleClientSearch}
                                                paginationSelector={selectClients}
                                                paginationTotalSelector={selectClientTotal}
                                                loadPaginatedData={fetchClients}
                                                searchSelector={selectClientSearch}
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item name="logo" hidden={true} />
                                <FileUpload form={form} fileLink={channelPartner.file_url} filename={channelPartner.filename} />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            </Skeleton>
        </div>
    )
}
