import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"
import moment from "moment"

// http client
import client from "../../lib/client"
import {
    billsUrl,
    billUrl,
    dynamicUrl,
    clientBillableAccountSelectOptionsUrl,
    billableAccountSelectOptionByIdUrl,
    billFilteredBillableAccountSelectOptionsUrl,
    clientSupplyAccountSelectOptionsUrl,
    supplyAccountSelectOptionByIdUrl,
    billFilteredSupplyAccountSelectOptionsUrl,
    previousBillableAccountBillsUrl,
    previousSupplyAccountBillsUrl,
    billAccountUrl,
    linkedServiceBillSelectOptionsUrl,
    serviceBillUrl,
    groupAccountBillsUrl,
    billsScopedByClientUrl,
    billsCsvUrl,
} from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

const emptyState = {
    bill: {},
    bills: [],
    billSelectOptions: [],
    billSearch: [],
    account: {},
    accountSelectOptions: [],
    previousBillSelectOptions: [],
    previousBill: {},
    linkedServiceBillSelectOptions: [],
    total: 0,
    pagination: { current: 1, pageSize: 10 },
    loading: false,
}

export const billsSlice = createSlice({
    name: "bills",
    initialState: emptyState,
    reducers: {
        setBill: (state, action) => {
            state.bill = action.payload
        },
        setBills: (state, action) => {
            state.bills = action.payload.bills
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAccountLoading: (state, action) => {
            state.accountLoading = action.payload
        },
        setBillSelectOptions: (state, action) => {
            state.billSelectOptions = action.payload
        },
        setBillSearch: (state, action) => {
            state.billSearch = action.payload
        },
        setAccount: (state, action) => {
            state.account = action.payload
        },
        setAccountSelectOptions: (state, action) => {
            state.accountSelectOptions = action.payload
        },
        setPreviousBillSelectOptions: (state, action) => {
            state.previousBillSelectOptions = action.payload
        },
        setPreviousBill: (state, action) => {
            state.previousBill = action.payload
        },
        setLinkedServiceBillSelectOptions: (state, action) => {
            state.linkedServiceBillSelectOptions = action.payload
        },
        clearState: (state, action) => {
            return emptyState
        },
        deleteBill: (state, action) => {
            state.bills = state.bills.filter((bill) => bill.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const {
    setBill,
    setBills,
    setPagination,
    setLoading,
    setAccountLoading,
    setBillSelectOptions,
    setBillSearch,
    setAccountSelectOptions,
    setAccount,
    setPreviousBillSelectOptions,
    setPreviousBill,
    setLinkedServiceBillSelectOptions,
    clearState,
} = billsSlice.actions

export function fetchBill(id) {
    return async (dispatch, getState) => {
        client.get(billUrl(id)).then((response) => {
            dispatch(setBill(response.data))
        })
    }
}

export function fetchBills(pagination, currentPath) {
    // console.log(currentPath, pagination)
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        // This route may be nested so we have to use a dynamic route
        // /bills VS /clients/client_id/bills
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setBills(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientBills(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientBillsPath = `clients/${clientId}/bills/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientBillsPath)}`)
        return response.data.bills
    }
}

export function fetchGroupAccountBills(pagination, groupAccountId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${groupAccountBillsUrl(groupAccountId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setBills(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function submitBill(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(billUrl(id), {
                bill: {
                    ...values,
                    date: new Date(values.date).toISOString(),
                    due_date: new Date(values.due_date).toISOString(),
                    service_bills_attributes: formatServiceBillDates(values.service_bills_attributes) || [],
                    linked_service_bills_attributes: formatServiceBillDates(values.linked_service_bills_attributes) || [],
                },
            })
            return response.data
        } else {
            // post - create
            const response = await client.post(billsUrl, {
                bill: {
                    ...values,
                    date: new Date(values.date).toISOString(),
                    due_date: new Date(values.due_date).toISOString(),
                    service_bills_attributes: formatServiceBillDates(values.service_bills_attributes) || [],
                    linked_service_bills_attributes: formatServiceBillDates(values.linked_service_bills_attributes) || [],
                },
            })
            return response.data
        }
    }
}

function formatServiceBillDates(serviceBills) {
    if (serviceBills) {
        const updatedServiceBills = serviceBills.map((serviceBill) => {
            return {
                ...serviceBill,
                fiscal_month: serviceBill.fiscal_month !== "" && serviceBill.fiscal_month != null ? new Date(serviceBill.fiscal_month).toISOString() : null,
                next_read_date: serviceBill.next_read_date !== "" && serviceBill.next_read_date != null ? new Date(serviceBill.next_read_date).toISOString() : null,
                previous_read_date: new Date(serviceBill.previous_read_date).toISOString() || null,
                read_date: new Date(serviceBill.read_date).toISOString() || null,
            }
        })
        return updatedServiceBills
    } else {
        return []
    }
}

export function deleteBill(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${billUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(billsSlice.actions.deleteBill(id))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export function fetchClientBillableAccountSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${clientBillableAccountSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setAccountSelectOptions(response.data.billable_accounts))
        })
    }
}

export function fetchBillableAccountSelectOptionById(id) {
    return async (dispatch, getState) => {
        client.get(`${billableAccountSelectOptionByIdUrl}?billable_account_id=${id}`).then((response) => {
            dispatch(setAccountSelectOptions(response.data.billable_accounts))
        })
    }
}

export function fetchFilteredBillableAccountSelectOptions(clientId, searchTerm) {
    return async (dispatch, getState) => {
        client.get(`${billFilteredBillableAccountSelectOptionsUrl(clientId, searchTerm)}`).then((response) => {
            // console.log(response.data.billable_accounts)
            dispatch(setAccountSelectOptions(response.data.billable_accounts))
        })
    }
}

export function fetchClientSupplyAccountSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${clientSupplyAccountSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setAccountSelectOptions(response.data.supply_accounts))
        })
    }
}

export function fetchSupplyAccountSelectOptionById(id) {
    return async (dispatch, getState) => {
        client.get(`${supplyAccountSelectOptionByIdUrl}?supply_account_id=${id}`).then((response) => {
            dispatch(setAccountSelectOptions(response.data.supply_accounts))
        })
    }
}

export function fetchFilteredSupplyAccountSelectOptions(clientId, searchTerm) {
    return async (dispatch, getState) => {
        client.get(`${billFilteredSupplyAccountSelectOptionsUrl(clientId, searchTerm)}`).then((response) => {
            dispatch(setAccountSelectOptions(response.data.supply_accounts))
        })
    }
}

export function fetchSelectedAccount(accountType, accountId) {
    return async (dispatch, getState) => {
        dispatch(setAccountLoading(true))
        client.get(`${billAccountUrl(accountType, accountId)}`).then((response) => {
            dispatch(setAccount(response.data.account))
            dispatch(setAccountLoading(false))
        })
    }
}

export function fetchPreviousBillSelectOptions(accountType, accountId, date, billId = null) {
    // console.log(billId)
    return async (dispatch, getState) => {
        if (accountType === "BillableAccount") {
            // console.log(`${previousBillableAccountBillsUrl(accountId, date)}`)
            client.get(`${previousBillableAccountBillsUrl(accountId, date, billId)}`).then((response) => {
                dispatch(setPreviousBillSelectOptions(response.data.previous_bills))
            })
        } else {
            client.get(`${previousSupplyAccountBillsUrl(accountId, date, billId)}`).then((response) => {
                dispatch(setPreviousBillSelectOptions(response.data.previous_bills))
            })
        }
    }
}

export function fetchPreviousBill(id) {
    return async (dispatch, getState) => {
        client.get(billUrl(id)).then((response) => {
            dispatch(setPreviousBill(response.data))
        })
    }
}

export function fetchlinkedServiceBillSelectOptions(accountId) {
    return async (dispatch, getState) => {
        client.get(`${linkedServiceBillSelectOptionsUrl(accountId)}`).then((response) => {
            dispatch(setLinkedServiceBillSelectOptions(response.data.linked_service_bill_options))
        })
    }
}

export function fetchServiceBill(serviceBillId) {
    return async (dispatch, getState) => {
        // client.get(`${serviceBillUrl(serviceBillId)}`).then((response) => {
        //     dispatch(setAccount(response.data.account))
        // })

        const response = await client.get(`${serviceBillUrl(serviceBillId)}`)
        return response.data
    }
}

// TODO: will need to scope this as well
// export function fetchBillSelectOptions(searchTerm, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${billSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setBillSelectOptions(response.data.bills))
//         })
//     }
// }

// export function fetchBillSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${billSelectOptionByIdUrl}?bill_id=${id}`).then((response) => {
//             dispatch(setBillSelectOptions(response.data.bill))
//         })
//     }
// }

// export function searchBills(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${billSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setBillSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export function filterBills(query, { billableAccountId, supplyAccountId } = {}) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, billsScopedByClientUrl(query.clientId))

    if (billableAccountId) {
        filterUrl += "&account_type=BillableAccount&account_id=" + billableAccountId
    } else if (supplyAccountId) {
        filterUrl += "&account_type=SupplyAccount&account_id=" + supplyAccountId
    }

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setBills(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${billsCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectBill = (state) => state.bills.bill
export const selectBills = (state) => state.bills.bills
export const selectTotal = (state) => state.bills.total
export const selectPagination = (state) => state.bills.pagination
export const selectLoading = (state) => state.bills.loading
export const selectAccountLoading = (state) => state.bills.accountLoading
export const selectBillSelectOptions = (state) => state.bills.billSelectOptions
export const selectBillSearch = (state) => state.bills.billSearch
export const selectAccountSelectOptions = (state) => state.bills.accountSelectOptions
export const selectPreviousBillSelectOptions = (state) => state.bills.previousBillSelectOptions
export const selectPreviousBill = (state) => state.bills.previousBill
export const selectAccount = (state) => state.bills.account
export const selectLinkedServiceBillOptions = (state) => state.bills.linkedServiceBillSelectOptions

export default billsSlice.reducer
