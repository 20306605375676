import React from "react"
import { useHistory } from "react-router-dom"

import { ReactComponent as MucLogo } from "../../../assets/images/MucLogoCircle.svg"
import Icon from "@ant-design/icons"

const MucLogoSVG = () => {
    return <MucLogo />
}

function MucLogoHorizontal(props) {
    const history = useHistory()
    return <Icon component={MucLogoSVG} onClick={() => history.push("/login")} {...props} />
}

export default MucLogoHorizontal
