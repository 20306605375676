import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import DivisionTable from "./components/DivisionTable"
import DivisionForm from "./components/DivisionForm"
import FacilityTable from "../facilities/components/FacilityTable"
import FacilityForm from "../facilities/components/FacilityForm"
import DocumentsTable from "../documents/components/DocumentsTable"
import { useParams } from "react-router"
import DocumentForm from "../documents/components/DocumentForm"
import Dashboard from "../../features/dashboards/components/Dashboard"
import ImportForm from "../imports/ImportForm"

export default function DivisionPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { clientId, divisionId } = useParams()

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "import":
                return renderImport()
            case "form":
                return renderForm()
            case "facilities":
                return renderFacilities()
            case "facilities-form":
                return renderFacilitiesForm()
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            default:
                return renderDashboard()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <Dashboard scopeType="Division" scopeId={divisionId} clientId={clientId} />
        }

        return <Dashboard scopeType="Division" scopeId={null} clientId={clientId} />
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <DivisionTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <DivisionTable scopedByClient={scopedByClient} />
    }

    const renderImport = () => {
        return <ImportForm dataType="division" />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <DivisionForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <DivisionForm actionName="new" />
            </>
        )
    }

    const renderFacilities = () => {
        return <FacilityTable scopedByClient={scopedByClient} />
    }

    const renderFacilitiesForm = () => {
        return <FacilityForm />
    }

    const renderDocuments = () => {
        return <DocumentsTable type="Division" id={divisionId} />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="Division" id={divisionId} />
    }

    return <>{renderView()}</>
}
