import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch, useLocation } from "react-router-dom"
import { Alert, Button, Form, Input, Row, Col, notification, Select, Switch, Skeleton, Upload, InputNumber, DatePicker } from "antd"
import { LeftOutlined, UploadOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"
import { isEmpty, snakeCase } from "lodash"
import { requestNotification } from "../../../lib/notifications"
import { roundHumanizedNumbers } from "../../../lib/utilities"

// import { getClient, getToken, getUid } from "./storage"

import {
    fetchBill,
    setBill,
    selectBill,
    submitBill,
    fetchClientBillableAccountSelectOptions,
    fetchBillableAccountSelectOptionById,
    fetchFilteredBillableAccountSelectOptions,
    fetchClientSupplyAccountSelectOptions,
    fetchFilteredSupplyAccountSelectOptions,
    selectAccountSelectOptions,
    fetchPreviousBillSelectOptions,
    selectPreviousBillSelectOptions,
    fetchSelectedAccount,
    fetchPreviousBill,
    selectAccount,
    selectPreviousBill,
    clearState,
    selectAccountLoading,
} from "../billsSlice"
import { fetchClientSelectOptionById } from "../../clients/clientsSlice"
import LinkedServiceForm from "./LinkedServiceForm"
import BillHistoryTable from "./BillHistoryTable"
import WarningChip from "./subcomponents/WarningChip"
import ServiceBillHistory from "./subcomponents/ServiceBillHistory"
import FileUpload from "./subcomponents/FileUpload"
import {formatCurrency} from "../../../lib/utilities";

const { Option } = Select
const { TextArea } = Input
const dateFormat = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY', 'MM.DD.YYYY', 'MM.DD.YY', 'M-D-YY']

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function BillForm({ actionName }) {
    const { url } = useRouteMatch()
    const { clientId, billId, account_type, account_id } = useParams()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const bill = useSelector(selectBill)
    const account = useSelector(selectAccount)
    const history = useHistory()
    //  const billLoading = useSelector(selectLoading)
    const accountLoading = useSelector(selectAccountLoading)
    const accountSelectOptions = useSelector(selectAccountSelectOptions)
    const previousBillSelectOptions = useSelector(selectPreviousBillSelectOptions)
    const previousBill = useSelector(selectPreviousBill)
    const [loaded, setLoaded] = useState(false)
    const [prevBillId, setPrevBillId] = useState(null)
    const [isSupplyBill, setIsSupplyBill] = useState(false)
    const [showBillHistory, setShowBillHistory] = useState(false)
    const [selectedAccountType, setSelectedAccountType] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    let query = useQuery()

    const fetchAccount = async (accountTypeString, id) => {
        if (accountTypeString === "BillableAccount") {
            await dispatch(fetchClientBillableAccountSelectOptions(clientId))
        } else {
            await dispatch(fetchClientSupplyAccountSelectOptions(clientId))
        }

        await dispatch(fetchSelectedAccount(accountTypeString, id))
    }

    useEffect(() => {
        const accountType = query.get("account_type")
        const accountId = query.get("account_id")

        const loadData = async () => {
            if (!!accountType && !!accountId) {
                if (accountType === "billable_account") {
                    setIsSupplyBill(false)
                    await fetchAccount("BillableAccount", accountId)
                    form.setFieldsValue({ account_type: "BillableAccount" })
                    form.setFieldsValue({ account_id: parseInt(accountId) })
                } else {
                    setIsSupplyBill(true)
                    await fetchAccount("SupplyAccount", accountId, accountId)
                    form.setFieldsValue({ account_type: "SupplyAccount" })
                    form.setFieldsValue({ account_id: parseInt(accountId) })
                }
            }
            setIsLoading(false)
        }

        if (isLoading) {
            if (billId != null) {
                if (!isEmpty(bill) && !isEmpty(account)) {
                    setIsLoading(false)
                }
            } else {
                loadData()
            }
        }
    }, [form, billId, bill, account, isLoading])

    // Clear state when unmounting
    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if (billId) {
            dispatch(fetchBill(billId))
        } else {
            dispatch(setBill({}))
        }
    }, [dispatch, billId])

    // When a Bill is set, we need to fetch its Client in order
    // to prepopulate the Client select with the current value
    useEffect(() => {
        form.resetFields()
        // form.setFieldsValue({ balance_forward: 0.0 })

        if (bill) {
            setPrevBillId(bill.bill_id)

            if (bill.client_id && !loaded) {
                setLoaded(true)
                dispatch(fetchClientSelectOptionById([bill.client_id]))
            }

            // Fetch account options based on account type
            if (bill.account_type) {
                if (bill.account_type === "BillableAccount") {
                    setIsSupplyBill(false)
                    // dispatch(fetchClientBillableAccountSelectOptions(clientId))
                    // console.log("fetching by id")
                    dispatch(fetchBillableAccountSelectOptionById(bill.account_id))
                } else {
                    setIsSupplyBill(true)
                    dispatch(fetchClientSupplyAccountSelectOptions(clientId))
                }
            }

            // Fetch previous bills based on account and date
            if (bill.account_id && bill.date) {
                dispatch(fetchPreviousBillSelectOptions(bill.account_type, bill.account_id, bill.date, bill.id))
            }

            // Fetch selected account for this bill
            if (bill.account_type && bill.account_id) {
                dispatch(fetchSelectedAccount(bill.account_type, bill.account_id))
            }

            // Fetch previous bill
            if (bill.bill_id) {
                dispatch(fetchPreviousBill(bill.bill_id))
            }
        }
    }, [dispatch, bill]) //eslint-disable-line

    // Logic for determining whether or not we need to pull values from previous bill
    useEffect(() => {
        if (!isEmpty(previousBill) && !bill) {
            form.setFieldsValue({ balance_forward: 0.0 })
        } else {
            // if (!(parseFloat(form.getFieldValue("balance_forward")) >= 0)){
            //     form.setFieldsValue({ balance_forward: previousBill.next_bill_balance_forward || 0.0 })
            // }
            form.setFieldsValue({ balance_forward: previousBill.balance_forward })
        }

        if (billId) {
            if (prevBillId && previousBill) {
                // if (prevBillId !== previousBill.id) {
                if (!form.getFieldValue("service_bills_attributes")) {
                    return
                }
                form.setFieldsValue({
                    service_bills_attributes: form.getFieldValue("service_bills_attributes").map((service_bill) => {
                        const prev = previousBill.service_bills.filter((p) => p.service_id === service_bill.service_id)[0]

                        if (!prev) {
                            return service_bill
                        }

                        // For the middle portion with the values, the previous usage can be grabbed from the "usage" attribute on the previous Service Bill for this Service.
                        // The date comes from the "date" attribute on the previous Service Bill.
                        // The reading comes from the "reading" attribute on the previous Service Bill.

                        return {
                            ...service_bill,
                            // previous_read_date: moment(prev.read_date),
                            // previous_reading: prev.meter_reading,
                            // previous_usage:     prev.usage
                        }
                    }),
                })
                // }
            }
        } else {
            // Populate bill form data with data from previous bill
            if (!isEmpty(previousBill)) {
                // Populate service bill date from the previous bill
                if (!isEmpty(previousBill.service_bills)) {
                    form.setFieldsValue({
                        service_bills_attributes: form.getFieldValue("service_bills_attributes").map((service_bill) => {
                            const prev = previousBill.service_bills.filter((p) => p.service_id === service_bill.service_id)[0]

                            if (!prev) {
                                return service_bill
                            }

                            return {
                                ...service_bill,
                                previous_read_date: moment(prev.read_date),
                                previous_reading: prev.meter_reading,
                                // previous_usage:     prev.usage
                            }
                        }),
                    })
                }
            }
        }
    }, [previousBill]) //eslint-disable-line

    useEffect(() => {
        if (billId) {
            return
        }

        form.resetFields(["service_bills_attributes"])
        form.resetFields(["linked_service_bills_attributes"])
    }, [form, account])

    const calculateAmountDue = () => {
        const balance_forward = form.getFieldValue("balance_forward") || 0
        const current_charges = form.getFieldValue("current_charges") || 0
        const late_fee = form.getFieldValue("late_fee") || 0
        form.setFieldsValue({ amount_due: parseFloat(balance_forward) + parseFloat(current_charges) + parseFloat(late_fee) })
    }

    const handleAccountTypeSelect = (value) => {
        form.setFieldsValue({ account_id: null })
        form.setFieldsValue({ bill_id: null })
        form.setFieldsValue({ account_type: value })
        setSelectedAccountType(value)

        if (value === "BillableAccount") {
            setIsSupplyBill(false)
            dispatch(fetchClientBillableAccountSelectOptions(clientId))
        } else {
            setIsSupplyBill(true)
            dispatch(fetchClientSupplyAccountSelectOptions(clientId))
        }
    }

    const handleAccountSearch = (searchTerm) => {
        if (selectedAccountType === "BillableAccount") {
            dispatch(fetchFilteredBillableAccountSelectOptions(clientId, searchTerm))
        } else {
            dispatch(fetchFilteredSupplyAccountSelectOptions(clientId, searchTerm))
        }
    }

    //Create service_bill fields based off the account's services
    const initialServiceValues = () => {
        if (!isEmpty(bill) && !isEmpty(account)) {
            if (bill.account_type === "BillableAccount") {
                return bill.service_bills.map((service_bill) => {
                    return {
                        id: service_bill.id,
                        service_id: service_bill.service_id,
                        bill_id: service_bill.bill_id,
                        supply_bill_id: service_bill.supply_bill_id,
                        previous_read_date: moment(service_bill.previous_read_date || new Date()),
                        read_date: moment(service_bill.read_date || new Date()),
                        next_read_date: service_bill.next_read_date != null ? moment(service_bill.next_read_date) : "",
                        fiscal_month: service_bill.fiscal_month != null ? moment(service_bill.fiscal_month) : "",
                        number_of_days: service_bill.number_of_days,
                        estimated: service_bill.estimated,
                        unit_id: service_bill.unit_id,
                        previous_reading: service_bill.previous_reading,
                        meter_reading: service_bill.meter_reading,
                        multiplier: service_bill.multiplier || 1,
                        usage: service_bill.usage,
                        notes: service_bill.notes,

                        service_bill_usage_factors_attributes: service_bill.service_bill_usage_factors.map((service_bill_usage_factor) => {
                            return {
                                id: service_bill_usage_factor.id,
                                value: service_bill_usage_factor.value,
                                adjustment_factor: service_bill_usage_factor.adjustment_factor,
                            }
                        }),
                        service_bill_entry_fields_attributes: service_bill.service_bill_entry_fields.map((service_bill_entry_field) => {
                            return {
                                id: service_bill_entry_field.id,
                                value: service_bill_entry_field.value,
                                name: service_bill_entry_field.name,
                            }
                        }),
                        service_bill_additional_entry_fields_attributes: service_bill.service_bill_additional_entry_fields.map((service_bill_additional_entry_field) => {
                            return {
                                id: service_bill_additional_entry_field.id,
                                value: service_bill_additional_entry_field.value,
                            }
                        }),
                    }
                })
            } else {
                return bill.linked_service_bills.map((service_bill) => {
                    return {
                        id: service_bill.id,
                        service_id: service_bill.service_id,
                        bill_id: service_bill.bill_id,
                        supply_bill_id: service_bill.supply_bill_id,
                        previous_read_date: moment(service_bill.previous_read_date || new Date()),
                        read_date: moment(service_bill.read_date || new Date()),
                        next_read_date: service_bill.next_read_date != null ? moment(service_bill.next_read_date) : "",
                        fiscal_month: service_bill.fiscal_month != null ? moment(service_bill.fiscal_month) : "",
                        number_of_days: service_bill.number_of_days,
                        estimated: service_bill.estimated,
                        unit_id: service_bill.unit_id,
                        previous_reading: service_bill.previous_reading,
                        meter_reading: service_bill.meter_reading,
                        multiplier: service_bill.multiplier,
                        usage: service_bill.usage,
                        unit_options: service_bill.unit_options,
                        notes: service_bill.notes,
                        service_utility_service_name: service_bill.service_utility_service_name,
                        service_meter_number: service_bill.service_meter_number,
                        service_bill_usage_factors_attributes: service_bill.service_bill_usage_factors.map((service_bill_usage_factor) => {
                            return {
                                ...service_bill_usage_factor,
                            }
                        }),
                        service_bill_entry_fields_attributes: service_bill.service_bill_entry_fields.map((service_bill_entry_field) => {
                            return {
                                ...service_bill_entry_field,
                            }
                        }),
                        service_bill_additional_entry_fields_attributes: service_bill.service_bill_additional_entry_fields.map((service_bill_additional_entry_field) => {
                            return {
                                ...service_bill_additional_entry_field,
                            }
                        }),
                    }
                })
            }
        } else if (Object.keys(account).length !== 0) {
            if (account.services) {
                return account.services
                    .filter((service) => service.active)
                    .map((service) => {
                        return {
                            service_id: service.id,
                            // NOTE: what we need here is not the usage unit id, rather the usage unit's unit id, one more level down.
                            // unit_id: service.usage_unit_id,
                            unit_id: service.usage_unit_unit_id,

                            service_bill_usage_factors_attributes: service.usage_factors.map((usage_factor) => {
                                return {
                                    usage_factor_id: usage_factor.id,
                                    name: usage_factor.name,
                                    adjustment_factor: usage_factor.adjustment_factor,
                                    value: usage_factor.default_value,
                                }
                            }),
                            service_bill_entry_fields_attributes: service.entry_fields.map((entry_field) => {
                                return {
                                    entry_field_id: entry_field.id,
                                    name: entry_field.name,
                                }
                            }),
                            service_bill_additional_entry_fields_attributes: service.additional_entry_fields
                                .map((additional_entry_field) => {
                                    if (additional_entry_field.active) {
                                        return {
                                            additional_entry_field_id: additional_entry_field.id,
                                            name: additional_entry_field.name,
                                        }
                                    } else {
                                        return null
                                    }
                                })
                                .filter((x) => x != null),
                        }
                    })
            } else {
                return []
            }
        } else {
            return []
        }
    }

    const handleAccountSelect = (value) => {
        form.setFieldsValue({ bill_id: null })

        // Fetch previous bills based on account and date
        if (value && form.getFieldValue("date")) {
            dispatch(fetchPreviousBillSelectOptions(form.getFieldValue("account_type"), value, form.getFieldValue("date")))
        }

        // Fetch selected account for this bill
        if (form.getFieldValue("account_type") && value) {
            dispatch(fetchSelectedAccount(form.getFieldValue("account_type"), value))
        }
    }

    const handleDateChange = (value) => {
        // Fetch previous bills based on account and date
        if (form.getFieldValue("account_id") && value) {
            dispatch(fetchPreviousBillSelectOptions(form.getFieldValue("account_type"), form.getFieldValue("account_id"), value, bill ? bill.id : null))
        }
    }

    const handleReadDateChange = (fieldKey) => {
        try {
            const readDate = form.getFieldValue("service_bills_attributes")[fieldKey].read_date
            const previousReadDate = form.getFieldValue("service_bills_attributes")[fieldKey].previous_read_date

            if (!readDate || !previousReadDate) {
                return
            }

            const diff = moment(readDate).diff(moment(previousReadDate), "days")

            form.setFieldsValue({
                service_bills_attributes: form.getFieldValue("service_bills_attributes").map((service_bill, index) => {
                    if (index === fieldKey) {
                        return {
                            ...service_bill,
                            number_of_days: diff,
                        }
                    } else {
                        return service_bill
                    }
                }),
            })
        } catch (e) {
            console.error("Could not parse read date change in order to set the number_of_days attribute: ", e)
        }
    }

    // Need to fetch previous bill data
    const handlePreviousBillSelect = (value) => {
        // We have to set the previous bill id in order to know if we need
        // to update the values if a new previous bill is selected
        if (value) {
            setPrevBillId(previousBill.id)
            dispatch(fetchPreviousBill(value))   
        } else {
            setPrevBillId(null)
            form.setFieldsValue({
                balance_forward: 0.0,
                service_bills_attributes: form.getFieldValue("service_bills_attributes").map((service_bill) => {
                    return {
                        ...service_bill,
                        previous_read_date: null,
                        previous_reading: null
                    }
                }),
            })
        }
    }

    const evalServiceBillUsage = (index) => {
        const meterReading = form.getFieldValue("service_bills_attributes")[index].meter_reading
        const prevReading = form.getFieldValue("service_bills_attributes")[index].previous_reading
        let multiplier = form.getFieldValue("service_bills_attributes")[index].multiplier

        if (multiplier == null) {
            multiplier = 1
        }

        const usage = (meterReading - prevReading) * multiplier

        form.setFieldsValue({
            service_bills_attributes: form.getFieldValue("service_bills_attributes").map((service_bill, mapIndex) => {
                if (mapIndex !== index) {
                    return service_bill
                }

                return {
                    ...service_bill,
                    usage: usage,
                }
            }),
        })
    }

    // The 'Utility Charges' fields is required on utility bills.
    // This weaponized code determines which of the entry fields match and applies a required status to it.
    const evalUtilityChargeRequired = (service_bill_index, entry_field_index) => {
        if (form.getFieldValue("service_bills_attributes")) {
            if (form.getFieldValue("service_bills_attributes")[service_bill_index].service_bill_entry_fields_attributes[entry_field_index].name === "Utility Charges") {
                return true
            } else {
                return false
            }
        } else if (form.getFieldValue("service_bills")) {
            if (form.getFieldValue("service_bills")[service_bill_index].service_bill_entry_fields[entry_field_index].name === "Utility Charges") {
                return true
            } else {
                return false
            }
        }

        return false
    }

    const renderServiceBillHistory = (key) => {
        if (isEmpty(bill)) {
            if (account && account.services && account.services[key]) {
                return <ServiceBillHistory serviceId={account.services[key].id} />
            }
        } else {
            return <ServiceBillHistory serviceId={bill.service_bills[key].service_id} />
        }
    }

    const submit = async (values) => {
        const linked_service_bill_ids = values.linked_service_bills_attributes ? values.linked_service_bills_attributes.map((o) => o.id) : []
        if (!values.bill_id) {
            values.bill_id = null;
        }    
        const response = await dispatch(submitBill({ ...values, linked_service_bill_ids: linked_service_bill_ids }, billId))

        if (response.success) {
            const {account_id, account_type} = values
            if (actionName === "edit") {
                let path = `../../../${snakeCase(account_type)}s/${account_id}/bills`
                history.push(`${nodePath.join(url, path)}`)
            } else {
                let path = `../../${snakeCase(account_type)}s/${account_id}/bills`
                history.push(`${nodePath.join(url, path)}`)
            }
        }

        requestNotification(response)
    }

    // const isLoading = () => {
    //     if (billId) {
    //         // return isEmpty(bill) || isEmpty(account) || isEmpty(previousBill)
    //         return isEmpty(bill) || isEmpty(account)
    //     } else {
    //         return true
    //     }
    // }

    const getInitialDate = (date) => {
        if (date) {
            return moment(date)
        } else {
            return ""
        }
    }

    const renderBackButton = () => {
        const accountType = query.get("account_type")
        const accountId = query.get("account_id")

        if (!!accountType && !!accountId) {
            if (accountType === "billable_account") {
                return `/clients/${clientId}/billable_accounts/${accountId}/bills`
            } else {
                return `/clients/${clientId}/supply_accounts/${accountId}/bills`
            }
        } else {
            return actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")
        }
    }

    // const logUsageFactors = (index, key2) => {
    //     console.log("BILL: ", bill)
    //     console.log("INDEX: ", index)
    //     console.log("KEY2: ", key2)
    //     console.log("ACCOUNT: ", account)
    //     console.log("SERVICES @ INDEX: ", account.services[index])
    //     console.log("USAGE FACTORS: ", account.services[index].usage_factors)
    //     console.log("USAGE FACTORS AT KEY2: ", account.services[index].usage_factors[key2])
    //     console.log("")

    //     return account.services[index].usage_factors[key2].name
    // }

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}
            <Link to={renderBackButton}>
                <LeftOutlined />
                Go Back
            </Link>

            <Skeleton active loading={isLoading}>
                <Form
                    form={form}
                    // initialValues={{ ...bill, date: `${bill.date ? moment(bill.date) : ""}`, due_date: `${bill.due_date ? moment(bill.due_date) : ""}` }}
                    initialValues={{ ...bill, file: bill.signed_file_id, date: getInitialDate(bill.date), due_date: getInitialDate(bill.due_date) }}
                    onFinish={submit}
                    layout="vertical">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h2>
                                            <Link to={() => `/clients/${clientId}/dashboard`}>{account.client_name} &gt; </Link>
                                            <Link to={() => `/clients/${clientId}/divisions/${account.division_id}/dashboard`}>{account.division_name} &gt; </Link>
                                            <Link to={() => `/clients/${clientId}/facilities/${account.facility_id}/dashboard`}>{account.facility_name} &gt; </Link>
                                            {`${account.account_number}`}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Account Type" name="account_type" shouldUpdate={true}>
                                            <Select placeholder={"Select"} showArrow={true} onChange={handleAccountTypeSelect} disabled={!isEmpty(bill)}>
                                                <Option value="BillableAccount">Billable Account</Option>
                                                <Option value="SupplyAccount">Supply Account</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {/* <Col span={8}>
                                        <Form.Item label="Account" name="account_id">
                                            <Select placeholder={"Select Account"} showArrow={true} onChange={handleAccountSelect} disabled={!isEmpty(bill)}>
                                                {accountSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.account_number}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col> */}

                                    <Col span={8}>
                                        <Form.Item label="Account" name="account_id" shouldUpdate={true}>
                                            <Select
                                                disabled={!isEmpty(bill)}
                                                showSearch
                                                placeholder={"Select Account"}
                                                filterOption={false}
                                                defaultActiveFirstOption={false}
                                                showArrow={true}
                                                onSearch={handleAccountSearch}
                                                onChange={handleAccountSelect}>
                                                {accountSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.account_number}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {Object.keys(account).length !== 0 && (
                                        <Col span={4}>
                                            <Button type="primary" style={{ marginTop: "31px" }} onClick={() => setShowBillHistory(!showBillHistory)}>
                                                {`${showBillHistory ? "Hide" : "Show"} Bill History`}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>

                                {showBillHistory && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <BillHistoryTable data={account.last_five_bills ? account.last_five_bills : []} />
                                        </Col>
                                    </Row>
                                )}

                                <hr />
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <WarningChip contentText={bill?.["flags"]?.bill_date_flag} />
                                        <Form.Item label="Bill Date" name="date" rules={[{ required: true }]}>
                                            <DatePicker format={dateFormat} style={{ width: "100%" }} onChange={handleDateChange} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <WarningChip contentText={bill?.["flags"]?.due_date_flag} />
                                        <Form.Item label="Due Date" name="due_date" rules={[{ required: true }]}>
                                            <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Previous Bill" name="bill_id">
                                            <Select
                                                placeholder={"Select"}
                                                allowClear={true}
                                                showArrow={true}
                                                onChange={handlePreviousBillSelect}
                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}>
                                                {previousBillSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.full_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <WarningChip contentText={bill?.["flags"]?.balance_forward_flag} />
                                        <Form.Item
                                            label="Balance Forward"
                                            name="balance_forward"
                                            rules={[{ required: true }]}
                                            disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                            onChange={calculateAmountDue}>
                                            <InputNumber disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} style={{ width: "100%" }} precision={2} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <WarningChip contentText={bill?.["flags"]?.current_charges_flag} />
                                        <Form.Item
                                            label="Current Charges"
                                            name="current_charges"
                                            rules={[{ required: true }]}
                                            disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                            onChange={calculateAmountDue}>
                                            <InputNumber disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} style={{ width: "100%" }} precision={2} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <WarningChip contentText={bill?.["flags"]?.late_fee_flag} />
                                        <Form.Item
                                            label="Late Fee"
                                            name="late_fee"
                                            rules={[{ required: false }]}
                                            disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                            onChange={calculateAmountDue}>
                                            <InputNumber disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} style={{ width: "100%" }} precision={2} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="Amount Due" name="amount_due" rules={[{ required: true }]} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}>
                                            <InputNumber disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} style={{ width: "100%" }} precision={2} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <hr />

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Notes" name="notes" rules={[{ required: false }]} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}>
                                            <TextArea rows={4} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item label="Rebill/Adjustment" name="rebill_adjustment" valuePropName="checked" rules={[{ required: false }]} style={{ marginBottom: "5px" }}>
                                                    <Switch disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item label="QC" name="qc" valuePropName="checked" rules={[{ required: false }]}>
                                                    <Switch disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item label="Parked" name="parked" valuePropName="checked" rules={[{ required: false }]}>
                                                    <Switch disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <p>
                                                    Client Batch: <br /> {bill.accounting_batch_batch_code}
                                                </p>
                                            </Col>
                                            <Col span={8}>
                                                <p>
                                                    Vendor Payment: <br /> {bill.vendor_payment_id ? bill.vendor_payment_full_name : ""}
                                                </p>
                                            </Col>
                                            <Col span={8}>
                                                <p>
                                                    Amount Paid: <br /> {bill.amount_paid ? formatCurrency(bill.amount_paid) : ""}
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <p>
                                                    Vendor Batch Notes: <br /> {bill.payment_batch_notes}
                                                </p>
                                            </Col>
                                            <Col span={8}>
                                                <p>
                                                    Payment ID: <br /> {bill.check_number}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {account && !account.is_group_account && (
                                    <>
                                        <hr />
                                        <h2>Services / Meters</h2>
                                        {/* If a bill is present, we need to populate the nested form with its service_bill values.
                                            If the bill is not present, we need to populate it based off the accounts services. */}
                                        {/* <Form.List name="service_bill_attributes" initialValue={billId ? bill.service_bills : account.services}> */}
                                        <Skeleton active loading={!bill || accountLoading}>
                                            {isSupplyBill && account.billable_service_ids ? (
                                                <>
                                                    <h3>Acount Number - Meter Number - Usage - Read Date</h3>
                                                    <LinkedServiceForm bill={bill} form={form} accountId={account.id} initialValue={initialServiceValues()} />
                                                </>
                                            ) : (
                                                <Form.List name="service_bills_attributes" initialValue={initialServiceValues()}>
                                                    {(fields, {}) => (
                                                        <>
                                                            {fields.map((field, index) => {
                                                                let accountService, meterText

                                                                const formServiceBill = form.getFieldValue("service_bills_attributes")[field.fieldKey]

                                                                try {
                                                                    accountService = account.services.filter((s) => s.id === formServiceBill.service_id)[0]

                                                                    const prevServiceBill =
                                                                        !isEmpty(previousBill) && previousBill.service_bills.filter((sb) => sb.service_id === formServiceBill.service_id)[0]

                                                                    meterText = (
                                                                        <div>
                                                                            {`Service/Meter ${accountService.utility_service_name} - ${accountService.meter_number}`}

                                                                            {
                                                                                <span style={{ float: "right" }}>
                                                                                    {prevServiceBill.usage
                                                                                        ? `Previous Usage Was ${roundHumanizedNumbers(prevServiceBill.usage)} ${
                                                                                              prevServiceBill.unit_symbol
                                                                                          } From ${moment(prevServiceBill.read_date).format("L")} Reading of ${prevServiceBill.meter_reading}`
                                                                                        : "No Record Of Usage From Previous Bill For This Service"}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                } catch (e) {
                                                                    console.warn("Could not find account service: ", e)
                                                                }

                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <Alert message={meterText || "ERROR"} type="info" />

                                                                        <Form.Item {...field} name={[field.name, "service_id"]} fieldKey={[field.fieldKey, "service_id"]} hidden={true} />

                                                                        <Row gutter={24}>
                                                                            <Col span={6}>
                                                                                <Row gutter={24}>
                                                                                    <Col span={24}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.previous_read_date_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Prev Read Date"
                                                                                            name={[field.name, "previous_read_date"]}
                                                                                            fieldKey={[field.fieldKey, "previous_read_date"]}
                                                                                            rules={[{ required: true, message: "Required" }]}>
                                                                                            <DatePicker
                                                                                                format={dateFormat}
                                                                                                style={{ width: "100%" }}
                                                                                                onChange={() => handleReadDateChange(field.fieldKey)}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Read Date"
                                                                                            name={[field.name, "read_date"]}
                                                                                            fieldKey={[field.fieldKey, "read_date"]}
                                                                                            rules={[{ required: true, message: "Required" }]}>
                                                                                            <DatePicker
                                                                                                format={dateFormat}
                                                                                                style={{ width: "100%" }}
                                                                                                onChange={() => handleReadDateChange(field.fieldKey)}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Next Read Date"
                                                                                            name={[field.name, "next_read_date"]}
                                                                                            fieldKey={[field.fieldKey, "next_read_date"]}
                                                                                            rules={[{ required: false, message: "Required" }]}>
                                                                                            <DatePicker
                                                                                                format={dateFormat}
                                                                                                style={{ width: "100%" }}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Fiscal Month"
                                                                                            name={[field.name, "fiscal_month"]}
                                                                                            fieldKey={[field.fieldKey, "fiscal_month"]}
                                                                                            rules={[{ required: false, message: "Required" }]}>
                                                                                            <DatePicker
                                                                                                format={dateFormat}
                                                                                                style={{ width: "100%" }}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.number_of_days_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="# of Days"
                                                                                            name={[field.name, "number_of_days"]}
                                                                                            fieldKey={[field.fieldKey, "number_of_days"]}
                                                                                            rules={[{ required: true, message: "Required" }]}>
                                                                                            <Input disabled={true} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>

                                                                            <Col span={6}>
                                                                                <Row gutter={24}>
                                                                                    <Col span={24}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.previous_reading_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Prev Reading"
                                                                                            name={[field.name, "previous_reading"]}
                                                                                            fieldKey={[field.fieldKey, "previous_reading"]}>
                                                                                            <InputNumber
                                                                                                style={{ width: "100%" }}
                                                                                                precision={2}
                                                                                                onChange={() => evalServiceBillUsage(index)}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Meter Reading"
                                                                                            name={[field.name, "meter_reading"]}
                                                                                            fieldKey={[field.fieldKey, "meter_reading"]}>
                                                                                            <InputNumber
                                                                                                style={{ width: "100%" }}
                                                                                                precision={2}
                                                                                                onChange={() => evalServiceBillUsage(index)}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.multiplier_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="multiplier"
                                                                                            name={[field.name, "multiplier"]}
                                                                                            fieldKey={[field.fieldKey, "multiplier"]}
                                                                                            initialValue={accountService?.meter_multiplier}>
                                                                                            <InputNumber
                                                                                                style={{ width: "100%" }}
                                                                                                precision={3}
                                                                                                onChange={() => evalServiceBillUsage(index)}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.usage_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="Usage"
                                                                                            name={[field.name, "usage"]}
                                                                                            fieldKey={[field.fieldKey, "usage"]}
                                                                                            rules={[{ required: true, message: "Required" }]}>
                                                                                            <InputNumber
                                                                                                style={{ width: "100%" }}
                                                                                                precision={3}
                                                                                                disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={12}>
                                                                                        <Form.Item {...field} label="Unit" name={[field.name, "unit_id"]} fieldKey={[field.fieldKey, "unit_id"]}>
                                                                                            <Select showArrow={true} disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}>
                                                                                                {Object.keys(bill).length !== 0 ? (
                                                                                                    bill.service_bills[index].unit_options.map((o) => (
                                                                                                        <Option key={o.id} value={o.id}>
                                                                                                            {o.symbol}
                                                                                                        </Option>
                                                                                                    ))
                                                                                                ) : // Woo buddy...There's a catch happening when switching from an account that has services to
                                                                                                // one that doesn't. This is a temporary fix to keep the form from blowing up...
                                                                                                !isEmpty(account.services) ? (
                                                                                                    account.services[index].unit_options.map((o) => (
                                                                                                        <Option key={o.id} value={o.id}>
                                                                                                            {o.symbol}
                                                                                                        </Option>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <Option />
                                                                                                )}
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={12}>
                                                                                        <WarningChip contentText={billId ? bill.service_bills[field.fieldKey]?.flags?.estimated_flag : ""} />
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            label="estimated"
                                                                                            name={[field.name, "estimated"]}
                                                                                            valuePropName="checked"
                                                                                            fieldKey={[field.fieldKey, "estimated"]}>
                                                                                            <Switch disabled={!isEmpty(bill) && (bill.finalized || bill.paid)} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>

                                                                            <Col span={6}>
                                                                                <Row gutter={24}>
                                                                                    <Col span={24}>
                                                                                        <Form.Item noStyle={true}>
                                                                                            <Form.List name={[field.name, "service_bill_entry_fields_attributes"]}>
                                                                                                {(service_bill_entry_fields_attributes, {}) => {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            {service_bill_entry_fields_attributes.map((entryField, key2) => (
                                                                                                                <Form.Item
                                                                                                                    {...entryField}
                                                                                                                    label={
                                                                                                                        // Woo buddy...There's a catch happening when switching from an account that has services to
                                                                                                                        // one that doesn't. This is a temporary fix to keep the form from blowing up...
                                                                                                                        Object.keys(bill).length !== 0
                                                                                                                            ? bill.service_bills[index].service_bill_entry_fields[key2].name
                                                                                                                            : !isEmpty(account.services)
                                                                                                                            ? account.services[index].entry_fields[key2].name
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    name={[entryField.name, "value"]}
                                                                                                                    fieldKey={[entryField.fieldKey, "value"]}
                                                                                                                    key={key2}
                                                                                                                    rules={[
                                                                                                                        {
                                                                                                                            required: evalUtilityChargeRequired(index, key2),
                                                                                                                            message: "Utility Charges are required",
                                                                                                                        },
                                                                                                                    ]}>
                                                                                                                    <InputNumber
                                                                                                                        disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                                                        style={{ width: "100%" }}
                                                                                                                        precision={2}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    )
                                                                                                }}
                                                                                            </Form.List>
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>

                                                                            <Col span={6}>
                                                                                <Row gutter={24}>
                                                                                    <Col span={24} style={{ marginBottom: "0" }}>
                                                                                        <Form.Item noStyle={true}>
                                                                                            <Form.List name={[field.name, "service_bill_usage_factors_attributes"]}>
                                                                                                {(service_bill_usage_factors_attributes, {}) => {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            {service_bill_usage_factors_attributes.map((usageFactor, key2) => (
                                                                                                                <Form.Item
                                                                                                                    {...usageFactor}
                                                                                                                    label={
                                                                                                                        // Woo buddy...There's a catch happening when switching from an account that has services to
                                                                                                                        // one that doesn't. This is a temporary fix to keep the form from blowing up...
                                                                                                                        Object.keys(bill).length !== 0
                                                                                                                            ? bill.service_bills[index].service_bill_usage_factors[key2].name
                                                                                                                            : !isEmpty(account.services)
                                                                                                                            ? account.services[index].usage_factors[key2].name
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    name={[usageFactor.name, "value"]}
                                                                                                                    rules={[{ required: true, message: "Required" }]}
                                                                                                                    fieldKey={[usageFactor.fieldKey, "value"]}
                                                                                                                    key={key2}>
                                                                                                                    <InputNumber
                                                                                                                        style={{ width: "100%" }}
                                                                                                                        precision={4}
                                                                                                                        // If a bill is locked for editing (finalized OR paid status), normally you can edit this field regardless.
                                                                                                                        // When this bill is locked AND this usage factor was marked as an adjustment factor when the bill was made, you CANNOT edit it.
                                                                                                                        // This logic block is just checking to see if 1. the bill is locked in the first place (finalized or paid) and 2. is the adjustment factor set as true.
                                                                                                                        disabled={
                                                                                                                            !isEmpty(bill) &&
                                                                                                                            ((bill.finalized &&
                                                                                                                                bill.service_bills[index].service_bill_usage_factors[key2]
                                                                                                                                    .adjustment_factor) ||
                                                                                                                                (bill.paid &&
                                                                                                                                    bill.service_bills[index].service_bill_usage_factors[key2]
                                                                                                                                        .adjustment_factor))
                                                                                                                        }
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    )
                                                                                                }}
                                                                                            </Form.List>
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    <Col span={24}>
                                                                                        <Form.Item noStyle={true}>
                                                                                            <Form.List name={[field.name, "service_bill_additional_entry_fields_attributes"]}>
                                                                                                {(service_bill_additional_entry_fields_attributes, {}) => {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            {service_bill_additional_entry_fields_attributes.map((additionalEntryField, key2) => (
                                                                                                                <Form.Item
                                                                                                                    {...additionalEntryField}
                                                                                                                    label={
                                                                                                                        // Woo buddy...There's a catch happening when switching from an account that has services to
                                                                                                                        // one that doesn't. This is a temporary fix to keep the form from blowing up...
                                                                                                                        Object.keys(bill).length !== 0
                                                                                                                            ? bill.service_bills[index].service_bill_additional_entry_fields[key2].name
                                                                                                                            : !isEmpty(account.services)
                                                                                                                            ? account.services[index].additional_entry_fields[key2].name
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    name={[additionalEntryField.name, "value"]}
                                                                                                                    fieldKey={[additionalEntryField.fieldKey, "value"]}
                                                                                                                    key={key2}>
                                                                                                                    <InputNumber
                                                                                                                        disabled={!isEmpty(bill) && (bill.finalized || bill.paid)}
                                                                                                                        precision={4}
                                                                                                                        style={{ width: "100%" }}
                                                                                                                    />
                                                                                                                </Form.Item>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    )
                                                                                                }}
                                                                                            </Form.List>
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <Form.Item {...field} label="Notes" name={[field.name, "notes"]} fieldKey={[field.fieldKey, "notes"]}>
                                                                                            <TextArea style={{ width: "100%" }} rows="3" />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>

                                                                        {renderServiceBillHistory(field.fieldKey)}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </Form.List>
                                            )}
                                        </Skeleton>
                                    </>
                                )}

                                <hr />
                                <br />
                                <Col>
                                    <Row style={{ alignItems: "center" }}>
                                        <Form.Item className="mr-5">
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>

                                        <Form.Item name="file" hidden={true} />
                                        <FileUpload form={form} fileLink={bill.file_url} filename={bill.filename} />

                                        <hr />
                                        <br />
                                    </Row>
                                </Col>
                                {/* <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>

                                <Form.Item name="file" hidden={true} />
                                <FileUpload form={form} fileLink={bill.file_url} filename={bill.filename} />

                                <hr />
                                <br /> */}
                            </div>
                        </div>
                    </div>
                </Form>
            </Skeleton>
        </div>
    )
}
