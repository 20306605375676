import { createSlice } from "@reduxjs/toolkit"

import client from "../../lib/client"
import { clientsUrl, clientUrl, clientSelectOptionsUrl, clientSelectOptionByIdUrl, clientDropdownOptionsUrl, searchClientsUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"

export const clientsSlice = createSlice({
    name: "clients",
    initialState: {
        client: {},
        clients: [],
        clientSelectOptions: [],
        clientDropdownOptions: [],
        clientSearch: [],
        total: 0,
        dropdownTotal: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload
        },
        setClients: (state, action) => {
            state.clients = action.payload.clients
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setClientSelectOptions: (state, action) => {
            state.clientSelectOptions = action.payload
        },
        setClientDropdownOptions: (state, action) => {
            state.clientDropdownOptions = action.payload
            state.dropdownTotal = action.payload.total
        },
        setClientSearch: (state, action) => {
            state.clientSearch = action.payload
        },
    },
})

export const { setClient, setClients, setPagination, setLoading, setClientSelectOptions, setClientDropdownOptions, setClientSearch } = clientsSlice.actions

export function fetchClient(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(clientUrl(id)).then((response) => {
            dispatch(setClient(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClients(pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${clientsUrl}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${clientsUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setClients(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function submitClient(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(clientUrl(id), { client: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(clientsUrl, { client: values })
            return response.data
        }
    }
}

export function fetchClientSelectOptions(searchTerm) {
    return async (dispatch, getState) => {
        client.get(`${clientSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
            dispatch(setClientSelectOptions(response.data.clients))
        })
    }
}

export function fetchClientSelectOptionById(id) {
    return async (dispatch, getState) => {
        client.get(`${clientSelectOptionByIdUrl}?client_id=${id}`).then((response) => {
            dispatch(setClientSelectOptions(response.data.client))
        })
    }
}

export function fetchClientDropdownOptions(pagination, searchTerm = null) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        // client.get(`${clientSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
        //     dispatch(setClientSidebarOptions(response.data.clients))
        // })

        if (searchTerm) {
            client.get(`${clientDropdownOptionsUrl}?search_term=${searchTerm}&page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
                // dispatch(setPagination(pagination))
                dispatch(setClientDropdownOptions(response.data))
                dispatch(setLoading(false))
            })
        } else {
            client.get(`${clientDropdownOptionsUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
                // dispatch(setPagination(pagination))
                dispatch(setClientDropdownOptions(response.data))
                dispatch(setLoading(false))
            })
        }
    }
}

export function searchClients(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${searchClientsUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setClientSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterClients(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, clientsUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setClients(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectClient = (state) => state.clients.client
export const selectClients = (state) => state.clients.clients
export const selectTotal = (state) => state.clients.total
export const selectPagination = (state) => state.clients.pagination
export const selectLoading = (state) => state.clients.loading
export const selectClientSelectOptions = (state) => state.clients.clientSelectOptions
export const selectClientDropdownOptions = (state) => state.clients.clientDropdownOptions
export const selectDropdownTotal = (state) => state.clients.dropdownTotal
export const selectClientSearch = (state) => state.clients.clientSearch

export default clientsSlice.reducer
