import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, Select, InputNumber } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import { notification } from "antd"

import { fetchFacility, setFacility, selectFacility, submitFacility } from "../facilitiesSlice"
import { fetchFacilityTypeSelectOptions, selectFacilityTypeSelectOptions } from "../../settings/facilityTypes/facilityTypesSlice"
import { fetchProductionUnitSelectOptions, selectProductionUnitSelectOptions } from "../../settings/productionUnits/productionUnitsSlice"
import {
    fetchDivisionSelectOptions,
    setDivisionSelectOptions,
    fetchDivisionSelectOptionById,
    selectLoading,
    selectDivisions,
    selectTotal,
    fetchDivisions,
    selectDivisionSelectOptions,
} from "../../divisions/divisionsSlice"
import PaginatedSelect from "../../forms/components/PaginatedSelect"

const { Option } = Select

export default function FacilityForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const facility = useSelector(selectFacility)
    const productionUnitSelectOptions = useSelector(selectProductionUnitSelectOptions)
    const facilityTypeSelectOptions = useSelector(selectFacilityTypeSelectOptions)
    const divisionLoading = useSelector(selectLoading)
    const { facilityId, clientId, divisionId } = useParams()
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchedF, setFetchedF] = useState(false)
    const [fetchedFT, setFetchedFT] = useState(false)
    const [fetchedPU, setFetchedPU] = useState(false)
    const [fetchedD, setFetchedD] = useState(false)

    useEffect(() => {
        if (facilityId && !fetchedF) {
            dispatch(fetchFacility(facilityId))
            setFetchedF(true)
        } else {
            dispatch(setFacility({}))
        }
    }, [dispatch, fetchedF, facilityId])

    // When a Faciliy is set, we need to fetch its Division in order
    // to prepopulate the Division select with the current value
    useEffect(() => {
        form.resetFields()

        if (!fetchedFT) {
            dispatch(fetchFacilityTypeSelectOptions())
            setFetchedFT(true)
        }

        if (!fetchedPU) {
            dispatch(fetchProductionUnitSelectOptions())
            setFetchedPU(true)
        }

        if (facility.division_id && !fetchedD) {
            dispatch(fetchDivisionSelectOptionById([facility.division_id]))
            setFetchedD(true)
        }
    }, [dispatch, fetchedD, fetchedF, fetchedFT, fetchedPU, form, facility])

    // TODO: Need to set up debounce searching to only fire request after delay
    useEffect(() => {
        if (searchTerm.length >= "3") {
            dispatch(fetchDivisionSelectOptions(clientId, searchTerm))
        } else {
            dispatch(setDivisionSelectOptions([]))
        }
    }, [dispatch, searchTerm])

    const handleSearchUpdate = (value) => {
        setSearchTerm(value)
    }

    const handleFacilityTypeSelect = (value) => {
        form.setFieldsValue({ facility_type_id: value })
    }

    const handleProductionUnitsSelect = (value) => {
        // form.setFieldsValue({ facility_production_units_attributes: [{ production_unit_id: 1 }] })
        form.setFieldsValue({ production_unit_ids: value })
    }

    const handleDivisionSelect = (value) => {
        form.setFieldsValue({ division_id: value })
    }

    const submit = async (values) => {
        const response = await dispatch(submitFacility(values, facilityId))

        if (response.success) {
            let path = `/clients/${clientId}`

            if (divisionId) {
                path += `/divisions/${divisionId}/facilities`
            } else {
                path += `/facilities`
            }

            history.push(path)
        } else {
            notification.error({
                message: response.message,
                duration: 0.8,
            })
        }
    }

    return (
        <div id="user-form-container">
            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={facility} onFinish={submit} layout="vertical">
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Number" name="number" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Square Footage" name="square_footage" rules={[{ required: false }]}>
                                        <InputNumber min={0} precision={4} step={0.01} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Facility Type" name="facility_type_id">
                                        <Select
                                            placeholder={"Please select a facility type"}
                                            showArrow={true}
                                            onChange={handleFacilityTypeSelect}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {facilityTypeSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.name}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    {/* <Form.Item label="Production Units" name="facility_production_units_attributes"> */}
                                    <Form.Item label="Production Units" name="production_unit_ids">
                                        <Select
                                            placeholder={"Please select production units"}
                                            showArrow={true}
                                            onChange={handleProductionUnitsSelect}
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {productionUnitSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.name}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Division" name="division_id" rules={[{ required: true }]}>
                                        <PaginatedSelect
                                            loading={divisionLoading}
                                            placeholder="Please select a division"
                                            onChange={handleDivisionSelect}
                                            onSearch={handleSearchUpdate}
                                            paginationSelector={selectDivisions}
                                            paginationTotalSelector={selectTotal}
                                            loadPaginatedData={(pagination) => fetchDivisions(pagination, `/clients/${clientId}/divisions`)}
                                            searchSelector={selectDivisionSelectOptions}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
