import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Form, Input, Row, Col, Select } from "antd"
import { LeftOutlined } from "@ant-design/icons"

import { fetchClientVendorNumber, selectClientVendorNumber, setClientVendorNumber, selectLoading, submitClientVendorNumber, deleteClientVendorNumber } from "./clientVendorNumbersSlice"
import { fetchVendors, fetchVendorSelectOptions, selectVendorSelectOptions, selectVendors, setVendorSelectOptions, selectTotal } from "../vendors/vendorsSlice"
import { requestNotification } from "../../lib/notifications"
import PaginatedSelect from "../forms/components/PaginatedSelect"

const { Option } = Select

export default function ClientVendorNumberForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const clientVendorNumber = useSelector(selectClientVendorNumber)
    const { clientId, clientVendorNumberId } = useParams()
    const history = useHistory()
    // const vendorSelectOptions = useSelector(selectVendorSelectOptions)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        if (clientVendorNumberId) {
            dispatch(fetchClientVendorNumber(clientId, clientVendorNumberId))
        } else {
            dispatch(setClientVendorNumber({}))
        }
    }, [dispatch, clientVendorNumberId])

    useEffect(() => {
        form.resetFields()
        dispatch(fetchVendorSelectOptions())
    }, [form, clientVendorNumber])

    // TODO: Need to set up debounce searching to only fire request after delay
    useEffect(() => {
        if (searchTerm.length >= "3") {
            dispatch(fetchVendorSelectOptions(null, null, null, searchTerm))
        } else {
            dispatch(setVendorSelectOptions([]))
        }
    }, [dispatch, searchTerm])

    const handleSearchUpdate = (value) => {
        setSearchTerm(value)
    }

    const handleVendorSelect = (value) => {
        form.setFieldsValue({ vendor_id: value })
    }

    const submit = async (values) => {
        const response = await dispatch(submitClientVendorNumber({ ...values, client_id: clientId }, clientId, clientVendorNumberId))

        requestNotification(response)

        if (response.success) {
            history.push(`/clients/${clientId}/client_vendor_numbers`)
        }
    }

    return (
        <div id="user-form-container">
            <h1>Client Vendor Number</h1>

            <Link to={`/clients/${clientId}/client_vendor_numbers`}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form form={form} initialValues={clientVendorNumber} onFinish={submit} layout="vertical">
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Vendor" name="vendor_id" rules={[{ required: true }]}>
                                        <PaginatedSelect
                                            loading={false}
                                            placeholder="Please select a Vendor"
                                            onChange={handleVendorSelect}
                                            onSearch={handleSearchUpdate}
                                            paginationSelector={selectVendors}
                                            paginationTotalSelector={selectTotal}
                                            loadPaginatedData={(pagination) => fetchVendors(pagination, "vendors")}
                                            searchSelector={selectVendorSelectOptions}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Vendor Number" name="vendor_number" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
