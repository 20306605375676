import { createSlice } from "@reduxjs/toolkit"
import { endDate, startDate } from "./consts/date"

const emptyState = {
    unpaid: true,
    filteredClients: [],
    filteredVendorNames: [],
    filteredDateRange: [startDate.getTime(), endDate.getTime()],
    filteredIds1: [],
    filteredIds2: [],
    filteredPaymentMethods: [],
    filteredAmountRange: [],
    filteredFormat: 1,
}

export const globalBatchFilterSlice = createSlice({
    name: "globalBatchFilter",
    initialState: emptyState,

    reducers: {
        setFilteredClients: (state, action) => {
            if (!action.payload.delete) {
                state.filteredClients.push(action.payload.value)
            } else {
                state.filteredClients = action.payload.value
            }
        },
        setFilteredVendors: (state, action) => {
            if (!action.payload.delete) {
                state.filteredVendorNames.push(action.payload.value)
            } else {
                state.filteredVendorNames = action.payload.value
            }
        },
        setFilteredDateRange: (state, action) => {
            if (action.payload.index != undefined) {
                state.filteredDateRange[action.payload.index] = action.payload.value
            } else {
                state.filteredDateRange = action.payload
            }
        },
        setUnpaid: (state, action) => {
            state.unpaid = action.payload
        },
        setFilteredIds1: (state, action) => {
            if (!action.payload.delete) {
                state.filteredIds1.push(action.payload.value)
            } else {
                state.filteredIds1 = action.payload.value
            }
        },
        setFilteredIds2: (state, action) => {
            if (!action.payload.delete) {
                state.filteredIds2.push(action.payload.value)
            } else {
                state.filteredIds2 = action.payload.value
            }
        },
        setFilteredPaymentMethods: (state, action) => {
            if (!action.payload.delete) {
                state.filteredPaymentMethods.push(action.payload.value)
            } else {
                state.filteredPaymentMethods = action.payload.value
            }
        },
        setFilteredAmountRange: (state, action) => {
            if (action.payload.initialFetch != undefined) {
                state.filteredAmountRange = action.payload.value.amount_range
            } else if (action.payload.index != undefined) {
                state.filteredAmountRange[action.payload.index] = action.payload.value
            } else {
                state.filteredAmountRange = action.payload
            }
        },

        // Probably don't need this anymore.
        setFilteredFormat: (state, action) => {
            let newFormat = action.payload
            switch (
                newFormat // Switch to make future formats easier to implement
            ) {
                case 2:
                    state.filteredFormat = 2
                    return
                default:
                    state.filteredFormat = 1
            }
        },
        clearState: (state, action) => {
            return emptyState
        },
    },
})

export const {
    setUnpaid,
    setFilteredVendors,
    setFilteredClients,
    setFilteredDateRange,
    setFilteredIds1,
    setFilteredIds2,
    setFilteredPaymentMethods,
    setFilteredAmountRange,
    setFilteredFormat,
    clearState
} = globalBatchFilterSlice.actions

export const selectUnpaid = (state) => state.globalBatchFilter.unpaid
export const selectFilteredClients = (state) => state.globalBatchFilter.filteredClients
export const selectFilteredVendors = (state) => state.globalBatchFilter.filteredVendorNames
export const selectFilteredDateRange = (state) => state.globalBatchFilter.filteredDateRange
export const selectFilteredIds1 = (state) => state.globalBatchFilter.filteredIds1
export const selectFilteredIds2 = (state) => state.globalBatchFilter.filteredIds2
export const selectFilteredPaymentMethods = (state) => state.globalBatchFilter.filteredPaymentMethods
export const selectFilteredAmountRange = (state) => state.globalBatchFilter.filteredAmountRange
export const selectFilteredFormat = (state) => state.globalBatchFilter.filteredFormat

export default globalBatchFilterSlice.reducer