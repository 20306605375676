import React, { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Select, Switch, DatePicker, InputNumber } from "antd"
import { ConsoleSqlOutlined, LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { requestNotification } from "../../../lib/notifications"
import { fetchGlobalBatch, selectCheckedBills, selectGlobalBatch, selectLoading, setGlobalBatch, submitGlobalBatch } from "../globalBatchSlice"
import { fetchVendorSelectOptions, selectVendorSelectOptions } from "../../vendors/vendorsSlice"

const { Option } = Select
const dateFormat = "MM/DD/YYYY"
const { TextArea } = Input

export default function GlobalBatchForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const { globalBatchId } = useParams()

    const checkedBills = useSelector(selectCheckedBills)
    const globalBatch = useSelector(selectGlobalBatch)
    const loading = useSelector(selectLoading)
    const vendorSelectOptions = useSelector(selectVendorSelectOptions)
    const [payerSelectOptions, setPayerSelectOptions] = useState([])
    const [batchTotal, setBatchTotal] = useState(0)

    function calculateBatchTotal() {
        let total = 0
        for(let i = 0; i < checkedBills.length; i++){
            const { amount_paid } = checkedBills[i]
            total += amount_paid
        }
        return total
    }

    // useEffect(() => { // TODO: Add way to make it so user know that refreshing or leaving page will reset their bill selection.
    //     const handleBeforeUnload = (event) => {
    //         event.preventDefault();
    //         event.returnValue = ""
    //     };

    //     window.addEventListener("beforeunload", handleBeforeUnload);

    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);

    useEffect(() => {
        if (globalBatchId) {
            dispatch(fetchGlobalBatch(globalBatchId))
        } else {
            dispatch(setGlobalBatch({}))
        }
    }, [dispatch, globalBatchId])

    useEffect(() => {
        form.resetFields()
        setBatchTotal(calculateBatchTotal())
        dispatch(fetchVendorSelectOptions(globalBatch.client_id))

        // if (globalBatch && globalBatch.payer_type === "Division") {
        //     setPayerSelectOptions(divisionSelectOptions)
        // } else {
        //     if (client && client.name) {
        //         setPayerSelectOptions([{ id: parseInt(clientId), name: client.name }])
        //     }
        // }
    }, [dispatch, globalBatch])

    const submit = async (values) => {
        const globalBatchValuesParams = { 
            ...values, 
            payer_type: "Client",
            // bill_ids: billIds, // not sure if needed
            batch_date: format_date(values.batch_date), 
            bills_due_date: format_date(values.bills_due_date), 
            client_id: globalBatch.client_id 
        }

        const response = await dispatch(submitGlobalBatch(globalBatchValuesParams, globalBatch.id))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../../todays_batches/OP")}`) : history.push(`${nodePath.join(url, "../")}`)
        }

        requestNotification(response)
    }

    function format_date(date) {
        return moment.isMoment(date) ? date.format("YYYY-MM-DD") : moment(date, "MM/DD/YYYY").format("YYYY-MM-DD")
    }

    return (
        <div id="user-form-container">
            <Link to={actionName === "edit" ? nodePath.join(url, "../../todays_batches/OP").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form
                layout="vertical"
                form={form}
                onFinish={submit}
                initialValues={{
                    ...globalBatch,
                    batch_date: moment(globalBatch.batch_date || new Date()),
                    bills_due_date: moment(globalBatch.bills_due_date || new moment().add(14, "d")),
                    batch_total: globalBatch.batch_total ? globalBatch.batch_total : batchTotal,
                }}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                {/* <Col span={8}>
                                    <Form.Item label="Payer Type" name="payer_type" rules={[{ required: true }]}>
                                        <Select showArrow={true} onChange={handlePayerTypeSelect} disabled={actionName === "edit"}>
                                            <Option key={1} value="Client">
                                                Client
                                            </Option>

                                            <Option key={2} value="Division">
                                                Division
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                                <Col span={8}>
                                    <Form.Item label="Payer" name="payer_id" rules={[{ required: true }]}>
                                        <Select
                                            showArrow={true}
                                            disabled={actionName === "edit"}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {payerSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.name}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Closed" name="closed" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Vendor" name="vendor_ids">
                                        <Select placeholder={"Automatically added from bills"} showArrow={true} mode="multiple" disabled>
                                            {vendorSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Batch Date" name="batch_date" rules={[{ required: true }]}>
                                        <DatePicker
                                            format={dateFormat}
                                            style={{ width: "100%" }}
                                            allowClear={false}
                                            onChange={(date) => {
                                                let dueDate = moment(date).add(14, "d")
                                                form.setFieldsValue({ bills_due_date: dueDate })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Bills Due on or Before" name="bills_due_date" rules={[{ required: true }]}>
                                        <DatePicker format={dateFormat} style={{ width: "100%" }} allowClear={false} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Notes" name="notes" rules={[{ required: false }]}>
                                        <TextArea />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Batch Total" name="batch_total" rules={[{ required: false }]}>
                                        <InputNumber disabled={true} precision={2} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}