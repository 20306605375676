import { useDispatch } from 'react-redux';
import { Modal, Button } from 'antd';

const { confirm } = Modal;

const useConfirmGlobalBatchCreationModal = () => {
  const dispatch = useDispatch();

  const showConfirm = (onAccept, bills, filteredDateRange) => {
    confirm({
        title: "Create batches?",
        content: "Clicking yes will create payment batches according to client and payment type.\n",
        onOk() {
          dispatch(onAccept(bills, filteredDateRange))
        },
        onCancel() {},
        okText: 'Yes',
        cancelText: "no"
    })
  };

  return showConfirm;
};

export default useConfirmGlobalBatchCreationModal;