import { allObjectValuesEmpty } from "./allObjectValuesEmpty"

export function createFilterUrl(query, clientScopedUrl, alternateFilter = "filter") {
    /// create the start of the filter URL string
    let filterUrl = ""

    if (alternateFilter === "") {
        filterUrl = `${clientScopedUrl}?`
    } else {
        filterUrl = `${clientScopedUrl}/${alternateFilter}?`
    }

    /// if all of the values in the filter object are not empty, we take each of the key-value pairs in the query,
    ///  and add on another part to the filterUrl. in the query:
    /// { each key } - correlates to the field that needs to be filtered and its ransack predicate (ex. name_cont), and
    /// { each value } - correlates to the string to filter it by
    if (!allObjectValuesEmpty(query.filters)) {
        Object.entries(query.filters).forEach(([key, value]) => {
            if((key == 'due_date_gteq' || key == 'due_date_lteq' || key == 'batch_date_gteq' || key == 'batch_date_lteq') && value.indexOf('/') >= 0){
                value = formatDate(value)
            }
            filterUrl += `q[${key}]=${value}&`
        })
    }

    // Dates were being an issue when setting date range
    // Dates should always be formatted YYYY-MM-DD for api call
    function formatDate(date){
        const dateSplit = date.split('/')
        const month = dateSplit[0]
        const day = dateSplit[1]
        const year = dateSplit[2]

        return `${year}-${month}-${day}`
    }

    // find more elegant way to do this //
    if(query.clientId){
        filterUrl += `&client_id=${query.clientId}&`
    }

    if(query.vendorId){
        filterUrl += `&vendor_id=${query.vendorId}&`
    }

    if(query.unpaid){
        filterUrl += `&unpaid=${query.unpaid}&`
    }
    // <-----------------------------> //

    /// add in filter by column and direction
    filterUrl += `page=${query.pagination.current}&per=${query.pagination.pageSize}&q[s]=${query.sort.column} ${query.sort.direction}`

    return filterUrl
}
