import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Switch } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"

// import { fetchLedgerField, selectLedgerField, setLedgerField, submitLedgerField } from "../ledgerFieldsSlice"
import { selectClient, fetchClient, submitClient } from "../clientsSlice"
import { selectScopedClient } from "../../../stores/appSlice"

import { requestNotification } from "../../../lib/notifications"

import Role from "../../userRoles/Role"

export default function SubScriptionsForms({ clientId }) {
    const { url } = useRouteMatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    // const { clientId } = useParams()
    const client = useSelector(selectClient)
    // const client = useSelector(selectScopedClient)

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClient(clientId))
        }
    }, [dispatch, clientId])

    useEffect(() => {
        form.resetFields()
    }, [form, client])

    const submit = async (values) => {
        const response = await dispatch(submitClient({ ...values }, clientId))

        if (response.success) {
            history.push(`${nodePath.join(url, "")}`)
            // Because this is changing the client subscriptions which affects the nav, re-fetch this client with the updated settings.
            // We can update the response from the controller to just include this if we don't want to make a separate call.
            dispatch(fetchClient(clientId))
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            <div className="card">
                <div className="card-body">
                    <div>
                        <div className="card-body-heading">
                            <h2>Manage Client Subscriptions</h2>
                        </div>

                        <Form form={form} initialValues={client} onFinish={submit} layout="vertical">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="User Management" name="user_management" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Utility Payment Management" name="utility_payment_management" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Client Batch Management" name="accounting_batch_management" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Ledger Account Management" name="ledger_account_management" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Removing Batch Detail report for now 3/31/2022 */}
                            {/* <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Enable Batch Detail Report" name="batch_detail_report" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            {/* some roles are able to view this form but not update it */}
                            <Role action="update" model="client">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Role>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
