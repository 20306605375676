import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { rolesUrl } from "../../lib/endpoints"

export const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        loading: false,
        roles: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
    },
})

export const { setLoading, setRoles } = rolesSlice.actions

export function fetchRoles() {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(rolesUrl)
            .then(({ data }) => {
                dispatch(setRoles(data.roles))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectRoles = (state) => state.roles.roles

export default rolesSlice.reducer
