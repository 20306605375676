import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, Popconfirm, notification, Tooltip, Dropdown, Menu, Button } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, FilePdfOutlined, FileTextOutlined, EditOutlined, DeleteOutlined, DownOutlined, FolderOutlined, LoadingOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons"
import moment from "moment"

import {
    fetchAccountingBatches,
    selectAccountingBatches,
    deleteAccountingBatch,
    selectTotal,
    selectPagination,
    selectLoading,
    generateAccountingBatchCsv,
    generateAccountingBatchUploadFile,
    generateAccountingBatchInvoicePdf,
    filterAccountingBatches,
} from "../accountingBatchesSlice"

import Role from "../../../userRoles/Role"
import {formatCurrency} from "../../../../lib/utilities";
import useConfirmationModal from "../../../alertsPanel/ShowConfirm"
import ColumnDateFilter from "../../../../components/utilityComponents/table/ColumnDateFilter"
import { tableFiltersApplied } from "../../../../functions/objectFunctions/tableFiltersApplied"
import { allObjectValuesEmpty } from "../../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnSearchFilter from "../../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"

export default function AccountingBatchTable({ scopedByClient }) {
    const { url } = useRouteMatch()
    const { clientId } = useParams()
    const dispatch = useDispatch()
    const accountingBatches = useSelector(selectAccountingBatches)
    // console.log(accountingBatches)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "batch_date",
        direction: "desc",
    })

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialAccountingBatches()
        }
        // eslint-disable-next-line
    }, [loaded, url, dispatch, pagination])

    const handleFetchInitialAccountingBatches = () => {
        dispatch(fetchAccountingBatches(clientId, pagination))
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchAccountingBatches(clientId, newPagination))
        } else {
            dispatch(fetchAccountingBatches(clientId, newPagination))
        }
    }

    const handleFilterAccountingBatches = (newPagination = null, newSort = null) => {
        return dispatch(
            filterAccountingBatches({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortAccountingBatchesChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterAccountingBatches(null, newSort)
    }
   

    const deleteRecord = async (id) => {
        const response = await dispatch(deleteAccountingBatch(id))
        if (response.success) {
            notification.open({
                message: "Success",
                description: "Deleted Client Batch",
                duration: 0.8,
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was an error deleting the Client Batch",
                duration: 0.8,
            })
        }
    }

    const accountingBatchesCsv = () => {
        notification.open({
            message: "Client batch export started",
            duration: 0.8,
        })

        dispatch(generateAccountingBatchCsv(clientId))
    }

    const generateUploadFile = async (accountingBatchId) => {
        notification.open({
            message: "Upload file generation started",
            duration: 0.8,
        })

        dispatch(generateAccountingBatchUploadFile(accountingBatchId))
    }

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteAccountingBatch, id);
    };

    const generateInvoicePdf = async (accountingBatchId) => {
        notification.open({
            message: "Invoice PDF generation started",
            duration: 0.8,
        })

        dispatch(generateAccountingBatchInvoicePdf(accountingBatchId))
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const renderColumnHeaderTitle = (title, altTitle = null, columnClass = '') => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortAccountingBatchesChange} sort={sort} columnClass={columnClass}  />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sort)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="#"
                    onClick={() => {
                        handleFetchInitialAccountingBatches()
                        setFilters({})
                        setSort({ column: "", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Client Batches</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}
                                <Role action="create" model="accounting_batch">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                {/* <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a> */}

                                <a href="javascript:;" onClick={() => accountingBatchesCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={accountingBatches} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column title="Batch Closed" key="closed" dataIndex="closed" render={(text, record) => <>{record.closed ? "Closed" : ""}</>} />
                            <Table.Column
                                title={renderColumnHeaderTitle('batch_group_name')}
                                key="batch_group_name"
                                dataIndex="batch_group_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialAccountingBatches}
                                        filterAction={filterAccountingBatches}
                                        filterColumn={"batch_group_name"}
                                        filterType={"batch_group_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("batch_group_name_cont")}
                                render={(text, record) => <>{record.batch_group?.name}</>}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("batch_code")}
                                key="batch_code"
                                dataIndex="batch_code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialAccountingBatches}
                                        filterAction={filterAccountingBatches}
                                        filterColumn={"batch_code"}
                                        filterType={"batch_code_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("batch_code_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("batch_date")}
                                key="batch_date"
                                dataIndex="batch_date"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialAccountingBatches}
                                        filterAction={filterAccountingBatches}
                                        filterColumn={"batch_date"}
                                        filterType={"batch_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("batch_date_lteq" || "batch_date_gteq")}
                                render={(text, record) => <>{moment(record.batch_date).format("MM/DD/YYYY")}</>}
                            />
                            {/* TODO: Make money formatting a library function */}
                            <Table.Column
                                title={renderColumnHeaderTitle("bills_total")}
                                key="bills_total"
                                dataIndex="bills_total"
                                align="right"
                                render={(text, record, index) => formatCurrency(Math.round(record.bills_total * 100) / 100)}
                            />
                            <Table.Column title={renderColumnHeaderTitle("line_item_count")} key="line_item_count" dataIndex="line_item_count" />
                            <Table.Column
                                title="Upload File"
                                key="download"
                                // LoadingOutlined icon when new file is generating
                                render={(text, record) => {
                                    if (!record.upload_file_url) {
                                        return null
                                    }

                                    if (record.generating_file) {
                                        return <LoadingOutlined />
                                    } else {
                                        return (
                                            <a href={record.upload_file_url} target="_blank" rel="noreferrer">
                                                <Tooltip title="View Report">
                                                    <FileTextOutlined />
                                                </Tooltip>
                                            </a>
                                        )
                                    }
                                }}
                            />
                            <Table.Column
                                title="Actions"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="accounting_batch">
                                                        <Menu.Item key="1" icon={<FolderOutlined />}>
                                                            <Link to={`${url}/${record.id}/bills`}>Bills</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="accounting_batch">
                                                        <Menu.Item key="2" icon={<EditOutlined />}>
                                                            <Link to={`${url}/${record.id}/edit`}>Edit Client Batch</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="read" model="accounting_batch">
                                                        <Menu.Item key="3" icon={<FileTextOutlined />}>
                                                            <a href="javascript:;" onClick={() => generateUploadFile(record.id)}>
                                                                Generate Upload File
                                                            </a>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="read" model="accounting_batch">
                                                        <Menu.Item key="4" icon={<FilePdfOutlined />}>
                                                            <a href="javascript:;" onClick={() => generateInvoicePdf(record.id)}>
                                                                Invoice (PDF)
                                                            </a>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="destroy" model="accounting_batch">
                                                        <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)} disabled={record.has_been_closed}>
                                                            Delete Client Batch
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
