import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

import client from "../../lib/client"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import {
    exportGlobalBatchAvidUrl,
    exportGlobalBatchOthersUrl,
    globalPaymentBatchUrl,
    globalPaymentBatchFilterUrl,
    globalPaymentBatchUpdateUrl,
    globalBatchUrl,
    globalPaymentBatchBillsUrl,
    paymentBatchUrl,
    createBatchesUrl,
    globalPaymentBatchInfo,
    globalPaymentBatchNewBillsUrl,
    todaysPaymentBatchesUrl,
    globalBatchVoucherCsvUrl,
    globalBatchCheckVendorPaymentsUrl,
    todaysPaymentBatchesForTypeUrl,
    unbatchBatchesUrl,
    updateParamsUrl,
    globalBatchCheckPaidBatchesUrl,
    globalBatchBankValueUpdateUrl,
} from "../../lib/endpoints"
import { formatDateRange } from "./consts/date"
import { setAmtRange, setFilteredAmountRange } from "./globalBatchFilterSlice"

const emptyState = {
    bills: [],
    clientsList: [],
    amountRange: [],
    initialAmountRange: [],
    vendorNames: [],
    vendorIdentifiers1: [],
    vendorIdentifiers2: [],
    paymentMethods: [],
    total: 0,
    sumTotal: 0,
    pagination: { current: 1, pageSize: 10 },
    allBatchesPagination: { current: 1, pageSize: 10 },
    todaysBatchesPagination: { current: 1, pageSize: 10 },
    loading: false,
    bankValue: { value: 0, locked: false, attemps: 3, string: '0' },
    sumOfFinalized: 0.00,

    batchCreationStatus: '',

    // TODO: Figure out where this should go
    checkedBills: [], // This is probably more of a filter prop
    billsAttributes: [],
    globalBatches: [],
    globalBatchesCount: 0,
    globalBatchBills: [],
    globalBatch: {},
    checkedBatches: []
}

export const globalBatchSlice = createSlice({
    name: "globalBatch",
    initialState: emptyState,
    reducers: {
        setBills: (state, action) => {
            state.bills = action.payload.bills
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setAllBatchesPagination: (state, action) => {
            state.pagination = action.payload
        },
        setTodaysBatchesPagination: (state, action) => {
            state.todaysBatchesPagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setSumTotal: (state, action) => {
            state.sumTotal = action.payload
        },
        setClientsList: (state, action) => {
            state.clientsList = action.payload.client_info
        },
        setAmountRange: (state, action) => {
            state.amountRange = action.payload.amount_range
        },
        setInitialAmountRange: (state, action) => {
            state.initialAmountRange = action.payload.amount_range
        },
        setVendorNames: (state, action) => {
            state.vendorNames = action.payload.vendor_names
        },
        setVendorIdentifiers1: (state, action) => {
            state.vendorIdentifiers1 = action.payload.vendor_payment_identifiers
        },
        setVendorIdentifiers2: (state, action) => {
            state.vendorIdentifiers2 = action.payload.vendor_po_boxes
        },
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload.payment_methods
        },

        setBatchCreationStatus: (state, action) => {
            state.batchCreationStatus = action.payload
        },

        setCheckedBills: (state, action) => {
            if (action.payload.id != undefined) {
                if(state.checkedBills.length == 0){
                    state.checkedBills[0] = action.payload.value
                }
                else{
                    for(let i = 0; i < state.checkedBills.length; i++){
                        const checkId = state.checkedBills[i].id
                        if(checkId == action.payload.id) state.checkedBills[i] = action.payload.value
                    }
                }
            } else {
                state.checkedBills = action.payload
            }
        },

        setCheckedBatches: (state, action) => {
            if (action.payload.id != undefined) {
                if(state.checkedBatches.length == 0){
                    state.checkedBatches[0] = action.payload.value
                }
                else{
                    for(let i = 0; i < state.checkedBatches.length; i++){
                        const checkId = state.checkedBatches[i].id
                        if(checkId == action.payload.id) state.checkedBatches[i] = action.payload.value
                    }
                }
            } else {
                state.checkedBatches = action.payload
            }
        },

        setGlobalBatches: (state, action) => {
            state.globalBatches = action.payload.batches
            state.globalBatchesCount = action.payload.total
        },
        setGlobalBatchBills: (state, action) => {
            state.globalBatchBills = action.payload.bills
        },
        setGlobalBatch: (state, action) => {
            state.globalBatch = action.payload
        },
        setBankValue: (state, action) => {
            let val = action.payload.bank_value
            if(val.string == undefined) val.string = val.value.toString()
            state.bankValue = val
        },
        setSumOfFinalized: (state, action) => {
            state.sumOfFinalized = action.payload.sum_of_finalized
        },
        clearState: (state, action) => {
            return emptyState
        },

        deleteBatch: (state, action) => {
            state.globalBatches = state.globalBatches.filter((batch) => batch.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const {
    setBills,
    setPagination,
    setAllBatchesPagination,
    setTodaysBatchesPagination,
    setLoading,
    setSumTotal,
    setClientsList,
    clearState,
    setAmountRange,
    setVendorNames,
    setVendorIdentifiers1,
    setVendorIdentifiers2,
    setPaymentMethods,
    setInitialAmountRange,
    setBankValue,
    setSumOfFinalized,
    
    setCheckedBills,
    setGlobalBatches,
    setGlobalBatchBills,
    setGlobalBatch,

    setBatchCreationStatus,
    setCheckedBatches

} = globalBatchSlice.actions

export function fetchGlobalBills(pagination, sort = null) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const formattedInitialDate = formatDateRange("YYYY-MM-DD")
        let url1 = `${globalPaymentBatchUrl}?page=${pagination.current}&per=${pagination.pageSize}&unpaid=true&q[due_date_lteq]=${formattedInitialDate[1]}`
        if (sort) {
            url1 += `&q[s]=${sort.column} ${sort.direction}`
        }

        if (pagination?.values) {
            const response = await client.get(`${globalPaymentBatchUrl}?values=[${pagination.values}]`)
            return response.data
        }

        client
            .get(url1)
            .then((response) => {
                dispatch(setPagination(pagination))
                dispatch(setBills(response.data))
                dispatch(setClientsList(response.data))
                dispatch(setVendorNames(response.data))
                dispatch(setSumOfFinalized(response.data))
                dispatch(setAmountRange(response.data))
                dispatch(setInitialAmountRange(response.data))
                dispatch(setFilteredAmountRange({ value: response.data, initialFetch: true }))
                dispatch(setPaymentMethods(response.data))
                dispatch(setBankValue(response.data))
                dispatch(setLoading(false))
            })
            .catch((e) => console.log("ERROR", e))
    }
}

export function filterBatch(query, initialLoad = false) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, globalPaymentBatchFilterUrl, '')
    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                if(initialLoad){
                    dispatch(setInitialAmountRange(response.data))
                    dispatch(setFilteredAmountRange(response.data.amount_range))
                }
                dispatch(setPagination(query.pagination))
                dispatch(setBills(response.data))
                dispatch(setAmountRange(response.data))
                dispatch(setClientsList(response.data))
                dispatch(setVendorNames(response.data))
                dispatch(setSumOfFinalized(response.data))
                dispatch(setPaymentMethods(response.data))
                // dispatch(setBankValue(response.data.bank_value))
                // dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}


export function generateCsv(billIds, type = 'Avid', title = '') {
    return async (dispatch, getState) => {
        const mainUrl = type == 'Avid' ? exportGlobalBatchAvidUrl : exportGlobalBatchOthersUrl
        const url = `${mainUrl}?bill_ids=${billIds}&connection_id=${getState().app.uid}${title != "" ? `&title=${title}` : ""}${type == "quick_books" ? "&type=quick_books" : ""}`
        
        const res = await client.get(url)
        return res
    }
}

export function submitPaymentBatch(billInfo, batchIds) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await client.patch(globalPaymentBatchUpdateUrl, { payment_batch: billInfo, payment_batch_ids: batchIds })

        dispatch(setLoading(false))
        // return response.data
    }
}  

export function updateBankValue(id, currVal, newVal, attempts, isHandlingLock = false, lock = false){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        let props = { value: newVal, locked: true }
        if(currVal != 0) props.attempts = attempts - 1 

        let response = ''
        if(!isHandlingLock){
            response = await client.patch(globalBatchBankValueUpdateUrl(id), props).catch((e) => {
                console.log("ERROR", e)
                setLoading(false)
            })
        }
        else{
            let lockProps = { locked: lock }
            if (lock) lockProps.attempts = attempts - 1
            response = await client.patch(globalBatchBankValueUpdateUrl(id), lockProps).catch((e) => {
                console.log("ERROR", e)
                setLoading(false)
            })
        }

        if(response.data.success){
            dispatch(setBankValue(response.data))
        }
        
        dispatch(setLoading(false))
        // return response.data
    }
}

/////////////////////////////////////////////////////////////////////////////////////////

export function fetchGlobalBatch(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${globalBatchUrl}/${id}`).then((response) => {
            dispatch(setGlobalBatch(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchGlobalBatches(pagination, todaysBills = false) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${globalBatchUrl}?values=[${pagination.values}]`)
            return response.data
        }

        client
            .get(`${todaysBills ? todaysPaymentBatchesUrl : globalBatchUrl}?page=${pagination.current}&per=${pagination.pageSize}`)
            .then((response) => {
                dispatch(todaysBills ? setTodaysBatchesPagination(pagination) : setPagination(pagination))
                dispatch(setGlobalBatches(response.data))
                dispatch(setSumOfFinalized(response.data))
                dispatch(setBankValue(response.data))
                dispatch(setLoading(false))
            })
            .catch((e) => {
                console.log("ERROR", e)
                // setLoading(false)
            })
    }
}

export function fetchGlobalBatchesForType(pagination, type) {
    return async (dispatch, getState) => {
        if(type == "CO") type = "Check - OVERNIGHT"
        dispatch(setLoading(true))
        client.get(`${todaysPaymentBatchesForTypeUrl}?type=${type}&page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setTodaysBatchesPagination(pagination))
            dispatch(setGlobalBatches(response.data))
            dispatch(setSumOfFinalized(response.data))
            dispatch(setBankValue(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchGlobalBatchBills(globalBatchId) {
    return async (dispatch, getState) => {
        client.get(`${globalPaymentBatchBillsUrl(globalBatchId)}`).then((response) => {
            dispatch(setGlobalBatchBills(response.data))
        })
        dispatch(setLoading(false))
    }
}


export function submitGlobalBatch(values, paymentBatchId, clientId, updateBills = false) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        if (paymentBatchId) {
            // patch - update
            const response = await client.patch(`${paymentBatchUrl(paymentBatchId, clientId)}`, { payment_batch: values })

            if (updateBills) {
                dispatch(setGlobalBatchBills(response.data))
                dispatch(setGlobalBatch(response.data.payment_batch))
            }
            
            dispatch(setLoading(false))
            return response.data
        } else {
            // const response = await client.post(globalBatchUrl, { global_batch: values })
            // dispatch(setLoading(false))
            // return response.data
        }
    }
}

export function getPaymentBatchInfo(globalBatchId){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await client.get(`${globalPaymentBatchInfo(globalBatchId)}`)
        return response.data
        dispatch(setLoading(false))
    }
}


export function createBatches(values, dateRange){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        dispatch(setBatchCreationStatus(''))
        const formattedDates = formatDateRange("YYYY-MM-DD", [new Date(dateRange[0]), new Date(dateRange[1])])
        const response = await client.post(`${createBatchesUrl}`, { bills: values, batch_date: formattedDates[0], bills_due_date: formattedDates[1] }).catch((e) => {
            // console.log(e.request.response)
            dispatch(setBatchCreationStatus(false))
        })
        if (response) dispatch(setBatchCreationStatus(true)) 
        dispatch(setLoading(false))
    }
}

export function unbatchBatches(values){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await client.post(`${unbatchBatchesUrl}`, { ids: values }).catch((e) => {
            // dispatch(setBatchCreationStatus(false))
            dispatch(setLoading(false))
            return { error: "error" }
        })
        dispatch(setGlobalBatches(response.data))
        dispatch(setLoading(false))
    }
}

export function updateTempPayType(values, newMethod, type = false){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        let vals = { ids: values, temp_method: newMethod }
        if (type) vals.type = type
        const response = await client.patch(`${updateParamsUrl}`, vals).catch((e) => {
            notification.error({
                message: 'An error has occured',
                duration: 2,
            })
            // dispatch(setBatchCreationStatus(false))
            dispatch(setLoading(false))
            return { error: "error" }
        })
        // console.log(response)
        dispatch(setGlobalBatches(response.data))
        dispatch(setLoading(false))
    }
}

export function updatePaidProcess(values, type = false, paid = false, finalized = false){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        let vals = { ids: values }
        if (type) vals.type = type
        if (paid) vals.paid = paid
        if (finalized) vals.finalized = finalized
        const response = await client.patch(`${updateParamsUrl}`, vals).catch((e) => {
            // dispatch(setBatchCreationStatus(false))
            dispatch(setLoading(false))
            return { error: "error" }
        })
        // console.log(response)
        dispatch(setGlobalBatches(response.data))
        dispatch(setLoading(false))
    }
}

export function fetchPaymentBatchNewBills(clientId, paymentBatchId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await client.get(`${globalPaymentBatchNewBillsUrl(paymentBatchId, clientId)}`)
        dispatch(setGlobalBatchBills(response.data))
        dispatch(setLoading(false))
        return { success: true, new_bills: response.data.new_bills, total: response.data.new_bills_total }
    }
}

export function fetchTodaysBatches(pagination){
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await client.get(`${todaysPaymentBatchesUrl}`)
        // console.log(response.data)
        dispatch(setLoading(false))
    }
}

export function deletePaymentBatch(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${paymentBatchUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(globalBatchSlice.actions.deleteBatch(id))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 2,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 2,
                })
                console.error(e)
            })
    }
}

export function checkForVendorPayments(type = false){
    return async (dispatch, getState) => {
        const response = await client.get(`${globalBatchCheckVendorPaymentsUrl}?${type ? `type=${type}` : ""}`)
        return response
    }
}

export function checkForPaidBatches(){
    return async (dispatch, getState) => {
        const response = await client.get(`${globalBatchCheckPaidBatchesUrl}`)
        return response
    }
}

export function generateGlobalVoucherCsv(globalBatchIds = false) {
    return async (dispatch, getState) => {
        client
            .get(`${globalBatchVoucherCsvUrl}?connection_id=${getState().app.uid}`)
            .then(({ data }) => {
                notification.success({
                    message: 'Export was created',
                    duration: 2,
                })
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 2,
                })
                console.error(e)
            })
    }
}

export const selectBills = (state) => state.globalBatch.bills
export const selectSumTotal = (state) => state.globalBatch.sumTotal
export const selectTotal = (state) => state.globalBatch.total
export const selectPagination = (state) => state.globalBatch.pagination
export const selectAllBatchesPagination = (state) => state.globalBatch.allBatchesPagination
export const selectTodaysBatchesPagination = (state) => state.globalBatch.todaysBatchesPagination
export const selectLoading = (state) => state.globalBatch.loading
export const selectClients = (state) => state.globalBatch.clientsList
export const selectAmountRange = (state) => state.globalBatch.amountRange
export const selectInitialAmountRange = (state) => state.globalBatch.initialAmountRange
export const selectVendorNames = (state) => state.globalBatch.vendorNames
export const selectIdentifiers1 = (state) => state.globalBatch.vendorIdentifiers1
export const selectIdentifiers2 = (state) => state.globalBatch.vendorIdentifiers2
export const selectPaymentMethods = (state) => state.globalBatch.paymentMethods
export const selectBankValue = (state) => state.globalBatch.bankValue
export const selectSumOfFinalized = (state) => state.globalBatch.sumOfFinalized

export const selectCheckedBills = (state) => state.globalBatch.checkedBills
export const selectCheckedBatches = (state) => state.globalBatch.checkedBatches
export const selectGlobalBatches = (state) => state.globalBatch.globalBatches
export const selectGlobalBatchesCount =  (state) => state.globalBatch.globalBatchesCount
export const selectGlobalBatch = (state) => state.globalBatch.globalBatch
export const selectGlobalBatchBills = (state) => state.globalBatch.globalBatchBills

export const selectBatchCreationStatus = (state) => state.globalBatch.batchCreationStatus

export default globalBatchSlice.reducer