import { FilterOutlined } from "@ant-design/icons"
import { Input, Layout } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { selectScopedByClient } from "../../stores/appSlice"
import { fetchClientBillableAccounts } from "../billableAccounts/billableAccountsSlice"
import { fetchClientDivisions } from "../divisions/divisionsSlice"
import { fetchClientFacilities } from "../facilities/facilitiesSlice"
import { fetchLedgerAccountsSidebar } from "../financials/ledgerAccounts/ledgerAccountsSlice"
import { fetchLedgerFieldsSidebar } from "../financials/ledgerFields/ledgerFieldsSlice"
import { fetchGroupAccountsSidebar } from "../groupAccounts/groupAccountsSlice"
import { fetchGroupSupplyAccountsSidebar } from "../groupSupplyAccounts/groupSupplyAccountsSlice"
import { fetchClientSupplyAccounts } from "../supplyAccounts/supplyAccountsSlice"

const { Sider } = Layout

export default function Sidebar({ sidebarType }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { divisionId, facilityId, billableAccountId, supplyAccountId, ledgerFieldId, ledgerAccountId, groupAccountId, groupSupplyAccountId } = useParams()

    const [records, setRecords] = useState([])
    const [clientId, setClientId] = useState(null)
    const [recordType, setRecordType] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")
    const scopedByClient = useSelector(selectScopedByClient)

    const renderTitle = () => {
        switch (sidebarType) {
            case "divisions":
                return "Divisions"
            case "facilities":
                return "Facilities"
            case "billableAccounts":
                return "Billable Accounts"
            case "supplyAccounts":
                return "Supply Accounts"
            case "ledgerFields":
                return "Ledger Fields"
            case "ledgerAccounts":
                return "Ledger Accounts"
            case "groupAccounts":
                return "Group Billing Accounts"
            case "groupSupplyAccounts":
                return "Group Billing Supply Accounts"
            default:
                return "None selected"
        }
    }

    const handleRecordClick = (recordId) => {
        // setActiveRecord(recordId)

        switch (sidebarType) {
            case "divisions":
                history.push(`/clients/${scopedByClient}/divisions/${recordId}/dashboard`)
                break
            case "facilities":
                history.push(`/clients/${scopedByClient}/facilities/${recordId}/dashboard`)
                break
            case "billableAccounts":
                history.push(`/clients/${scopedByClient}/billable_accounts/${recordId}/dashboard`)
                break
            case "supplyAccounts":
                history.push(`/clients/${scopedByClient}/supply_accounts/${recordId}/dashboard`)
                break
            case "ledgerFields":
                history.push(`/clients/${scopedByClient}/ledger_fields/${recordId}/edit`)
                break
            case "ledgerAccounts":
                history.push(`/clients/${scopedByClient}/ledger_accounts/${recordId}/edit`)
                break
            case "groupAccounts":
                history.push(`/clients/${scopedByClient}/group_accounts/${recordId}/subaccounts`)
                break
            case "groupSupplyAccounts":
                history.push(`/clients/${scopedByClient}/group_supply_accounts/${recordId}/subaccounts`)
                break
            default:
                history.push(`/clients/${scopedByClient}`)
                break
        }
    }

    // Setting the class for the actively selected record. We need to be able to account for when an item
    // is clicked from something other than the sidebar (table record, etc...) and still be able to highlight
    // that record in the sidebar. Using the url params combined with the sidebarType to make that determination
    const highlightActiveRecord = (recordId) => {
        switch (sidebarType) {
            case "divisions":
                return String(recordId) === divisionId ? "selected" : ""
            case "facilities":
                return String(recordId) === facilityId ? "selected" : ""
            case "billableAccounts":
                return String(recordId) === billableAccountId ? "selected" : ""
            case "supplyAccounts":
                return String(recordId) === supplyAccountId ? "selected" : ""
            case "ledgerFields":
                return String(recordId) === ledgerFieldId ? "selected" : ""
            case "ledgerAccounts":
                return String(recordId) === ledgerAccountId ? "selected" : ""
            case "groupAccounts":
                return String(recordId) === groupAccountId ? "selected" : ""
            case "groupSupplyAccounts":
                return String(recordId) === groupSupplyAccountId ? "selected" : ""
            default:
                return ""
        }
    }

    useEffect(() => {
        setSearchTerm("")

        async function fetchData() {
            switch (sidebarType) {
                case "divisions":
                    setRecords(await dispatch(fetchClientDivisions(scopedByClient)))
                    break
                case "facilities":
                    setRecords(await dispatch(fetchClientFacilities(scopedByClient)))
                    break
                case "billableAccounts":
                    setRecords(await dispatch(fetchClientBillableAccounts(scopedByClient)))
                    break
                case "supplyAccounts":
                    setRecords(await dispatch(fetchClientSupplyAccounts(scopedByClient)))
                    break
                case "ledgerFields":
                    setRecords(await dispatch(fetchLedgerFieldsSidebar(scopedByClient)))
                    break
                case "ledgerAccounts":
                    setRecords(await dispatch(fetchLedgerAccountsSidebar(scopedByClient)))
                    break
                case "groupAccounts":
                    setRecords(await dispatch(fetchGroupAccountsSidebar(scopedByClient)))
                    break
                case "groupSupplyAccounts":
                    setRecords(await dispatch(fetchGroupSupplyAccountsSidebar(scopedByClient)))
                    break
                default:
                    break
            }
        }

        if (scopedByClient) {
            // Context change - either a new client or a new tab was selected
            if (scopedByClient !== clientId || recordType !== sidebarType) {
                setClientId(scopedByClient)
                setRecordType(sidebarType)
                fetchData()
            }
        } else {
            // dispatch(setDivision({}))
            setClientId(null)
            setRecordType(null)
            setRecords([])
        }
    }, [dispatch, clientId, recordType, scopedByClient, sidebarType])

    const renderRecords = () => {
        if (records && records.length > 0) {
            if (sidebarType === "billableAccounts" || sidebarType === "supplyAccounts") {
                return records.map(
                    (record) =>
                        (searchTerm.length > 0 ? record.account_number.toLowerCase().includes(searchTerm.toLowerCase()) : true) && (
                            <div key={record.id} className={`console-side-nav-result ${highlightActiveRecord(record.id)}`} onClick={() => handleRecordClick(record.id)}>
                                {record.account_number}
                            </div>
                        )
                )
            } else {
                return records.map(
                    (record) =>
                        (searchTerm.length > 0 ? record.name.toLowerCase().includes(searchTerm.toLowerCase()) : true) && (
                            <div key={record.id} className={`console-side-nav-result ${highlightActiveRecord(record.id)}`} onClick={() => handleRecordClick(record.id)}>
                                {record.name}
                                {/* <Link to={() => createRecordLink(record.id)}>{record.name}</Link> */}
                                {/* <Link to={`/clients/${scopedByClient}/${sidebarType}/${record.id}`}>{record.name}</Link> */}
                            </div>
                        )
                )
            }
        } else {
            return ""
        }
    }

    return (
        <Sider id="console-side-nav">
            <div id="console-side-nav-filter">
                <FilterOutlined />
                <Input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} allowClear />
                {/* <Input type="text" placeholder={`Filter ${sidebarRecordType}`} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
            </div>

            <div id="console-side-nav-results">
                <div className="console-side-nav-result">
                    <div className="console-side-nav-result-data">
                        <div className="console-side-nav-result-data-label">{renderTitle()}</div>
                        <div className="console-side-nav-result-data-meta">Filter Result:</div>
                    </div>
                    <div className="console-side-nav-result-data-badge">
                        <span>{records?.length}</span>
                    </div>
                </div>

                {renderRecords()}
            </div>
        </Sider>
    )
}
