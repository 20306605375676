import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import GroupSupplyAccountTable from "./components/GroupSupplyAccountTable"
import GroupSupplyAccountForm from "./components/GroupSupplyAccountForm"
import SubaccountTable from "./components/SubaccountTable"
import BillTable from "../bills/components/BillTable"

export default function GroupSupplyAccountPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "bills":
                return renderBills()
            case "subaccounts":
                return renderSubaccounts()
            default:
                return renderIndex()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <h1>Single Group Account Dashboard</h1>
        }

        return <h1>Group Accounts dashboard (scoped by client)</h1>
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return <GroupSupplyAccountTable scopedByClient={scopedByClient} />
        }

        return <GroupSupplyAccountTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        return (
            <>
                <h1>Manage Group Supply Account</h1>
                <GroupSupplyAccountForm actionName="new" />
            </>
        )
    }

    const renderBills = () => {
        return <BillTable scopedByClient={scopedByClient} />
    }

    const renderSubaccounts = () => {
        return <SubaccountTable scopedByClient={scopedByClient} />
    }

    return <>{renderView()}</>
}
