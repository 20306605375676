import moment from "moment"
import { InputNumber } from "antd"

export const tableColInfo = [
    {
        header: "client_name",
        key: "client_name",
        dataIndex: "client_name",
        filterColumn: "client_name",
        filterType: "client_name",
        filterIcon: "client_name",
        columnFilterType: "search",
        filterDropdown: false,
    },
    {
        header: "company_name",
        key: "vendor_name",
        dataIndex: "vendor_name",
        filterColumn: "vendor_name",
        filterType: "vendor_name",
        filterIcon: "vendor_name",
        columnFilterType: "search",
        filterDropdown: false,
    },
    {
        header: "vendor_short_code",
        key: "vendor_short_code",
        dataIndex: "vendor_short_code",
        filterColumn: "vendor_short_code",
        filterType: "vendor_short_code_cont",
        filterIcon: "vendor_short_code_cont",
        columnFilterType: "search",
    },
    {
        header: "payment_date",
        key: "payment_date",
        dataIndex: "payment_date",
        filterColumn: "payment_date",
        filterType: "payment_date",
        filterIcon: "payment_date_lteq" || "payment_date_gteq",
        columnFilterType: "date",
        render: (text, record, index) => moment(text).format("L"),
        filterDropdown: false,
        title: "Payment Date",
    },
    {
        header: "due_date",
        key: "due_date",
        dataIndex: "due_date",
        filterColumn: "due_date",
        filterType: "due_date",
        filterIcon: "due_date_lteq" || "due_date_gteq",
        columnFilterType: "date",
        render: (text, record, index) => moment(text).format("L"),
    },
    {
        header: "Amount Due",
        key: "current_charges",
        dataIndex: "current_charges",
        filterColumn: "current_charges",
        filterType: "current_charges",
        filterIcon: "current_charges",
        columnFilterType: "search",
        filterDropdown: false,
    },
    {
        header: "amount_paid",
        key: "amount_paid",
        dataIndex: "amount_paid",
        filterColumn: "amount_paid",
        filterType: "amount_paid",
        filterIcon: "amount_paid",
        columnFilterType: "search",
        filterDropdown: false,
        title: "Amount Paid",
    },
    {
        header: "pay_type",
        key: "vendor_payment_methods",
        dataIndex: "vendor_payment_methods",
        filterColumn: "vendor_payment_methods",
        filterType: "vendor_payment_methods",
        filterIcon: "vendor_payment_methods",
        columnFilterType: "search",
        filterDropdown: false,
    },
    { header: "PO Box", key: "vendor_po_box", dataIndex: "vendor_po_box", filterColumn: "vendor_po_box", filterType: "vendor_po_box", filterIcon: "vendor_po_box", columnFilterType: "search" },
    {
        header: "Banking Identifier",
        key: "vendor_payment_identifier",
        dataIndex: "vendor_payment_identifier",
        filterColumn: "vendor_payment_identifier",
        filterType: "vendor_payment_identifier_cont",
        filterIcon: "vendor_payment_identifier_cont",
        columnFilterType: "search",
    },
    {
        header: "account_number",
        key: "account_number",
        dataIndex: "account_number",
        filterColumn: "account_number",
        filterType: "account_number_cont",
        filterIcon: "account_number_cont",
        columnFilterType: "search",
    },
    // { header: "client_id", key: "client_id", dataIndex: "client_id", filterColumn: "client_id", filterType: "client_id", filterIcon: "client_id", columnFilterType: "search" },
    // { header: "vendor_company_id", key: "vendor_id", dataIndex: "vendor_id", filterColumn: "vendor_id", filterType: "vendor_id", filterIcon: "vendor_id", columnFilterType: "search" },
    // { header: "vendor_bill_id", key: "bill_id", dataIndex: "bill_id", filterColumn: "bill_id", filterType: "bill_id", filterIcon: "bill_id", columnFilterType: "search" },
    {
        header: "days_due",
        key: "days_due",
        dataIndex: "days_due",
        filterColumn: "days_due",
        filterType: "days_due",
        filterIcon: "days_due",
        columnFilterType: "search",
        filterDropdown: false,
        title: "Days due",
    },
]

export const tableColInfo2 = [
    {
        header: "due_date",
        key: "due_date",
        dataIndex: "due_date",
        filterColumn: "due_date",
        filterType: "due_date",
        filterIcon: "due_date_lteq" || "due_date_gteq",
        columnFilterType: "date",
        render: (text, record, index) => moment(text).format("L"),
    },
    {
        header: "pay_type",
        key: "vendor_payment_methods",
        dataIndex: "vendor_payment_methods",
        filterColumn: "vendor_payment_methods",
        filterType: "vendor_payment_methods",
        filterIcon: "vendor_payment_methods",
        columnFilterType: "search",
    },
    {
        header: "payment_date",
        key: "payment_date",
        dataIndex: "payment_date",
        filterColumn: "payment_date",
        filterType: "payment_date",
        filterIcon: "payment_date_lteq" || "payment_date_gteq",
        columnFilterType: "date",
        render: (text, record, index) => moment(text).format("L"),
    },
    {
        header: "Amount Due",
        key: "current_charges",
        dataIndex: "current_charges",
        filterColumn: "current_charges",
        filterType: "current_charges",
        filterIcon: "current_charges",
        columnFilterType: "search",
    },
    {
        header: "amount_paid",
        key: "amount_paid",
        dataIndex: "amount_paid",
        filterColumn: "amount_paid",
        filterType: "amount_paid",
        filterIcon: "amount_paid",
        columnFilterType: "search",
        render: (text, record, index) => {
            return <InputNumber defaultValue={record.amount_paid} precision={2} controls={false} disabled={record.paid} style={{ float: "right" }} />
        },
    },
    {
        header: "account_number",
        key: "account_number",
        dataIndex: "account_number",
        filterColumn: "account_number",
        filterType: "account_number",
        filterIcon: "account_number",
        columnFilterType: "search",
    },
    { header: "PO Box", key: "vendor_po_box", dataIndex: "vendor_po_box", filterColumn: "vendor_po_box", filterType: "vendor_po_box", filterIcon: "vendor_po_box", columnFilterType: "search" },
    {
        header: "Banking Identifier",
        key: "vendor_payment_identifier",
        dataIndex: "vendor_payment_identifier",
        filterColumn: "vendor_payment_identifier",
        filterType: "vendor_payment_identifier",
        filterIcon: "vendor_payment_identifier",
        columnFilterType: "search",
    },
    {
        header: "vendor_short_code",
        key: "vendor_short_code",
        dataIndex: "vendor_short_code",
        filterColumn: "vendor_short_code",
        filterType: "vendor_short_code_cont",
        filterIcon: "vendor_short_code_cont",
        columnFilterType: "search",
    },
    { header: "client_name", key: "client_name", dataIndex: "client_name", filterColumn: "client_name", filterType: "client_name", filterIcon: "client_name", columnFilterType: "search" },
    { header: "company_name", key: "vendor_name", dataIndex: "vendor_name", filterColumn: "vendor_name", filterType: "vendor_name", filterIcon: "vendor_name", columnFilterType: "search" },
    // { header: "client_id", key: "client_id", dataIndex: "client_id", filterColumn: "client_id", filterType: "client_id", filterIcon: "client_id", columnFilterType: "search" },
    // { header: "vendor_company_id", key: "vendor_id", dataIndex: "vendor_id", filterColumn: "vendor_id", filterType: "vendor_id", filterIcon: "vendor_id", columnFilterType: "search" },
    { header: "vendor_bill_id", key: "bill_id", dataIndex: "bill_id", filterColumn: "bill_id", filterType: "bill_id", filterIcon: "bill_id", columnFilterType: "search" },
    { header: "days_due", key: "days_due", dataIndex: "days_due", filterColumn: "days_due", filterType: "days_due", filterIcon: "days_due", columnFilterType: "search" },
]