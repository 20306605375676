import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { clientSupplyAccountsMissingBillsUrl, dynamicUrl, clientSupplyAccountsMissingBillsCsvUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../../functions/objectFunctions/createRansackParams"

export const supplyAccountsMissingBillsSlice = createSlice({
    name: "supplyAccountsMissingBills",
    initialState: {
        supplyAccounts: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setSupplyAccounts: (state, action) => {
            state.supplyAccounts = action.payload.supply_accounts
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
})

export const { setSupplyAccounts, setPagination, setLoading } = supplyAccountsMissingBillsSlice.actions

export function fetchSupplyAccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        // This route may be nested so we have to use a dynamic route
        // /divisions VS /clients/client_id/divisions
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setSupplyAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function filterSupplyAccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, clientSupplyAccountsMissingBillsUrl(query.clientId), "")

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setSupplyAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${clientSupplyAccountsMissingBillsCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectSupplyAccounts = (state) => state.supplyAccountsMissingBills.supplyAccounts
export const selectTotal = (state) => state.supplyAccountsMissingBills.total
export const selectPagination = (state) => state.supplyAccountsMissingBills.pagination
export const selectLoading = (state) => state.supplyAccountsMissingBills.loading

export default supplyAccountsMissingBillsSlice.reducer
