import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, DatePicker, Input, notification, Row, Select, Skeleton, Slider } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import {
    filterBatch,
    selectAmountRange,
    selectBankValue,
    selectClients,
    selectInitialAmountRange,
    selectPagination,
    selectPaymentMethods,
    selectSumOfFinalized,
    selectSumTotal,
    selectVendorNames,
    setBankValue,
    updateBankValue,
} from "../globalBatchSlice"
import moment from 'moment';
import MultiSelectRowItem from './MultiSelectRowItem';
import { useParams, useRouteMatch  } from "react-router-dom"
import {
    clearState,
    selectUnpaid,
    setFilteredAmountRange,
    setFilteredClients,
    setFilteredDateRange,
    setFilteredPaymentMethods,
    setFilteredVendors,
    selectFilteredAmountRange,
    selectFilteredClients,
    selectFilteredDateRange,
    selectFilteredVendors,
    selectFilteredPaymentMethods,
} from "../globalBatchFilterSlice"
import { disabledDate } from '../consts/date';
import {  LockFilled, LockOutlined, UnlockFilled, UnlockOutlined } from "@ant-design/icons"
import useConfirmBankValueChange from '../../alertsPanel/ShowConfirmBankValueChange';
// const { Option } = Select

export default function GlobalBatchFilters({ filters, setFilters, sort, loading }) {
    /////*** redux start ***/////
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const { globalBatchId } = useParams()

    const sumTotal = useSelector(selectSumTotal)
    const pagination = useSelector(selectPagination)
    const clients = useSelector(selectClients)
    const vendors = useSelector(selectVendorNames)
    // const amountRange = useSelector(selectAmountRange)
    const paymentMethods = useSelector(selectPaymentMethods)
    const initialAmountRange = useSelector(selectInitialAmountRange)

    const unpaid = useSelector(selectUnpaid) 
    const filteredClients = useSelector(selectFilteredClients)
    const filteredVendors = useSelector(selectFilteredVendors)
    const filteredDateRange = useSelector(selectFilteredDateRange)
    const filteredPaymentMethods = useSelector(selectFilteredPaymentMethods)
    const filteredAmountRange = useSelector(selectFilteredAmountRange)
    const bankValue = useSelector(selectBankValue)
    const sumOfFinalized = useSelector(selectSumOfFinalized)

    const inputRef = useRef(null);

    const [amountRange, setAmountRange] = useState(['', ''])
    const [editingAmountRange, setEditingAmountRange] = useState(false)
    const [loaded, setLoaded] = useState(false)
    /////*** redux end ***/////
    useEffect(() => {
        if(filteredAmountRange.length > 0 && !loaded){
            setAmountRange([filteredAmountRange[0].toString(), filteredAmountRange[1].toString()])
            setLoaded(true)
        }
    }, [filteredAmountRange])
    
    useEffect(() => { // Makes it so filters don't linger
        dispatch(clearState())
    },[url])

    const startDateTimestamp = new Date().getTime()
    let endDateTimestamp = new Date()
    endDateTimestamp.setDate(endDateTimestamp.getDate() + 30)
    endDateTimestamp = endDateTimestamp.getTime()

    const [startDate, setStartDate] = useState(moment.unix(filteredDateRange[0] / 1000))
    const [endDate, setEndDate] = useState(moment.unix(filteredDateRange[1] / 1000))
    // const [bankValue, setBankValue] = useState({ num: 126000, string: "126000" })

    // const disabledDate = (current) => {
    //     return current &&  current.isAfter(endDate, "day")
    // }

    function findNamesByIds(arr, ids) {
        const res = []
        let id = ""
        let val = ""
        if (arr.length > 0) {
            Object.entries(arr[0]).forEach(([key, value]) => {
                const split = key.split("_")
                if (split[1] === "id") id = split.join("_")
                else val = split.join("_")
            })
            for (let i = 0; i < arr.length; i++) {
                if (ids.includes(arr[i][id])) res.push(arr[i][val])
            }
        }
        return res
    }

    function RowItem(props) {
        const { type } = props
        if (type === "multi-select") {
            return <MultiSelectRowItem props={props} filters={filters} setFilters={setFilters} sort={sort} pagination={pagination} unpaid={unpaid} />
        }
    }

    const showConfirm = useConfirmBankValueChange();
    const handleBankValueChange = (value, isHandlingLock = false, lock = false, from = '') => {
        if(value == 0){
            notification.error({
                description: 'Bank value cannot be set to 0.',
                message: "Unauthorized value",
                duration: 1.5
            })
            return
        }
        showConfirm(updateBankValue, handleFocus, {
            id: bankValue.id,
            currVal: bankValue.value,
            newVal: value,
            attempts: bankValue.attempts,
            isHandlingLock: isHandlingLock,
            lock: lock,
            from: from
        })
    }

    const handleFocus = () => {
        // setTimeout(() => {
        //   }, 500);
        // inputRef.current?.focus()
    }

    // const changeBankValue = (value, isHandlingLock = false, lock = false, from = '') => {
    //     dispatch(updateBankValue(bankValue.id, bankValue.value, value, bankValue.attempts, isHandlingLock, lock))
    // }

    
    return (
        <div className="card" style={{ marginBottom: "40px", color: "white" }}>
            <div className="card-body">
                <Skeleton loading={loading}>
                    <div>
                        {/* <div style={{ display: "flex", flexDirection: "row", gap: 5, marginBottom: "21px" }}>
                            <div>Unpaid</div>
                            <Switch
                                defaultChecked={unpaid}
                                onChange={(e) => {
                                    dispatch(setUnpaid(e))
                                    dispatch(filterBatch({ filters: filters, pagination: pagination, sort: sort, unpaid: e }))
                                }}
                            />
                        </div> */}
                        <Row gutter={20}>
                            <RowItem
                                type="multi-select"
                                label={"Client Name:"}
                                value={findNamesByIds(clients, filteredClients)}
                                arr={clients}
                                filterArr={filteredClients}
                                filterVal={"client_ids"}
                                setFilterArr={setFilteredClients}
                                findId
                                disabled={globalBatchId ? true : false}
                            />
                            <RowItem
                                type="multi-select"
                                label={"Payment Type"}
                                value={filteredPaymentMethods}
                                arr={paymentMethods}
                                filterArr={filteredPaymentMethods}
                                filterVal={"payment_method_names"}
                                setFilterArr={setFilteredPaymentMethods}
                                hasId={false}
                                disabled={globalBatchId ? true : false}
                            />
                            <RowItem
                                type="multi-select"
                                label={"Vendor"}
                                value={filteredVendors}
                                arr={vendors}
                                filterArr={filteredVendors}
                                filterVal={"vendor_ids"}
                                setFilterArr={setFilteredVendors}
                            />
                            <Col span={6}>
                                <div className="global-batch-filter-label-text">Bank Value {bankValue.locked ? <LockFilled /> : <UnlockFilled />}:</div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Input
                                        ref={inputRef}
                                        disabled={bankValue.locked}
                                        value={bankValue.string}
                                        onChange={(e) => {
                                            const twoDecimalNumRegex = /^\d+(\.\d{0,2})?$/
                                            const val = e.target.value
                                            if (twoDecimalNumRegex.test(val) || val == "") {
                                                dispatch(setBankValue({ bank_value: { ...bankValue, string: val } }))
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (bankValue.string) {
                                                handleBankValueChange(parseFloat(bankValue.string), false, false, "input")
                                            } else {
                                                handleBankValueChange(0, false, false, "input")
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            const val = e.target.value
                                            if (e.code === "Enter") {
                                                handleBankValueChange(parseFloat(val), false, false, "input")
                                            }
                                        }}
                                    />
                                    <Button
                                        type="primary"
                                        icon={!bankValue.locked ? <LockOutlined /> : <UnlockOutlined />}
                                        onClick={() => {
                                            handleBankValueChange(bankValue.value, bankValue.locked ? true : false, !bankValue.locked, "button")
                                        }}
                                        disabled={bankValue.attempts == 0}>
                                        {bankValue.locked ? "Unlock" : "Lock"}
                                    </Button>
                                </div>
                            </Col>

                            {/* TODO: Put this in the style sheet instead of inline */}
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "2%" }}>
                                {/* Date */}
                                <Col span={6}>
                                    <div className="global-batch-filter-label-text">Bill Due On Or Before:</div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                        {/* <DatePicker
                                            style={{ width: "100%" }}
                                            disabled={globalBatchId ? true : false}
                                            suffixIcon={""}
                                            format={["MM/DD/YYYY", "MM-DD-YYYY", "M/D/YY"]}
                                            value={moment.unix(filteredDateRange[0] / 1000)}
                                            onChange={(val) => {
                                                if (!val) val = moment.unix(startDateTimestamp / 1000)
                                                setStartDate(val)
                                                // setSliderStart(val.valueOf())
                                                dispatch(setFilteredDateRange({ value: val.valueOf(), index: 0 }))
                                                dispatch(
                                                    filterBatch({
                                                        filters: {
                                                            ...filters,
                                                            due_date_gteq: val.calendar(),
                                                            due_date_lteq: endDate.calendar(),
                                                        },
                                                        pagination: pagination,
                                                        sort: sort,
                                                        unpaid: unpaid,
                                                    })
                                                )
                                            }}
                                            disabledDate={disabledDate}
                                        />{" "}
                                        - */}
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            disabled={globalBatchId ? true : false}
                                            suffixIcon={""}
                                            format={["MM/DD/YYYY", "MM-DD-YYYY", "M/D/YY"]}
                                            value={endDate}
                                            onChange={(val) => {
                                                if (!val) val = moment.unix(endDateTimestamp / 1000)
                                                setEndDate(val)
                                                // setSliderEnd(val.valueOf())
                                                dispatch(setFilteredDateRange({ value: val.valueOf(), index: 1 }))
                                                dispatch(
                                                    filterBatch({
                                                        filters: {
                                                            ...filters,
                                                            // due_date_gteq: startDate.calendar(),
                                                            due_date_lteq: val.calendar(),
                                                        },
                                                        pagination: pagination,
                                                        sort: sort,
                                                        unpaid: unpaid,
                                                    })
                                                )
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </div>
                                    {/* <Slider
                                        disabled={globalBatchId ? true : false}
                                        range
                                        tooltip={{ open: false }}
                                        min={startDateTimestamp}
                                        max={endDateTimestamp}
                                        defaultValue={[startDate, endDate]}
                                        step={86400000} // ms for one day
                                        onChange={(val) => {
                                            dispatch(setFilteredDateRange(val))
                                            setStartDate(moment.unix(val[0] / 1000))
                                            setEndDate(moment.unix(val[1] / 1000))
                                            // setSliderStart(val[0])
                                            // setSliderEnd(val[1])
                                        }}
                                        value={[startDate, endDate]}
                                        onAfterChange={(val) => {
                                            const startDueDate = moment.unix(val[0] / 1000)
                                            const endDueDate = moment.unix(val[1] / 1000)
                                            if (startDate._i != startDueDate._i || endDate._i != endDueDate._i) {
                                                // Makes sure no unnecessary calls are made
                                                dispatch(setFilteredDateRange(val))
                                                dispatch(
                                                    filterBatch({
                                                        filters: {
                                                            ...filters,
                                                            due_date_gteq: moment.unix(val[0] / 1000).calendar(),
                                                            due_date_lteq: moment.unix(val[1] / 1000).calendar(),
                                                        },
                                                        pagination: pagination,
                                                        sort: sort,
                                                        unpaid: unpaid,
                                                    })
                                                )
                                            }
                                        }}
                                    /> */}
                                </Col>
                                {/* Amount */}
                                {/* TODO: Make the inputs better.
                                            - Should allow user to input any number
                                            - If new input is > Max or < Min then default to that (max or min)
                                            - If new input is blank then reset to default (max or min)
                                */}
                                <Col span={12}>
                                    <div className="global-batch-filter-label-text">Amount Due:</div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                                        <Input
                                            // defaultValue={filteredAmountRange[0]?.toFixed(2)}
                                            value={editingAmountRange ? amountRange[0] : amountRange[0] == '' ? '' : parseFloat(amountRange[0]).toFixed(2)}
                                            onChange={(e) => {
                                                const twoDecimalNumRegex = /^-?\d+(\.\d{0,2})?$/
                                                let val = e.target.value
                                                if(twoDecimalNumRegex.test(val) || val == ''){
                                                    // if(val == '') val = 0
                                                    const newRange = [val, amountRange[1]]
                                                    setAmountRange(newRange)
                                                }
                                                // if (twoDecimalNumRegex.test(val) && parseFloat(val) >= initialAmountRange[0] && parseInt(val) < initialAmountRange[1]) {
                                                //     dispatch(setFilteredAmountRange({ value: parseFloat(val), index: 0 }))
                                                // } else if (parseInt(val) >= initialAmountRange[1]) {
                                                //     dispatch(setFilteredAmountRange({ value: initialAmountRange[1], index: 0 }))
                                                // } else if (val == "") {
                                                //     dispatch(setFilteredAmountRange({ value: 0, index: 0 }))
                                                // }
                                            }}
                                            onFocus={()=>setEditingAmountRange(true)}
                                            onBlur={(e) => {
                                                let val = parseFloat(e.target.value)

                                                if (val > initialAmountRange[1]) val = initialAmountRange[1]
                                                else if(val < initialAmountRange[0]) val = initialAmountRange[0]
                                                else if(!val) val = filteredAmountRange[0]

                                                setAmountRange([val.toString(), amountRange[1]])
                                                setFilters({
                                                    ...filters,
                                                    current_charges_gteq: val,
                                                    current_charges_lteq: filteredAmountRange[1],
                                                })
                                                
                                                dispatch(setFilteredAmountRange({ value: val, index: 0 }))
                                                dispatch(
                                                    filterBatch({
                                                        filters: {
                                                            ...filters,
                                                            current_charges_gteq: val,
                                                            current_charges_lteq: filteredAmountRange[1],
                                                        },
                                                        pagination: pagination,
                                                        sort: sort,
                                                        unpaid: unpaid,
                                                    })
                                                )
                                                setEditingAmountRange(false)
                                            }}
                                        />{" "}
                                        -{" "}
                                        <Input
                                            onFocus={()=>setEditingAmountRange(true)}
                                            value={editingAmountRange ? amountRange[1] : amountRange[1] == '' ? '' : parseFloat(amountRange[1]).toFixed(2)}
                                            onChange={(e) => {
                                                const twoDecimalNumRegex = /^-?\d+(\.\d{0,2})?$/
                                                const val = e.target.value
                                                if(twoDecimalNumRegex.test(val) || val == ''){
                                                    // if(val == '') val = 0
                                                    const newRange = [amountRange[0], val]
                                                    setAmountRange(newRange)
                                                }
                                                // if (twoDecimalNumRegex.test(val) && parseFloat(val) <= initialAmountRange[1] && parseInt(val) > initialAmountRange[0]) {
                                                //     dispatch(setFilteredAmountRange({ value: parseFloat(val), index: 1 }))
                                                // } else if (val == "" || parseInt(val) <= initialAmountRange[0]) {
                                                //     dispatch(setFilteredAmountRange({ value: initialAmountRange[0], index: 1 }))
                                                // }
                                            }}
                                            onBlur={(e) => {
                                                let val = parseFloat(e.target.value)

                                                if (val > initialAmountRange[1]) val = initialAmountRange[1]
                                                else if(val < initialAmountRange[0]) val = initialAmountRange[0]
                                                else if(!val) val = filteredAmountRange[1]

                                                setAmountRange([amountRange[0], val.toString()])
                                                setFilters({
                                                    ...filters,
                                                    current_charges_gteq: filteredAmountRange[0],
                                                    current_charges_lteq: parseFloat(val),
                                                })
                                                
                                                dispatch(setFilteredAmountRange({ value: val, index: 1 }))
                                                dispatch(
                                                    filterBatch({
                                                        filters: {
                                                            ...filters,
                                                            current_charges_gteq: filteredAmountRange[0],
                                                            current_charges_lteq: parseFloat(val),
                                                        },
                                                        pagination: pagination,
                                                        sort: sort,
                                                        unpaid: unpaid,
                                                    })
                                                )
                                                setEditingAmountRange(false)
                                            }}
                                        />
                                    </div>
                                    <Slider
                                        range
                                        tooltip={{ open: false }}
                                        min={initialAmountRange[0] * 100}
                                        max={initialAmountRange[1] * 100}
                                        defaultValue={[initialAmountRange[0] * 100, initialAmountRange[1] * 100]}
                                        value={[filteredAmountRange[0] * 100, filteredAmountRange[1] * 100]}
                                        step={1}
                                        onChange={(val) => {
                                            // setAmountStart(val[0])
                                            // setAmountEnd(val[1])
                                            const vals = [val[0] / 100, val[1] / 100]
                                            setAmountRange([vals[0].toString(), vals[1].toString()])
                                            dispatch(setFilteredAmountRange(vals))
                                        }}
                                        onAfterChange={(val) => {
                                            setFilters({ ...filters, current_charges_gteq: val[0] / 100, current_charges_lteq: val[1] / 100 })
                                            dispatch(setFilteredAmountRange([val[0] / 100, val[1] / 100]))
                                            dispatch(
                                                filterBatch({
                                                    filters: {
                                                        ...filters,
                                                        current_charges_gteq: val[0] / 100,
                                                        current_charges_lteq: val[1] / 100,
                                                    },
                                                    pagination: pagination,
                                                    sort: sort,
                                                    unpaid: unpaid,
                                                })
                                            )
                                        }}
                                    />
                                </Col>
                                <Col span={3}>
                                    <div className="global-batch-filter-label-text">Bank Value After Amount Due:</div>
                                    <div className="global-batch-filter-label-text">${(bankValue.value - sumTotal - sumOfFinalized).toLocaleString()}</div>
                                </Col>
                                <Col span={3}>
                                    <div className="global-batch-filter-label-text">Total of finalized batches:</div>
                                    <div className="global-batch-filter-label-text">${sumOfFinalized.toLocaleString()}</div>
                                </Col>
                            </div>
                        </Row>
                    </div>
                </Skeleton>
            </div>
        </div>
    )
}
