import React from "react"
import { Select, Pagination, Divider } from "antd"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { isEmpty } from "lodash"
import { fetchDivisionFacilities } from '../../facilities/facilitiesSlice'

export default function PaginatedSelect({ loading, placeholder, value, onChange, onSearch, paginationSelector, paginationTotalSelector, loadPaginatedData, searchSelector, renderOptionValue, mode, name, perPage }) {
    const dispatch = useDispatch()

    const [searchMode, setSearchMode] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize] = useState(10)
    const [initialized, setInitialized] = useState(false)
    const [valuesLoaded, setValuesLoaded] = useState(false)
    const [savedValues, setSavedValues] = useState([])
    const [formUpdated, setFormUpdated] = useState(false)
    const { clientId } = useParams()

    /**
     * This is the array similar to, for example, the clients table that is paginated
     */
    const paginatedResults = useSelector(paginationSelector)
    /**
     * The total number of paginated results since this is stored in separate slices
     */
    const paginatedTotal = useSelector(paginationTotalSelector)
    /**
     * The resource's slice search results array
     */
    const searchResults = useSelector(searchSelector)

    /**
     * If we are in search mode, use the search results data, otherwise use our paginated data
     */
    const options = searchMode ? searchResults : paginatedResults

    /**
     * When the component first mounts we want to load the initial paginated data since we start off with that.
     */
    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchPaginated()
        }
        // eslint-disable-next-line
    }, [dispatch, initialized])

    useEffect(() => {
        if (valuesLoaded || formUpdated) {
            return
        }

        if (Array.isArray(value) && isEmpty(value)) {
            return
        }

        if (!Array.isArray(value) && !value) {
            return
        }

        setValuesLoaded(true)

        loadSavedValues()
    }, [value])

    const loadSavedValues = async (v) => {
        try {
            const response = await dispatch(loadPaginatedData({ values: v || value }))
            setSavedValues(response)
        } catch (e) {
            console.error("RESPONSE ERROR: ", e)
        }
    }

    /**
     * Fetch paginated results from the slice action that is passed in. We are expecting a uniform
     * format of sending in current, pageSize
     */
    const fetchPaginated = (pagination = { current: currentPage, pageSize: pageSize }) => {
        dispatch(loadPaginatedData(pagination))
    }

    /**
     * Handle what happens when a user clicks a new pagination option, not an individual option.
     * this is responsible for fetching the next page and setting that new page in state
     */
    const handlePaginationChange = (newPage) => {
        fetchPaginated({ current: newPage, pageSize: pageSize })
        setCurrentPage(newPage)
    }

    /**
     * Handle what happens when a user makes a change by typing in the search bar. This will turn
     * on search mode, disabling pagination and perform the search.
     */
    const handleInputChange = (value) => {
        if (value.length) {
            setSearchMode(true)
            onSearch(value)
        } else {
            setSearchMode(false)
        }
    }

    /**
     * Handle what happens when the actual selected options change. When the input is cleared
     * with the X it sets the value as undefined, so if that comes back we want to kick them
     * out of search mode since it means they deleted their search.
     */
    const handleSelect = (value, name) => {
        onChange(value)
        
        if(name == 'divisions'){
            dispatch(
                fetchDivisionFacilities(value, clientId)
            )
        }

        setFormUpdated(true)

        /**
         * When we are in search mode, the user makes the selection, but then when the search
         * menu closes, we lose context of those objects, and it will snap back to the ID unless
         * we load the new id as well. Therefore we essentially want to run the same process
         * of regathering those ids into memory with whatever was selected.
         */
        if (searchMode) {
            loadSavedValues(value)
        }

        // when it's a single value it's set to undefined
        if (value === undefined) {
            setSearchMode(false)
        }

        // when it's in multiple mode it sets it to an empty array
        if (Array.isArray(value) && value.length) {
            setSearchMode(false)
            // if we don't fetch paginated again the results get all messed up for some reason... -- i'm not sure if this is still valid...
            // fetchPaginated()
        }
    }

    return (
        <Select
            showSearch
            loading={loading}
            filterOption={false}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            value={value}
            allowClear={true}
            onChange={(values) => handleSelect(values, name)}
            onSearch={handleInputChange}
            mode={mode}
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    {!searchMode && (
                        <>
                            <Divider />
                            <div style={{ textAlign: "center" }}>
                                <Pagination simple current={currentPage} total={paginatedTotal} onChange={handlePaginationChange} style={{ paddingBottom: "10px" }} />
                            </div>
                        </>
                    )}
                </div>
            )}>
            {options?.concat(savedValues).map((o) => {
                try {
                    return (
                        <Select.Option key={o.id} value={o.id}>
                            {renderOptionValue ? renderOptionValue(o) : o.name}
                        </Select.Option>
                    )
                } catch (e) {
                    console.log("Caught option render error: ", savedValues)
                    return null
                }
            })}
        </Select>
    )
}
