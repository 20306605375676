import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../lib/client"
import {
    groupAccountUrl,
    subaccountOptionsForGroupAccountUrl,
    clientGroupAccountsSidebarUrl,
    dynamicUrl,
    groupAccountsScopedByClientUrl,
    groupSubaccountsScopedByClientUrl,
    groupBillableAccountCsvUrl,
} from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const groupAccountsSlice = createSlice({
    name: "groupAccounts",
    initialState: {
        groupAccount: {},
        groupAccounts: [],
        groupAccountSelectOptions: [],
        subaccounts: [],
        subaccountSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        groupAccountSearch: [],
    },
    reducers: {
        setGroupAccount: (state, action) => {
            state.groupAccount = action.payload
        },
        setGroupAccounts: (state, action) => {
            state.groupAccounts = action.payload.group_accounts
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setGroupAccountSelectOptions: (state, action) => {
            state.groupAccountSelectOptions = action.payload
        },
        setGroupAccountSearch: (state, action) => {
            state.groupAccountSearch = action.payload
        },
        setSubaccounts: (state, action) => {
            state.subaccounts = action.payload.subaccounts
            state.total = action.payload.total
        },
        setSubaccountSelectOptions: (state, action) => {
            state.subaccountSelectOptions = action.payload
        },
    },
})

export const { setGroupAccount, setGroupAccounts, setPagination, setLoading, setGroupAccountSelectOptions, setGroupAccountSearch, setSubaccounts, setSubaccountSelectOptions } =
    groupAccountsSlice.actions

export function fetchGroupAccount(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(groupAccountUrl(id)).then((response) => {
            dispatch(setGroupAccount(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchGroupAccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setGroupAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientGroupAccounts(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientGroupAccountsPath = `clients/${clientId}/group_accounts/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientGroupAccountsPath)}`)
        return response.data.group_accounts
    }
}

export function fetchGroupAccountsSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(clientGroupAccountsSidebarUrl(clientId))
        return response.data.group_accounts
    }
}

export function fetchSubaccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setSubaccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchSubaccountSelectOptions(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(subaccountOptionsForGroupAccountUrl(id)).then((response) => {
            dispatch(setSubaccountSelectOptions(response.data.subaccount_options))
            dispatch(setLoading(false))
        })
    }
}

export function submitGroupAccount(values, id) {
    return async (dispatch, getState) => {
        // const id = getState().groupAccounts.groupAccount.id
        if (id) {
            // patch - update
            const response = await client.patch(groupAccountUrl(id), { group_account: values })
            return response.data
        }
    }
}

// export function fetchGroupAccountSelectOptions(searchTerm) {
//     return async (dispatch, getState) => {
//         client.get(`${groupAccountSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setGroupAccountSelectOptions(response.data.groupAccounts))
//         })
//     }
// }

// export function fetchGroupAccountSelectOptionById(id) {
//     return async (dispatch, getState) => {
//         client.get(`${groupAccountSelectOptionByIdUrl}?groupAccount_id=${id}`).then((response) => {
//             dispatch(setGroupAccountSelectOptions(response.data.groupAccounts))
//         })
//     }
// }

// export function searchGroupAccounts(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${groupAccountSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setGroupAccountSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export function filterGroupAccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, groupAccountsScopedByClientUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setGroupAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterSubaccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, groupSubaccountsScopedByClientUrl(query.clientId, query.groupAccountId), "filter_subaccounts")

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setSubaccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${groupBillableAccountCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectGroupAccount = (state) => state.groupAccounts.groupAccount
export const selectGroupAccounts = (state) => state.groupAccounts.groupAccounts
export const selectTotal = (state) => state.groupAccounts.total
export const selectPagination = (state) => state.groupAccounts.pagination
export const selectLoading = (state) => state.groupAccounts.loading
export const selectGroupAccountSelectOptions = (state) => state.groupAccounts.groupAccountSelectOptions
export const selectGroupAccountSearch = (state) => state.groupAccounts.groupAccountSearch
export const selectSubaccounts = (state) => state.groupAccounts.subaccounts
export const selectSubaccountSelectOptions = (state) => state.groupAccounts.subaccountSelectOptions

export default groupAccountsSlice.reducer
