import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { selectScopedByClient } from "../../stores/appSlice"

export default function ReportNav({ activeTab }) {
    const dispatch = useDispatch()
    const { clientId } = useParams()
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the division we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    // useEffect(() => {
    //     if (divisionId) {
    //         dispatch(fetchDivision(divisionId))
    //     }
    // }, [dispatch, divisionId])

    const formatRoute = (newRoute) => {
        return `/clients/${clientId}/reports${newRoute}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return renderIndexHeader()
        }
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Reports</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/dashboard")}>
                            <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                                Reports Dashboard
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to={() => formatRoute("/generated-reports")}>
                            <button type="link" className={`${activeTab === "generated-reports" ? "selected" : ""}`}>
                                Generated Reports
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
