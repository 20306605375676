import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationPin } from "@fortawesome/free-solid-svg-icons"
import { selectScopedByClient } from "../../stores/appSlice"
import { fetchSupplyAccount, selectSupplyAccount } from "./supplyAccountsSlice"

export default function SupplyAccountNav({ activeTab, scopedBySupplyAccount = false }) {
    const dispatch = useDispatch()
    const { clientId, supplyAccountId } = useParams()
    const supplyAccount = useSelector(selectSupplyAccount)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the supplyAccount we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (supplyAccountId) {
            dispatch(fetchSupplyAccount(supplyAccountId))
        }
    }, [dispatch, supplyAccountId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (supplyAccountId && scopedBySupplyAccount) {
                return `/clients/${clientId}/supply_accounts/${supplyAccountId}${newRoute}`
            } else {
                return `/clients/${clientId}/supply_accounts${newRoute}`
            }
        } else if (supplyAccountId) {
            return `/supply_accounts/${supplyAccountId}${newRoute}`
        }

        return `/supply_accounts${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedBySupplyAccount ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Supply Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Supply Accounts
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>
                    <Link to={() => `/clients/${clientId}/divisions/${supplyAccount.division_id}/dashboard`}>{supplyAccount.division_name} > </Link>
                    <Link to={() => `/clients/${clientId}/facilities/${supplyAccount.facility_id}/dashboard`}>{supplyAccount.facility_name} > </Link>
                    {`${supplyAccount.account_number}`}
                    <a style={{marginLeft: "5px"}}  href={`https://www.google.com/maps/search/?api=1&query=${supplyAccount.street_address_1}, ${supplyAccount.state}, ${supplyAccount.zip}`} target="_blank">
                        <FontAwesomeIcon icon={faLocationPin} color="#fbb040" />
                    </a>
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/documents")}>
                            <button type="link" className={`${activeTab === "documents" ? "selected" : ""}`}>
                                Documents
                            </button>
                        </Link>
                    </li>

                    {/* TODO: Do they actually want this functionality? */}
                    {/* <li>
                        <Link to={() => formatRoute("/services")}>
                            <button type="link" className={`${activeTab === "services" ? "selected" : ""}`}>
                                Services
                            </button>
                        </Link>
                    </li> */}

                    <li>
                        <Link to={() => formatRoute("/bills")}>
                            <button type="link" className={`${activeTab === "bills" ? "selected" : ""}`}>
                                Supply Account's bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Supply Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                Supply Accounts List
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
