import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Row, Col, Select, Button, Skeleton } from "antd"
import { useParams, Link, useRouteMatch, useHistory } from "react-router-dom"
import { LeftOutlined } from "@ant-design/icons"
import { fetchRoles, selectRoles } from "../roles/rolesSlice"
import { titleize } from "../../lib/string"
import PaginatedSelect from "../forms/components/PaginatedSelect"
import { fetchClients, searchClients, selectClients, selectClientSearch, selectTotal as selectClientTotal } from "../clients/clientsSlice"
import { fetchDivisions, searchDivisions, selectDivisions, selectDivisionSearch, selectTotal as selectDivisionTotal } from "../divisions/divisionsSlice"
import { fetchFacilities, searchFacilities, selectFacilities, selectFacilitySearch, selectTotal as selectFacilityTotal } from "../facilities/facilitiesSlice"
import {
    fetchBillableAccounts,
    searchBillableAccounts,
    selectBillableAccounts,
    selectBillableAccountSearch,
    selectTotal as selectBillableAccountTotal,
} from "../billableAccounts/billableAccountsSlice"
import { fetchSupplyAccounts, searchSupplyAccounts, selectSupplyAccounts, selectSupplyAccountSearch, selectTotal as selectSupplyAccountTotal } from "../supplyAccounts/supplyAccountsSlice"
import { debounce } from "lodash"
import { createUserRole, fetchUserRole, selectUserRole, setUserRole, updateUserRole, selectLoading as selectUserRoleLoading } from "./userRolesSlice"
import { fetchChannelPartnersFlatSelect } from "../channelPartners/channelPartnersSlice"
import { requestNotification } from "../../lib/notifications"
import path from "path"

export default function UserRoleForm({ actionName }) {
    const dispatch = useDispatch()

    const { clientId, userId, roleId } = useParams()
    const { url } = useRouteMatch()
    const history = useHistory()

    const [form] = Form.useForm()

    const [init, setInit] = useState(false)
    const [selectedRole, setSelectedRole] = useState(null)
    const [partners, setPartners] = useState([])

    const roles = useSelector(selectRoles)
    const userRole = useSelector(selectUserRole)
    const userRoleLoading = useSelector(selectUserRoleLoading)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchRoles())

        if (actionName === "edit" && roleId) {
            dispatch(fetchUserRole(userId, roleId))
        } else {
            dispatch(setUserRole({}))
        }
    }, [dispatch, init])

    useEffect(() => {
        form.resetFields()
    }, [userRole, form])

    useEffect(() => {
        if (!userRole || !roles || !roles?.length) {
            return
        }

        const role = roles.filter((r) => r.id === userRole?.role_id)[0]

        setSelectedRole(role)

        loadChannelPartners(role)
    }, [userRole, roles])

    const submit = async (values) => {
        const response = actionName === "new" ? await dispatch(createUserRole(userId, values)) : await dispatch(updateUserRole(userId, roleId, values))

        if (response?.success) {
            history.push(backPath())
        }

        requestNotification(response)
    }

    const backPath = () => {
        return actionName === "new" ? path.join(url, "..") : path.join(url, "../..")
    }

    const performSearch = debounce((value, callback) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            callback()
        }
    }, 1000)

    const handleClientSearch = (value) => {
        performSearch(value, () => dispatch(searchClients(value)))
    }

    const handleDivisionSearch = (value) => {
        performSearch(value, () => dispatch(searchDivisions(value)))
    }

    const handleFacilitySearch = (value) => {
        performSearch(value, () => dispatch(searchFacilities(value)))
    }

    const handleBillableAccountSearch = (value) => {
        performSearch(value, () => dispatch(searchBillableAccounts(value)))
    }

    const handleSupplyAccountSearch = (value) => {
        performSearch(value, () => dispatch(searchSupplyAccounts(value)))
    }

    const handleRoleChange = (roleId) => {
        const role = roles.filter((r) => r.id === roleId)[0]

        if (!role) {
            return
        }

        setSelectedRole(role)

        loadChannelPartners(role)
    }

    const loadChannelPartners = async (role) => {
        if (role?.name !== "channel_partner_admin") {
            return
        }

        const response = await dispatch(fetchChannelPartnersFlatSelect())
        setPartners(response)
    }

    /**
     * If the user selected a client-required role, and we are NOT in a client URL scope, we have to show
     * them the select client required dropdown.
     */
    const showUserRoleClientSelect = () => {
        if (["client_manager", "client_user", "read_only_user"].includes(selectedRole?.name) && !clientId) {
            return true
        }

        return false
    }

    const isLoading = () => {
        // for right now, we don't care if the form renders on new since all fields will be shown
        if (actionName !== "edit") {
            return
        }

        // on edit, if we are fetching the user role, don't have a user role yet, and don't have a selected role yet (which controls the form showing/hiding),
        // we mark the form as loading. This will keep the user role multi selects for division, facility, etc. from loading so they don't load
        // all of that data, then be hidden immediately and made those requests for nothing.
        return userRoleLoading || !userRole || !selectedRole
    }

    const isChannelPartner = () => {
        return selectedRole?.name === "channel_partner_admin"
    }

    return (
        <div id="user-form-container">
            <Link to={backPath()}>
                <LeftOutlined />
                Go Back
            </Link>

            <Skeleton active loading={isLoading()}>
                <Form form={form} onFinish={submit} initialValues={userRole} layout="vertical">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <Row gutter={24}>
                                    <Col span={showUserRoleClientSelect() ? 12 : 24}>
                                        <Form.Item label="Role" name="role_id" rules={[{ required: true }]}>
                                            <Select onChange={handleRoleChange}>
                                                {roles.map((r) => (
                                                    <Select.Option key={r.id} value={r.id}>
                                                        {titleize(r.name)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {showUserRoleClientSelect() && !isChannelPartner() && (
                                        <Col span="12">
                                            <Form.Item label="Client" name="client_id" rules={[{ required: true }]}>
                                                {/* @TODO: I'm not sure what will happen by adding 2 paginated selects for the same model (client_ids below)... */}
                                                <PaginatedSelect
                                                    placeholder="Search clients..."
                                                    onSearch={handleClientSearch}
                                                    paginationSelector={selectClients}
                                                    paginationTotalSelector={selectClientTotal}
                                                    loadPaginatedData={fetchClients}
                                                    searchSelector={selectClientSearch}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* super admins only see the role select */}
                                {selectedRole?.name !== "super_admin" && selectedRole?.name !== "client_manager" && !isChannelPartner() && (
                                    <div>
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item label="Clients" name="client_ids">
                                                    <PaginatedSelect
                                                        placeholder="Search clients..."
                                                        onSearch={handleClientSearch}
                                                        paginationSelector={selectClients}
                                                        paginationTotalSelector={selectClientTotal}
                                                        loadPaginatedData={fetchClients}
                                                        searchSelector={selectClientSearch}
                                                        mode={["client_user", "read_only_user"].includes(selectedRole?.name) ? null : "multiple"}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* muc admin, client managers, etc. can only see role client dropdowns, nothing else */}
                                        {!["muc_admin", "client_manager"].includes(selectedRole?.name) && (
                                            <div>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="Divisions" name="division_ids">
                                                            <PaginatedSelect
                                                                placeholder="Search divisions..."
                                                                onSearch={handleDivisionSearch}
                                                                paginationSelector={selectDivisions}
                                                                paginationTotalSelector={selectDivisionTotal}
                                                                loadPaginatedData={(p) => fetchDivisions(p, "/divisions")}
                                                                searchSelector={selectDivisionSearch}
                                                                mode="multiple"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="Facilities" name="facility_ids">
                                                            <PaginatedSelect
                                                                placeholder="Search facilities..."
                                                                onSearch={handleFacilitySearch}
                                                                paginationSelector={selectFacilities}
                                                                paginationTotalSelector={selectFacilityTotal}
                                                                loadPaginatedData={(p) => fetchFacilities(p, "/facilities")}
                                                                searchSelector={selectFacilitySearch}
                                                                mode="multiple"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="Billable Accounts" name="billable_account_ids">
                                                            <PaginatedSelect
                                                                placeholder="Search billable accounts..."
                                                                onSearch={handleBillableAccountSearch}
                                                                paginationSelector={selectBillableAccounts}
                                                                paginationTotalSelector={selectBillableAccountTotal}
                                                                loadPaginatedData={(p) => fetchBillableAccounts(p, "/billable_accounts")}
                                                                searchSelector={selectBillableAccountSearch}
                                                                mode="multiple"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="Supply Accounts" name="supply_account_ids">
                                                            <PaginatedSelect
                                                                placeholder="Search supply accounts..."
                                                                onSearch={handleSupplyAccountSearch}
                                                                paginationSelector={selectSupplyAccounts}
                                                                paginationTotalSelector={selectSupplyAccountTotal}
                                                                loadPaginatedData={(p) => fetchSupplyAccounts(p, "/supply_accounts")}
                                                                searchSelector={selectSupplyAccountSearch}
                                                                mode="multiple"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {isChannelPartner() && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item label="Channel Partner" name="channel_partner_id" rules={[{ required: true }]}>
                                                <Select>
                                                    {partners.map((p) => (
                                                        <Select.Option key={p.id} value={p.id}>
                                                            {titleize(p.name)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Form>
            </Skeleton>
        </div>
    )
}
