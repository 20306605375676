import React from "react"
import { useRouteMatch, Switch, Route, useParams } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"
import GlobalBatch from "../features/globalBatch/GlobalBatchPage"
import GlobalBatchForm from "../features/globalBatch/components/GlobalBatchForm"
import GlobalBatchView from "../features/globalBatch/components/GlobalBatchesView"
import BillTable from "../features/globalBatch/components/BillTable"
import GlobalBatchNav from "../features/globalBatch/components/GlobalBatchNav"
import { paymentTypes } from "../features/globalBatch/consts/paymentTypes"
export default function GlobalBatchRoutes() {
    const { path } = useRouteMatch()   
    const params = useParams()

    return (
        <Switch>
            <Route exact path={path}>
                {/* <GlobalBatch /> */}
                <ConsoleLayout header={<GlobalBatchNav activeTab="global_bills" headerText="Global Batches" />} highlightTab="globalBatch" body={<GlobalBatch />} bodyFullWidth />
            </Route>
            <Route exact path="/global_batch/new">
                <ConsoleLayout
                    highlightTab="globalBatch"
                    // header={<PaymentBatchNav activeTab="paymentBatches" headerText="Vendor Batches" />}
                    body={<GlobalBatchForm view="form" recordSelected={false} />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route>
            {/* <Route exact path="/global_batch/view">
                <ConsoleLayout
                    highlightTab="globalBatch"
                    header={<GlobalBatchNav activeTab="batches" headerText="Global Batches" />}
                    body={<GlobalBatchView view="index" />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route> */}
            <Route exact path="/global_batch/todays_batches">
                <ConsoleLayout
                    highlightTab="globalBatch"
                    header={<GlobalBatchNav activeTab="todays_batches" headerText="Todays Batches" />}
                    body={<GlobalBatchView view="index" />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route>
            <Route exact path="/global_batch/todays_batches">
                <ConsoleLayout
                    highlightTab="globalBatch"
                    header={<GlobalBatchNav activeTab="todays_batches" headerText="Todays Batches" />}
                    body={<GlobalBatchView view="index" />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route>
            <Route exact path={`/global_batch/todays_batches/:type`}>
                <ConsoleLayout
                    highlightTab="globalBatch"
                    header={<GlobalBatchNav activeTab={`todays_batches_for_${useParams().type}`} headerText="Todays Batches" />}
                    body={<GlobalBatchView view="index" />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route>
            <Route exact path="/global_batch/view/:globalBatchId/bills">
                <ConsoleLayout highlightTab="globalBatch" header={<GlobalBatchNav activeTab="bills" headerText="Global Batches" />} body={<BillTable />} bodyFullWidth />
            </Route>
            <Route exact path="/global_batch/:globalBatchId/edit">
                <ConsoleLayout
                    highlightTab="globalBatch"
                    // header={<PaymentBatchNav activeTab="paymentBatches" headerText="Vendor Batches" />}
                    body={<GlobalBatchForm actionName="edit" />}
                    bodyFullWidth
                    // sidebarType="paymentBatches"
                />
            </Route>
            <Route exact path="/global_batch/:globalBatchId/newBills">
                <ConsoleLayout header={<GlobalBatchNav activeTab="new_bills" headerText="Global Batches" />} highlightTab="globalBatch" body={<GlobalBatch />} bodyFullWidth />
            </Route>
        </Switch>
    )
}