import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ReportAccountingBatches from "./fields/ReportAccountingBatches"
import ReportDivisions from "./fields/ReportDivisions"
import ReportFacilities from "./fields/ReportFacilities"
import ReportDateRange from "./fields/ReportDateRange"
import ReportVendors from "./fields/ReportVendors"
import ReportBillableAccounts from "./fields/ReportBillableAccounts"
import ReportSupplyAccounts from "./fields/ReportSupplyAccounts"
import ReportUtilityServices from "./fields/ReportUtilityServices"
import GlobalDataServiceFields from "./fields/GlobalDataServiceFields"
import ReportLedgerFields from "./fields/ReportLedgerFields"
import client from "../../../lib/client"
import { clientReportGlobalDataServiceFieldsUrl } from "../../../lib/endpoints"
import { notification } from "antd"
import { selectGeneratedReport } from "../reportsSlice"

export default function GlobalData({ form, actionName }) {
    const { clientId } = useParams()

    const generatedReport = useSelector(selectGeneratedReport)

    const [serviceFields, setServiceFields] = useState(null)
    const [init, setInit] = useState(false)

    useEffect(() => {
        if (init || actionName !== "edit" || !generatedReport) {
            return
        }

        setInit(true)

        updateFieldData(generatedReport?.form_data?.utility_service_fields)
    }, [init, generatedReport, actionName])

    const serviceChange = (value) => {
        client
            .get(clientReportGlobalDataServiceFieldsUrl(clientId, value))
            .then(({ data }) => {
                updateFieldData(data)
            })
            .catch((e) => {
                console.error(e)
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
            })
    }

    const updateFieldData = (data) => {
        const current = form.getFieldsValue()

        const formattedData = data.map((i) => {
            return {
                ...i,
                checked: i?.checked || false,
            }
        })

        // set form attributes for this 'hidden' value that we need...
        form.setFieldsValue({
            ...current,
            form_data: {
                ...current.form_data,
                utility_service_fields: formattedData,
            },
        })

        setServiceFields(formattedData)
    }

    // date range OR an client batch.
    // The user must select a service type.
    // Division(s)
    // Facilities
    // Utility Companies
    // Utility Accounts
    // Supply Accounts
    return (
        <div>
            <ReportAccountingBatches />
            <ReportDateRange />
            <ReportUtilityServices onChange={serviceChange} />
            {serviceFields && serviceFields.length && <GlobalDataServiceFields fields={serviceFields} setServiceFields={setServiceFields} form={form} />}
            <ReportDivisions />
            <ReportFacilities />
            <ReportVendors />
            <ReportBillableAccounts />
            <ReportSupplyAccounts />
            <ReportLedgerFields form={form} />
        </div>
    )
}
