import React from "react"

function SiteSurveysDocumentsForm(props) {
    return (
        <div id={"site-surveys-documents-form"} className={"card"}>
            <div>Documents Form</div>
        </div>
    )
}

export default SiteSurveysDocumentsForm
