import React from "react"
import { Timeline, Skeleton, Pagination } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { selectAuditLog, selectLoading, selectPagination, selectTotal, fetchAuditLog, selectFilter } from "../auditLogSlice"
import moment from "moment"
import AuditLogDateGroup from "./AuditLogDateGroup"

export default function AuditLogTimeLine() {
    const dispatch = useDispatch()

    const auditLog = useSelector(selectAuditLog)
    const loading = useSelector(selectLoading)
    const pagination = useSelector(selectPagination)
    const total = useSelector(selectTotal)
    const filter = useSelector(selectFilter)

    const handlePaginationChange = (page, pageSize) => {
        dispatch(fetchAuditLog({ current: page, pageSize: pageSize }, filter))
    }

    const groupedByDate = {}

    auditLog.forEach((version) => {
        // group the objects by day -> L gives you /m/d/y
        // we can actually scope this down to as low as we want, hour, minute, etc...
        const date = moment(version.version.created_at).format("L")

        const target = groupedByDate[date] || []

        target.push(version)

        groupedByDate[date] = target
    })

    return (
        <Skeleton loading={loading}>
            <Timeline mode="left">
                {Object.keys(groupedByDate).map((date) => {
                    return <AuditLogDateGroup key={date} date={date} logs={groupedByDate[date]} />
                })}
            </Timeline>

            <Pagination
                style={{ marginTop: "20px" }}
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={total}
                onChange={handlePaginationChange}
                showSizeChanger
            />
        </Skeleton>
    )
}
