import React from "react"
import PropTypes from "prop-types"

import { Popover, Badge } from "antd"
import { WarningTwoTone } from "@ant-design/icons"

function WarningChip(props) {
    if (!props.contentText || (props.contentText.length === 0 && !props.childFlags)) {
        /// if props.contentText isn't passed down, it means there isn't an associated flag value and the WarningChip
        /// component doesn't need to be rendered
        return null
    }

    // I had to blow this up in order to fit in the new flags that can exist on the child service bills
    const renderFlagContent = () => {
        /// if props.contentText is a type of string, it means that the associated error message is passed down
        /// straight from the form field that has the associated error (how it's done on BillForm.jsx, for example).
        /// if props.contentText is not a string, it's an object of key-value pairs passed down from BillTable.jsx
        /// which needs to be mapped over to get multiple flags values.
        if (typeof props.contentText === "string") {
            return <p>{props.contentText}</p>
        } else if (Object.values(props.contentText).length > 0) {
            // if this is true, it's an index-view style table and we want to append the child error message to the very last instance
            // of the flag list
            if (props.childFlags) {
                return Object.values(props.contentText).map((flagValue, index) => {
                    if (index === Object.values(props.contentText).length - 1) {
                        return (
                            <>
                                <p key={flagValue}>• {flagValue}</p>
                                <p key={"child-flags"}>• One or more service bills have flags.</p>
                            </>
                        )
                    } else {
                        return <p key={flagValue}>• {flagValue}</p>
                    }
                })
            } else {
                return Object.values(props.contentText).map((flagValue) => {
                    return <p key={flagValue}>• {flagValue}</p>
                })
            }
        } else if (props.childFlags) {
            return <p key={"child-flags"}>• One or more service bills have flags.</p>
        }
    }

    const evalFlagCount = () => {
        if (props.childFlags) {
            return (parseInt(props.flagCount) || 0) + 1
        } else {
            return props.flagCount
        }
    }

    return (
        <Popover className="warning-chip" content={<div>{renderFlagContent()}</div>} placement="topRight" trigger="hover">
            <Badge count={evalFlagCount()} size="small" offset={[-20, 5]}>
                <WarningTwoTone style={{ fontSize: "18px" }} twoToneColor="#fbb040" />
            </Badge>
        </Popover>
    )
}

WarningChip.propTypes = {
    contentText: PropTypes.any,
    flagCount: PropTypes.any,
    childFlags: PropTypes.any,
}

export default WarningChip
