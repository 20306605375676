import React from 'react'
import { Form } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from "lodash"
import PaginatedSelect from '../../../forms/components/PaginatedSelect'

import {
    fetchVendors,
    selectVendors,
    selectPagination,
    selectTotal,
    selectVendorSearch,
    searchVendors
} from '../../../vendors/vendorsSlice'

export default function ReportVendors() {
    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)
    const vendors    = useSelector(selectVendors)

    const handleSearch = debounce((value) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            dispatch(searchVendors(value))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {
                    fields => (
                        <Form.Item label="Vendor" name="vendor_ids">
                            <PaginatedSelect
                                mode='multiple'
                                placeholder='Search vendors...'
                                onSearch={handleSearch}
                                paginationSelector={selectVendors}
                                paginationTotalSelector={selectTotal}
                                loadPaginatedData={(p) => fetchVendors(p, 'vendors')}
                                searchSelector={selectVendorSearch}
                                renderOptionValue={(v) => v.display_name}
                            />
                        </Form.Item>
                    )
                }
            </Form.List>
        </div>
    )
}
