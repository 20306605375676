import { Button, Form, Input, Row, Col, notification } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useLocation, useHistory } from "react-router-dom"

import { resetPassword } from "./passwordResetSlice"
import MucLogoCircle from "../../components/utilityComponents/assetComponents/MucLogoCircle"

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function ResetPassword() {
    const dispatch = useDispatch()
    const history = useHistory()
    let query = useQuery()

    const accessToken = query.get("access-token")
    const clientToken = query.get("client")
    const uid = query.get("uid")

    if (!accessToken || !clientToken || !uid) {
        return <Redirect to="/login" />
    }

    const submit = async (values) => {
        const response = await dispatch(resetPassword({ ...values, redirect_url: process.env.FRONTEND_LOGIN_URL }, accessToken, clientToken, uid))

        if (response && response.success) {
            notification.open({
                message: "Your password has been reset",
                description: "Please login with your new password",
                duration: 0.8,
            })
            history.push("/login")
        } else {
            notification.error({
                message: "There was an error updating your password",
                description: `${response.join(", ")}`,
                duration: 0.8,
            })
        }
    }

    return (
        <div id="login-content">
            <Row gutter={[0, 30]} style={{ marginBottom: "30px" }}>
                <Col className="login-logo">
                    <MucLogoCircle />
                </Col>
            </Row>

            <Form onFinish={(values) => submit(values)} layout="vertical">
                <Row id="login-buttons-row" gutter={[16, 4]}>
                    <Col span={24}>
                        <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item name="password_confirmation" label="Confirm Password" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>

                <Row id="login-buttons-row" gutter={[16, 4]}>
                    <Col span={24}>
                        <Button id="sign-in-button" type="warning" htmlType="submit" style={{ width: "100%" }}>
                            Reset Password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
