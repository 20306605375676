import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { dynamicUrl, clientVendorNumbersUrl, clientVendorNumberUrl } from "../../lib/endpoints"
import { notification } from "antd"
import { clientUserRootPath } from "../../lib/roles"
import { filter } from "lodash"

export const clientVendorNumbersSlice = createSlice({
    name: "clientVendorNumbers",
    initialState: {
        loading: false,
        clientVendorNumbers: [],
        clientVendorNumber: {},
        vendorSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setClientVendorNumbers: (state, action) => {
            state.clientVendorNumbers = action.payload.client_vendor_numbers
            state.total = action.payload.total
        },
        setClientVendorNumber: (state, action) => {
            state.clientVendorNumber = action.payload
        },
        setVendorSelectOptions: (state, action) => {
            state.vendorSelectOptions = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
    },
})

export const { setLoading, setClientVendorNumbers, setClientVendorNumber, setVendorSelectOptions, setPagination } = clientVendorNumbersSlice.actions

export function fetchClientVendorNumbers(clientId, pagination) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client.get(`${clientVendorNumbersUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setClientVendorNumbers(response.data))
            dispatch(setLoading(false))
        })

        // client
        //     .get(clientVendorNumbersUrl(clientId))
        //     .then(({ data }) => {
        //         console.log(data)
        //         dispatch(setClientVendorNumbers(data))
        //     })
        //     .catch((error) => {
        //         console.warn(error)
        //     })
        //     .finally(() => {
        //         dispatch(setLoading(false))
        //     })
    }
}

export function fetchClientVendorNumber(clientId, id) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(clientVendorNumberUrl(clientId, id))
            .then(({ data }) => {
                dispatch(setClientVendorNumber(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function submitClientVendorNumber(values, clientId, id = null) {
    return async (dispatch) => {
        if (id) {
            // patch - update
            const response = await client.patch(clientVendorNumberUrl(clientId, id), { client_vendor_number: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(clientVendorNumbersUrl(clientId), { client_vendor_number: values })
            return response.data
        }
    }
}

export function deleteClientVendorNumber(clientId, id) {
    return async (dispatch, getState) => {
        const response = await client.delete(`${clientVendorNumberUrl(clientId, id)}`)
        if (response.data.success) {
            let vendorNumbers = [...getState().clientVendorNumbers.clientVendorNumbers]

            let newList = filter(vendorNumbers, function (n) {
                return n.id !== parseInt(id)
            })

            dispatch(setClientVendorNumbers({ client_vendor_numbers: [...newList], total: getState().clientVendorNumbers.total - 1 }))
            return response.data
        } else {
            return response.data
        }
    }
}

export const selectClientVendorNumbers = (state) => state.clientVendorNumbers.clientVendorNumbers
export const selectClientVendorNumber = (state) => state.clientVendorNumbers.clientVendorNumber
export const selectLoading = (state) => state.clientVendorNumbers.loading
export const selectPagination = (state) => state.clientVendorNumbers.pagination
export const selectTotal = (state) => state.clientVendorNumbers.total
// export const selectVendorSelectOptions = (state) => state.vendors.vendorSelectOptions

export default clientVendorNumbersSlice.reducer
