import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import DivisionNav from "../features/divisions/DivisionNav"
import DivisionPage from "../features/divisions/DivisionPage"

// import Subheader from "../features/nav/Subheader"

// const tabs = {
//     scopedTabs: {
//         recordTabs: [
//             { name: "Dashboard", link: "dashboard" },
//             { name: "Facilities", link: "facilities" },
//         ],
//         indexTabs: [
//             { name: "Dashboard", link: "dashboard" },
//             { name: "Division List", link: "list" },
//         ],
//     },
//     unscopedTabs: ["All Divisions", "list"],
// }

export default function DivisionRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            {/* TEST FOR DYNAMIC SUBHEADER COMPONENT */}
            {/* <Route exact path={path}>
                <ConsoleLayout header={<Subheader type="Divisions" tabs={tabs} activeTab="Dashboard" />} body={<DivisionTable />} />
            </Route> */}

            {/* <Route exact path={path}>
                <ConsoleLayout header={<DivisionNav activeTab="list" />} body={<DivisionTable />} />
            </Route> */}

            <Route exact path={path}>
                <ConsoleLayout highlightTab="divisions" header={<DivisionNav activeTab="list" />} body={<DivisionPage view="index" recordSelected={false} />} sidebarType="divisions" />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="divisions" header={<DivisionNav activeTab="list" />} body={<DivisionPage view="form" recordSelected={false} />} sidebarType="divisions" />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout highlightTab="divisions" header={<DivisionNav activeTab="list" />} body={<DivisionPage view="import" recordSelected={false} />} sidebarType="divisions" />
            </Route>

            <Route path={`${path}/dashboard`}>
                <ConsoleLayout highlightTab="divisions" header={<DivisionNav activeTab="dashboard" />} body={<DivisionPage view="dashboard" recordSelected={false} />} sidebarType="divisions" />
            </Route>

            {/* Even though a division is technically selected here, we are not scoped to this division since it's the edit form.
            The scopedByDivision prop being false lets the header component know not to build the nav tabs to be scoped under
            a specific division. */}
            <Route path={`${path}/:divisionId/edit`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="list" scopedByDivision={false} />}
                    body={<DivisionPage view="form" recordSelected={true} sidebarType="divisions" />}
                />
            </Route>

            <Route path={`${path}/:divisionId/dashboard`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="dashboard" scopedByDivision={true} />}
                    body={<DivisionPage view="dashboard" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId/facilities/new`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="facilities" scopedByDivision={true} />}
                    body={<DivisionPage view="facilities-form" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId/facilities/:facilityId/edit`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="facilities" scopedByDivision={true} />}
                    body={<DivisionPage view="facilities-form" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId/facilities`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="facilities" scopedByDivision={true} />}
                    body={<DivisionPage view="facilities" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route exact path={`${path}/:divisionId/documents`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="documents" scopedByDivision={true} />}
                    body={<DivisionPage view="documents" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId/documents/new`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="documents" scopedByDivision={true} />}
                    body={<DivisionPage view="documents-form" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId/documents/:documentId/edit`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="documents" scopedByDivision={true} />}
                    body={<DivisionPage view="documents-form" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>

            <Route path={`${path}/:divisionId`}>
                <ConsoleLayout
                    highlightTab="divisions"
                    header={<DivisionNav activeTab="dashboard" scopedByDivision={true} />}
                    body={<DivisionPage view="dashboard" recordSelected={true} />}
                    sidebarType="divisions"
                />
            </Route>
        </Switch>
    )
}
