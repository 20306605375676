import { allObjectValuesEmpty } from "./allObjectValuesEmpty"

export function createRansackParams(filters) {
    /// create the start of the filter URL string
    let queryParams = "?"

    /// if all of the values in the filter object are not empty, we take each of the key-value pairs in the query,
    ///  and add on another part to the filterUrl. in the query:
    /// { each key } - correlates to the field that needs to be filtered and its ransack predicate (ex. name_cont), and
    /// { each value } - correlates to the string to filter it by
    if (!allObjectValuesEmpty(filters)) {
        Object.entries(filters).forEach(([key, value]) => {
            queryParams += `q[${key}]=${value}&`
        })
    }

    return queryParams
}
