import React from "react"
import { Link } from "react-router-dom"

// NOTE i changed target='_blank' to be a default prop since sometimes they wanted it opened in the same tab.
// i'm not exactly sure why these were set to open in new tabs, but to not break the current uses i didn't
// want to change every usage of this to _self. Changed for BillTable.jsx.
export default function BillAccountLink({ accountType, accountId, accountName, clientId, target='_blank' }) {
    const formatLink = () => {
        if (accountType === "BillableAccount") {
            return (
                <Link to={`/clients/${clientId}/billable_accounts/${accountId}/edit`} target={target}>
                    {accountName}
                </Link>
            )
        } else {
            return (
                <Link to={`/clients/${clientId}/supply_accounts/${accountId}/edit`} target={target}>
                    {accountName}
                </Link>
            )
        }
    }

    return formatLink()
}
