import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import ReportNav from "../features/reports/ReportNav"
import ReportPage from "../features/reports/ReportPage"

export default function ReportRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/dashboard`}>
                <ConsoleLayout
                    highlightTab="reports"
                    header={<ReportNav activeTab="dashboard" />}
                    body={<ReportPage view="dashboard" recordSelected={false} />}
                    sidebarType="reports" />
            </Route>
            <Route exact path={`${path}/generated-reports`}>
                <ConsoleLayout
                    highlightTab="reports"
                    header={<ReportNav activeTab="generated-reports" />}
                    body={<ReportPage view="generated-reports" recordSelected={false} />}
                    sidebarType="reports" />
            </Route>
            <Route path={`${path}/:reportId/new`}>
                <ConsoleLayout
                    highlightTab="reports"
                    header={<ReportNav activeTab="dashboard" />}
                    body={<ReportPage view="form" recordSelected={false} />}
                    sidebarType="reports" />
            </Route>
            <Route path={`${path}/:reportId/generated-reports/:generatedReportId/edit`}>
                <ConsoleLayout
                    highlightTab="reports"
                    header={<ReportNav activeTab="generated-dashboard" />}
                    body={<ReportPage view="form" recordSelected={true} />}
                    sidebarType="reports" />
            </Route>
        </Switch>
    )
}
