import { Col, Form, Row, Select } from "antd"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchAssignables } from "./reportsSlice"

export default function ReportAssignee({ form, record }) {
    const { reportId, clientId } = useParams()

    const dispatch = useDispatch()

    const [assignables, setAssignables] = useState([])

    useEffect(() => {
        if (record) {
            loadAssignables(record.assignable_type)
        }
    }, [record])

    const loadAssignables = async (type) => {
        form.resetFields(["assignable_id"])

        // for user, it just gets set to the current user (me) on the old site
        if (type === "User") {
            setAssignables([])
            return
        }

        const response = await dispatch(fetchAssignables(clientId, reportId, type))

        setAssignables(response.data)
    }

    const displayType = (type) => {
        switch (type) {
            case "User":
                return "Me"
            default:
                return type
        }
    }

    return (
        <Row gutter={24}>
            <Col span={isEmpty(assignables) ? 24 : 12}>
                <Form.Item label="Assignee Type" name="assignable_type" rules={[{ required: true }]}>
                    <Select placeholder="Select Assignee Type" onChange={loadAssignables} defaultActiveFirstOption={false}>
                        {["Client", "Division", "User"].map((o) => (
                            <Select.Option key={o} value={o}>
                                {displayType(o)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            {!isEmpty(assignables) && (
                <Col span={12}>
                    <Form.Item label="Assignee" name="assignable_id" rules={[{ required: true }]}>
                        <Select placeholder="Select Assignee">
                            {assignables.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
        </Row>
    )
}
