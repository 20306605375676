import React from "react"
import ReportAccountingBatches from "./fields/ReportAccountingBatches"
import ReportDivisions from "./fields/ReportDivisions"
import ReportFacilities from "./fields/ReportFacilities"
import ReportDateRange from "./fields/ReportDateRange"
import ReportVendors from "./fields/ReportVendors"
import ReportBillableAccounts from "./fields/ReportBillableAccounts"
import ReportSupplyAccounts from "./fields/ReportSupplyAccounts"
import ReportUtilityServices from "./fields/ReportUtilityServices"

export default function CostAndUsage() {
    // Client Batch
    // Date Range
    // Utility Service
    // Division
    // Facility
    // Utility Company (Vendors)
    // Utility Account (Billable Accounts)
    // Supply Account
    return (
        <div>
            <ReportAccountingBatches />
            <ReportDateRange />
            <ReportUtilityServices />
            <ReportDivisions />
            <ReportFacilities />
            <ReportVendors />
            <ReportBillableAccounts />
            <ReportSupplyAccounts />
        </div>
    )
}
