import { createSlice } from "@reduxjs/toolkit"
import client from "../../../lib/client"
import { clientSiteSurveysUrl, clientSiteSurveyUrl, siteSurveysUrl, siteSurveyUrl, siteSurveyPdfUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"

export const siteSurveysSlice = createSlice({
    name: "siteSurveys",
    initialState: {
        siteSurvey: {},
        siteSurveys: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setSiteSurvey: (state, action) => {
            state.siteSurvey = action.payload
        },
        setSiteSurveys: (state, action) => {
            state.siteSurveys = action.payload.site_surveys
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
})

export const { setSiteSurvey, setSiteSurveys, setPagination, setLoading } = siteSurveysSlice.actions

export function fetchSiteSurvey(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(siteSurveyUrl(id)).then((response) => {
            dispatch(setSiteSurvey(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchSiteSurveys(pagination, clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${clientSiteSurveysUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setSiteSurveys(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function filterSiteSurveys(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, siteSurveysUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setSiteSurveys(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function submitSiteSurvey(values, id, clientId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(siteSurveyUrl(id), { site_survey: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(clientSiteSurveysUrl(clientId), { site_survey: values })
            return response.data
        }
    }
}

export function generatePdf(id) {
    return async (dispatch, getState) => {
        client.get(`${siteSurveyPdfUrl(id)}?connection_id=${getState().app.uid}`)
    }
}

export const selectSiteSurvey = (state) => state.siteSurveys.siteSurvey
export const selectSiteSurveys = (state) => state.siteSurveys.siteSurveys
export const selectTotal = (state) => state.siteSurveys.total
export const selectPagination = (state) => state.siteSurveys.pagination
export const selectLoading = (state) => state.siteSurveys.loading

export default siteSurveysSlice.reducer
