import React, { useState } from "react"
import moment from "moment"
import AuditLogEvent from "./AuditLogEvent"
import { Button } from "antd"

export default function AuditLogDateGroup({ date, logs }) {
    const [open, setOpen] = useState(true)

    return (
        <div>
            <h1 style={{ marginBottom: "20px" }}>
                {moment(date).format("L")}
                <Button type="link" onClick={() => setOpen(!open)}>
                    {open ? "Collapse" : "Expand"}
                </Button>
            </h1>
            {open &&
                logs.map((version) => {
                    return <AuditLogEvent key={version.version.id} version={version.version} user={version.user} />
                })}
        </div>
    )
}
