import moment from "moment"

const currentDate = new Date()
currentDate.setHours(0, 0, 0) 

export const startDate = new Date();

export const endDate = new Date(currentDate);
endDate.setDate(currentDate.getDate() + 35);

export const dateRange = [startDate, endDate]

export const disabledDate = (current) => {
    return current &&  current.isAfter(endDate)
}

export function format_date(date) {
    return moment.isMoment(date) ? date.format("YYYY-MM-DD") : moment(date, "MM/DD/YYYY").format("YYYY-MM-DD")
}

export function formatDateRange(format = 'MM/DD/YYYY', dates = dateRange){
    let startDate = dates[0]
    let endDate = dates[1]

    const startDay = startDate.getDate()
    const startMonth = startDate.getMonth() + 1
    const startYear = startDate.getFullYear()

    const endDay = endDate.getDate()
    const endMonth = endDate.getMonth() + 1
    const endYear = endDate.getFullYear()

    if (format == "MM/DD/YYYY") {
        startDate = `${startMonth}/${startDay}/${startYear}`
        endDate = `${endMonth}/${endDay}/${endYear}`
    } else if (format == "YYYY-MM-DD") {
        startDate = `${startYear}-${startMonth}-${startDay}`
        endDate = `${endYear}-${endMonth}-${endDay}`
    }
    return [startDate, endDate]
}

export const formattedDateRange = formatDateRange()