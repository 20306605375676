import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../lib/client"

import {
    utilityServicesUrl,
    utilityServiceUrl,
    utilityServiceSelectOptionsUrl,
    utilityServiceUnitSelectOptionsUrl,
    utilityServiceUsageFactorSelectOptionsUrl,
    dynamicUrl,
    utilityServiceSearchUrl,
} from "../../lib/endpoints"

import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"

export const UtilityServicesSlice = createSlice({
    name: "utilityServices",
    initialState: {
        utilityService: {},
        utilityServices: [],
        utilityServiceSelectOptions: [],
        unitSelectOptions: [],
        usageFactorSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        utilityServiceSearch: [],
    },
    reducers: {
        setUtilityService: (state, action) => {
            state.utilityService = action.payload
        },
        setUtilityServices: (state, action) => {
            state.utilityServices = action.payload.utility_services
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUtilityServiceSelectOptions: (state, action) => {
            state.utilityServiceSelectOptions = action.payload
        },
        setUnitSelectOptions: (state, action) => {
            state.unitSelectOptions = action.payload
        },
        setUsageFactorSelectOptions: (state, action) => {
            state.usageFactorSelectOptions = action.payload
        },
        setUtilityServiceSearch: (state, action) => {
            state.utilityServiceSearch = action.payload
        },
    },
})

export const { setUtilityService, setUtilityServices, setPagination, setLoading, setUtilityServiceSelectOptions, setUnitSelectOptions, setUsageFactorSelectOptions, setUtilityServiceSearch } =
    UtilityServicesSlice.actions

export function fetchUtilityService(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(utilityServiceUrl(id)).then((response) => {
            dispatch(setUtilityService(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchUtilityServices(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setUtilityServices(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function searchUtilityServices(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${utilityServiceSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setUtilityServiceSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function submitUtilityService(values) {
    return async (dispatch, getState) => {
        const id = getState().utilityServices.utilityService.id
        if (id) {
            // patch - update
            const response = await client.patch(utilityServiceUrl(id), { utility_service: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(utilityServicesUrl, { utility_service: values })
            return response.data
        }
    }
}

export function fetchUtilityServiceSelectOptions() {
    return async (dispatch, getState) => {
        client.get(`${utilityServiceSelectOptionsUrl}`).then((response) => {
            dispatch(setUtilityServiceSelectOptions(response.data.utility_services))
        })
    }
}

export function fetchUnitSelectOptions(utilityServiceId) {
    return async (dispatch, getState) => {
        client.get(`${utilityServiceUnitSelectOptionsUrl(utilityServiceId)}`).then((response) => {
            dispatch(setUnitSelectOptions(response.data.units))
        })
    }
}

export function fetchUsageFactorSelectOptions(utilityServiceId) {
    return async (dispatch, getState) => {
        client.get(`${utilityServiceUsageFactorSelectOptionsUrl(utilityServiceId)}`).then((response) => {
            dispatch(setUsageFactorSelectOptions(response.data.usage_factors))
        })
    }
}

export function filterUtilityServices(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, utilityServicesUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setUtilityServices(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectUtilityService = (state) => state.utilityServices.utilityService
export const selectUtilityServices = (state) => state.utilityServices.utilityServices
export const selectTotal = (state) => state.utilityServices.total
export const selectPagination = (state) => state.utilityServices.pagination
export const selectLoading = (state) => state.utilityServices.loading
export const selectUtilityServiceSelectOptions = (state) => state.utilityServices.utilityServiceSelectOptions
export const selectUtilityServiceSearch = (state) => state.utilityServices.utilityServiceSearch
export const selectUnitSelectOptions = (state) => state.utilityServices.unitSelectOptions
export const selectUsageFactorSelectOptions = (state) => state.utilityServices.usageFactorSelectOptions

export default UtilityServicesSlice.reducer
