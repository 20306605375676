import React from "react"
import ReportDivisions from "./fields/ReportDivisions"
import ReportFacilities from "./fields/ReportFacilities"
import ReportDateRange from "./fields/ReportDateRange"
import ReportVendors from "./fields/ReportVendors"

export default function Inventory() {
    // Date Range
    // Division
    // Facility
    // Utility Company (Vendors)
    return (
        <div>
            <ReportDateRange required={true} />
            <ReportDivisions />
            <ReportFacilities />
            <ReportVendors />
        </div>
    )
}
