import React from "react"
import { Form, Row, Col, Input, DatePicker } from "antd"

export default function AdvancedSearchPayments() {
    return (
        <div>
            <h1 className="search-heading">Search Payments</h1>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Search by Payment Date" name="payment_date">
                        <DatePicker.RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Search by Payment ID" name="check_number">
                        <Input placeholder="Enter Payment ID" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Search by Amount Paid" name="amount_paid">
                        <Input placeholder="Enter Amount Paid" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}
