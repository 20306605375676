import React from "react"
import { useSelector } from "react-redux"
import { selectScopedByClient } from "../../stores/appSlice"
import ReportDashboard from "./ReportDashboard"
import ReportForm from "./ReportForm"
import GeneratedReports from './GeneratedReports'

export default function ReportPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
        case "dashboard":
            return renderDashboard()
        case "generated-reports":
            return renderGeneratedReports()
        case "form":
            return renderForm()
        }
    }

    const renderDashboard = () => {
        return <ReportDashboard />
    }

    const renderGeneratedReports = () => {
        return <GeneratedReports />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <ReportForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <ReportForm actionName="new" />
            </>
        )
    }

    return <>{renderView()}</>
}
