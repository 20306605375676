const api = process.env.REACT_APP_V5_API_URL
// const api = "http://localhost:3000/api/v5"
// const api = process.env.V5_API_URL
// const v5 = "http://localhost:3000/api/v5"
// TODO: Why dis breaky - ANSWER: env variables must start with REACT_APP_* to get passed in. lol
// const v5 = process.env.V5_URL
export default api

// dynamic route for fetching records
export const dynamicUrl = (path) => `${api}/${path}`

// login
export const loginUrl = `${api}/auth/sign_in`

// sign out
export const signOutUrl = `${api}/auth/sign_out`

// password reset
export const forgotPasswordUrl = `${api}/auth/password`
export const resetPasswordUrl = `${api}/auth/password`

// profile
export const userProfileUrl = `${api}/users/profile`

// rails direct upload path
export const directUploadUrl = `${api}/documents/direct_upload_url`

// clients
export const clientsUrl = `${api}/clients`
export const clientUrl = (id) => `${clientsUrl}/${id}`
export const clientUtilityServicesUrl = (id) => `${clientUrl(id)}/client_utility_services`
export const clientSelectOptionsUrl = `${clientsUrl}/select_options`
export const clientSelectOptionByIdUrl = `${clientsUrl}/select_option_by_id`
export const clientDropdownOptionsUrl = `${clientsUrl}/dropdown_options`
export const searchClientsUrl = `${clientsUrl}/search`

export const channelPartnersUrl = `${api}/channel_partners`
export const channelPartnerUrl = (id) => `${channelPartnersUrl}/${id}`
export const channelPartnerSelectOptionsUrl = `${channelPartnersUrl}/select_options`
export const channelPartnerSearchUrl = `${channelPartnersUrl}/search`
export const channelPartnersCsvUrl = `${channelPartnersUrl}/csv`

// search
export const advancedSearchAssociationsUrl = (clientId) => `${clientUrl(clientId)}/advanced_search_associations`
export const globalAdvancedSearchAssociationsUrl = `${api}/advanced_search_associations`
export const advancedSearchUrl = (clientId) => `${clientUrl(clientId)}/advanced_search`
export const globalAdvancedSearchUrl = `${api}/advanced_search`

// client vendor numbers
export const clientVendorNumbersUrl = (clientId) => `${clientUrl(clientId)}/client_vendor_numbers`
export const clientVendorNumberUrl = (clientId, id) => `${clientVendorNumbersUrl(clientId)}/${id}`
// export const

// divisions
export const divisionsUrl = `${api}/divisions`
export const divisionUrl = (id) => `${divisionsUrl}/${id}`
export const divisionSelectOptionsUrl = `${divisionsUrl}/select_options`
export const divisionSelectOptionByIdUrl = `${divisionsUrl}/select_option_by_id`
export const clientDivisionSelectOptionsUrl = (clientId) => `${api}/clients/${clientId}/divisions/select_options`
export const divisionSearchUrl = `${divisionsUrl}/search`
export const divisionsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/divisions`
export const clientDivisionsCsvUrl = (clientId) => `${api}/clients/${clientId}/divisions/export_csv`
export const divisionImportUrl = (clientId) => `${api}/clients/${clientId}/divisions/import`

// facilities
export const facilitiesUrl = `${api}/facilities`
export const facilityUrl = (id) => `${facilitiesUrl}/${id}`
export const facilitySearchUrl = `${facilitiesUrl}/search`
export const facilitiesScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/facilities`
export const clientFacilitiesSearchUrl = (clientId) => `${facilitiesScopedByClientUrl(clientId)}/search`
export const clientFacilitySelectOptionsUrl = (clientId) => `${api}/clients/${clientId}/facilities/select_options`
export const clientFacilitiesCsvUrl = (clientId) => `${api}/clients/${clientId}/facilities/export_csv`
// export const divisionSelectOptionsUrl = `${facilitiesUrl}/select_options`
export const facilityImportUrl = (clientId) => `${api}/clients/${clientId}/facilities/import`

// utility services
export const utilityServicesUrl = `${api}/utility_services`
export const utilityServiceSearchUrl = `${utilityServicesUrl}/search`
export const utilityServiceUrl = (id) => `${utilityServicesUrl}/${id}`
export const utilityServiceSelectOptionsUrl = `${utilityServicesUrl}/select_options`
export const utilityServiceUnitSelectOptionsUrl = (id) => `${utilityServiceUrl(id)}/usage_unit_options`
export const utilityServiceUsageFactorSelectOptionsUrl = (id) => `${utilityServiceUrl(id)}/usage_factor_options`

// billable accounts
export const billableAccountsUrl = `${api}/billable_accounts`
export const billableAccountUrl = (id) => `${billableAccountsUrl}/${id}`
export const billableAccountSearchUrl = `${billableAccountsUrl}/search`
export const billableAccountsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/billable_accounts`
export const clientBillableAccountSelectOptionsUrl = (clientId) => `${api}/clients/${clientId}/billable_accounts/select_options`
export const billableAccountSelectOptionByIdUrl = `${billableAccountsUrl}/select_option_by_id`
export const billFilteredBillableAccountSelectOptionsUrl = (clientId, searchTerm) => `${api}/clients/${clientId}/billable_accounts/account_number_search?search_term=${searchTerm}`
export const previousBillableAccountBillsUrl = (accountId, date, billId) => `${billableAccountsUrl}/${accountId}/previous_bills?date=${date}&bill_id=${billId}`
export const clientBillableAccountsCsvUrl = (clientId) => `${api}/clients/${clientId}/billable_accounts/export_csv`
export const billableAccountImportUrl = (clientId) => `${api}/clients/${clientId}/billable_accounts/import`

// supply accounts
export const supplyAccountsUrl = `${api}/supply_accounts`
export const supplyAccountSearchUrl = `${supplyAccountsUrl}/search`
export const supplyAccountUrl = (id) => `${supplyAccountsUrl}/${id}`
export const supplyAccountsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/supply_accounts`
export const clientSupplyAccountSelectOptionsUrl = (clientId) => `${api}/clients/${clientId}/supply_accounts/select_options`
export const supplyAccountSelectOptionByIdUrl = `${supplyAccountsUrl}/select_option_by_id`
export const billFilteredSupplyAccountSelectOptionsUrl = (clientId, searchTerm) => `${api}/clients/${clientId}/supply_accounts/account_number_search?search_term=${searchTerm}`
export const previousSupplyAccountBillsUrl = (accountId, date, billId) => `${supplyAccountsUrl}/${accountId}/previous_bills?date=${date}&bill_id=${billId}`
export const linkedServiceBillSelectOptionsUrl = (accountId) => `${supplyAccountsUrl}/${accountId}/linked_service_bill_options`
export const supplyAccountCsvUrl = (clientId) => `${api}/clients/${clientId}/supply_accounts/export_csv`
export const supplyAccountImportUrl = (clientId) => `${api}/clients/${clientId}/supply_accounts/import`

// group accounts
export const groupAccountsUrl = `${api}/group_accounts`
export const groupAccountsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/group_accounts`
export const groupAccountUrl = (id) => `${groupAccountsUrl}/${id}`
export const groupAccountBillsUrl = (id) => `${groupAccountUrl}/bills`
export const subaccountOptionsForGroupAccountUrl = (id) => `${groupAccountUrl(id)}/subaccount_options`
export const clientGroupAccountsSidebarUrl = (clientId) => `${groupAccountsScopedByClientUrl(clientId)}/sidebar_records`
export const groupSubaccountsScopedByClientUrl = (clientId, id) => `${api}/clients/${clientId}/group_accounts/${id}`
export const groupBillableAccountCsvUrl = (clientId) => `${api}/clients/${clientId}/group_accounts/export_csv`

// group supply accounts
export const groupSupplyAccountsUrl = `${api}/group_supply_accounts`
export const groupSupplyAccountsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/group_supply_accounts` ///groupSupplyAccountsScopedByClientUrl
export const groupSupplyAccountUrl = (id) => `${groupSupplyAccountsUrl}/${id}`
export const groupSupplyAccountBillsUrl = (id) => `${groupSupplyAccountUrl}/bills`
export const subaccountOptionsForGroupSupplyAccountUrl = (id) => `${groupSupplyAccountUrl(id)}/subaccount_options`
export const clientGroupSupplyAccountsSidebarUrl = (clientId) => `${groupSupplyAccountsScopedByClientUrl(clientId)}/sidebar_records`
export const groupSupplySubaccountsScopedByClientUrl = (clientId, id) => `${api}/clients/${clientId}/group_supply_accounts/${id}`

// group supply accounts
export const clientGroupSupplyAccounts = (clientId) => `${api}/clients/${clientId}/group_supply_accounts` /// same URL as above

// bills
export const billsUrl = `${api}/bills`
export const billUrl = (id) => `${billsUrl}/${id}`
export const billAccountUrl = (accountType, accountId) => `${billsUrl}/fetch_account?account_type=${accountType}&account_id=${accountId}`
export const billsScopedByClientUrl = (clientId) => `${api}/clients/${clientId}/bills`
export const billsCsvUrl = (clientId) => `${api}/clients/${clientId}/bills/export_csv`
export const billsImportUrl = (clientId) => `${api}/clients/${clientId}/bills/import`

// services
export const servicesUrl = `${api}/services`
export const serviceUrl = (id) => `${servicesUrl}/${id}`
export const clientServiceUrl = (id, clientId) => `${api}/clients/${clientId}/services/${id}`
export const clientServicesUrl = (clientId) => `${api}/clients/${clientId}/services`
export const billableAccountServiceUrl = (clientId, billableAccountId, id) => `${api}/clients/${clientId}/billable_accounts/${billableAccountId}/services/${id}`
export const billableAccountServicesUrl = (clientId, billableAccountId) => `${api}/clients/${clientId}/billable_accounts/${billableAccountId}/services`
export const supplyAccountServiceUrl = (clientId, supplyAccountId, id) => `${api}/clients/${clientId}/supply_accounts/${supplyAccountId}/services/${id}`
export const supplyAccountServicesUrl = (clientId, supplyAccountId) => `${api}/clients/${clientId}/supply_accounts/${supplyAccountId}/services`
export const clientServicesSidebar = (clientId) => `${api}/clients/${clientId}/services/sidebar_records`
export const serviceOptionsForBillableAccount = (clientId, utilityServiceId, accountId) =>
    `${api}/clients/${clientId}/services/billable_account_service_options/?utility_service_id=${utilityServiceId}${accountId ? `&account_id=${accountId}` : ""}`
export const serviceOptionsForSupplyAccount = (clientId, utilityServiceId, accountId) =>
    `${api}/clients/${clientId}/services/supply_account_service_options?utility_service_id=${utilityServiceId}${accountId ? `&account_id=${accountId}` : ""}`
export const lastFiveBillsForServiceUrl = (serviceId) => `${serviceUrl(serviceId)}/last_five_bills`

// service bills
export const serviceBillUrl = (id) => `${api}/service_bills/${id}`

// vendors
export const vendorsUrl = `${api}/vendors`
export const vendorUrl = (id) => `${vendorsUrl}/${id}`
export const vendorSelectOptionsUrl = () => `${vendorsUrl}/select_options`
export const vendorsCsvUrl = `${api}/vendors/export_csv`
export const vendorSearchUrl = `${vendorsUrl}/search`
export const vendorsImportUrl = `${api}/vendors/import`

// payment methods
export const paymentMethodsUrl = `${api}/payment_methods`
export const paymentMethodUrl = (id) => `${paymentMethodsUrl}/${id}`

// financials ------------------------------------------------------------------------------------------------------
// client batches
export const accountingBatchesUrl = (clientId) => `${api}/clients/${clientId}/accounting_batches`
export const accountingBatchUrl = (id) => `${api}/accounting_batches/${id}`
export const accountingBatchBillsUrl = (clientId, id) => `${accountingBatchUrl(id)}/bills?client_id=${clientId}`
export const accountingBatchNewBillsUrl = (clientId, id) => `${accountingBatchUrl(id)}/new_bills?client_id=${clientId}`
export const accountingBatchesSidebar = (clientId) => `${accountingBatchesUrl(clientId)}/sidebar_records`
export const accountingBatchBillsCsvUrl = (id) => `${accountingBatchUrl(id)}/export_bills_csv`
export const accountingBatchCsvUrl = (clientId) => `${api}/clients/${clientId}/accounting_batches/export_csv`
export const accountingBatchGenerateUploadFileUrl = (id) => `${accountingBatchUrl(id)}/generate_upload_file`
export const accountingBatchGenerateInvoicePdfUrl = (id) => `${accountingBatchUrl(id)}/generate_invoice_pdf`

// payment batches
export const paymentBatchesUrl = (clientId) => `${api}/clients/${clientId}/payment_batches`
export const paymentBatchSearchUrl = (clientId) => `${api}/clients/${clientId}/payment_batches/search`
export const paymentBatchUrl = (id) => `${api}/payment_batches/${id}`
export const paymentBatchBillsUrl = (clientId, id) => `${paymentBatchUrl(id)}/bills?client_id=${clientId}`
export const paymentBatchNewBillsUrl = (clientId, id, utilityPaymentId) => `${paymentBatchUrl(id)}/new_bills?client_id=${clientId}&vendor_payment_id=${utilityPaymentId}`
export const vendorPaymentSyncUrl = (clientId, id) => `${paymentBatchUrl(id)}/create_missing_vendor_payments?client_id=${clientId}`
export const paymentBatchesSidebar = (clientId) => `${paymentBatchesUrl(clientId)}/sidebar_records`
export const utilityPaymentsUrl = (paymentBatchId) => `${api}/payment_batches/${paymentBatchId}/vendor_payments`
export const utilityPaymentUrl = (id) => `${api}/vendor_payments/${id}`
export const utilityPaymentPdfUrl = (id) => `${api}/vendor_payments/${id}/generate_payments_pdf`
export const utilityPaymentBillsUrl = (id) => `${utilityPaymentUrl(id)}/bills`
export const paymentBatchBillsCsvUrl = (id) => `${paymentBatchUrl(id)}/export_bills_csv`
export const paymentBatchVoucherCsvUrl = (id) => `${paymentBatchUrl(id)}/voucher_csv`
export const PaymentBatchesCsvUrl = (clientId) => `${api}/clients/${clientId}/payment_batches/export_csv`

// ledger fields
export const ledgerFieldsUrl = (clientId) => `${api}/clients/${clientId}/ledger_fields` /// scoped by client
export const ledgerFieldUrl = (id, clientId) => `${ledgerFieldsUrl(clientId)}/${id}`
export const ledgerFieldsAllWithOrder = (clientId) => `${ledgerFieldsUrl}/all_with_order`
export const ledgerFieldsSidebar = (clientId) => `${ledgerFieldsUrl(clientId)}/sidebar_records`
export const ledgerFieldsImportUrl = (clientId) => `${ledgerFieldsUrl(clientId)}/import`
export const ledgerFieldsCsvUrl = (clientId) => `${ledgerFieldsUrl(clientId)}/export_csv`

// ledger accounts
export const ledgerAccountsUrl = (clientId) => `${api}/clients/${clientId}/ledger_accounts` /// scoped by client
export const ledgerAccountUrl = (id, clientId) => `${ledgerAccountsUrl(clientId)}/${id}`
export const ledgerAccountsSidebar = (clientId) => `${ledgerAccountsUrl(clientId)}/sidebar_records`
export const ledgerAccountSelectOptionsUrl = (clientId) => `${ledgerAccountsUrl(clientId)}/select_options`
export const ledgerAccountsImportUrl = (clientId) => `${ledgerAccountsUrl(clientId)}/import`
export const ledgerAccountsImportTemplateUrl = (clientId) => `${ledgerAccountsUrl(clientId)}/import_template`
export const ledgerAccountCsvUrl = (clientId) => `${ledgerAccountsUrl(clientId)}/export_csv`

// batch groups
export const batchGroupSelectOptionsUrl = (clientId) => `${api}/clients/${clientId}/batch_groups/select_options`

// !financials ------------------------------------------------------------------------------------------------------

// alerts ------------------------------------------------------------------------------------------------------
// billable accounts missing bills in past 30 days
export const clientBillableAccountsMissingBillsUrl = (clientId) => `${api}/clients/${clientId}/alerts/billable_accounts_missing_bills`
export const clientBillableAccountsMissingBillsCsvUrl = (clientId) => `${api}/clients/${clientId}/alerts/billable_accounts_missing_bills_export_csv`

// supply accounts missing bills in past 30 days
export const clientSupplyAccountsMissingBillsUrl = (clientId) => `${api}/clients/${clientId}/alerts/supply_accounts_missing_bills`
export const clientSupplyAccountsMissingBillsCsvUrl = (clientId) => `${api}/clients/${clientId}/alerts/supply_accounts_missing_bills_export_csv`

// billable accounts with three consecutive estimated reads
export const clientBillableAccountsConsecutiveEstimationsUrl = (clientId) => `${api}/clients/${clientId}/alerts/billable_accounts_consecutive_estimations`
export const clientBillableAccountsConsecutiveEstimationsCsvUrl = (clientId) => `${api}/clients/${clientId}/alerts/billable_accounts_consecutive_estimations_export_csv`

// billable account bills 30+ days past due
export const clientPastDueBillsUrl = (clientId) => `${api}/clients/${clientId}/alerts/past_due_bills`
export const clientPastDueBillsCsvUrl = (clientId) => `${api}/clients/${clientId}/alerts/past_due_bills_export_csv`
// !alerts -----------------------------------------------------------------------------------------------------

// ancillary services ------------------------------------------------------------------------------------------------------
// site surveys
export const siteSurveysUrl = `${api}/site_surveys`
export const clientSiteSurveysUrl = (clientId) => `${api}/clients/${clientId}/site_surveys`
export const siteSurveyUrl = (id) => `${siteSurveysUrl}/${id}`
export const clientSiteSurveyUrl = (id, clientId) => `${clientSiteSurveysUrl(clientId)}/${id}`
export const siteSurveyPdfUrl = (id) => `${siteSurveyUrl(id)}/pdf`

// settings ---------------------------------------------------------------------------------------------------------
export const auditLogUrl = `${api}/audit_record`
export const exportAuditLogUrl = `${auditLogUrl}/export`

// facility types
export const facilityTypesUrl = `${api}/facility_types`
export const facilityTypeUrl = (id) => `${facilityTypesUrl}/${id}`
export const facilityTypeSelectOptionsUrl = `${facilityTypesUrl}/select_options`

//  units
export const unitsUrl = `${api}/units`
export const unitUrl = (id) => `${unitsUrl}/${id}`
export const unitSelectOptionsUrl = `${unitsUrl}/select_options`

// production units
export const productionUnitsUrl = `${api}/production_units`
export const productionUnitUrl = (id) => `${productionUnitsUrl}/${id}`
export const productionUnitSelectOptionsUrl = `${productionUnitsUrl}/select_options`

// cost parameters
export const costParametersUrl = `${api}/cost_parameters`
export const costParameterUrl = (id) => `${costParametersUrl}/${id}`
export const costParameterSelectOptionsUrl = `${costParametersUrl}/select_options`

// global batch
export const globalBatchUrl = `${api}/global_batches`
export const globalPaymentBatchUrl = `${globalBatchUrl}/global_payment_batch`
export const globalPaymentBatchFilterUrl = `${globalBatchUrl}/global_payment_batch_filter`
export const globalPaymentBatchUpdateUrl = `${globalBatchUrl}/update_multiple`
export const globalPaymentBatchBillsUrl = (id) => `${globalBatchUrl}/${id}/bills`
export const createBatchesUrl = `${globalBatchUrl}/create_with_bills`
export const unbatchBatchesUrl = `${globalBatchUrl}/unbatch_bills`
export const updateParamsUrl = `${globalBatchUrl}/update_params`
export const globalPaymentBatchInfo = (id) => `${globalBatchUrl}/${id}/payment_batch_info`
export const todaysPaymentBatchesUrl = `${globalBatchUrl}/todays_payment_batches`
export const todaysPaymentBatchesForTypeUrl = `${globalBatchUrl}/todays_payment_batches_for_type`
export const globalBatchVoucherCsvUrl = `${globalBatchUrl}/daily_global_voucher_csv`
export const globalBatchCheckVendorPaymentsUrl = `${globalBatchUrl}/check_todays_bills_for_vendor_payments`
export const globalBatchCheckPaidBatchesUrl = `${globalBatchUrl}/check_todays_bills_for_paid_batches`
export const exportGlobalBatchAvidUrl = `${globalBatchUrl}/global_batch_export_avid_csv`
export const exportGlobalBatchOthersUrl = `${globalBatchUrl}/global_batch_export_others_csv`

export const globalBatchBankValueUrl = `${api}/global_batch_bank_values`
export const globalBatchBankValueUpdateUrl = (id) => `${globalBatchBankValueUrl}/${id}`
export const globalPaymentBatchNewBillsUrl = (id, clientId) => `${paymentBatchUrl(id)}/new_bills?client_id=${clientId}`



// !settings ---------------------------------------------------------------------------------------------------------

// users
export const usersUrl = `${api}/users`
export const clientUsersUrl = (clientId) => `${api}/clients/${clientId}/users`
export const userUrl = (userId) => `${usersUrl}/${userId}`
export const clientUserUrl = (userId, clientId) => `${clientUsersUrl(clientId)}/${userId}`
export const activeUserUrl = `${api}/users/active_user`
export const userSearchUrl = `${usersUrl}/search`
export const usersCsvUrl = `${api}/users/export_csv`
export const resendConfirmationEmailUrl = (userId) => `${userUrl(userId)}/resend_confirmation_email`

// user roles
export const userRolesUrl = (userId) => `${userUrl(userId)}/user_roles`
export const clientUserRolesUrl = (userId, clientId) => `${clientUserUrl(userId, clientId)}/user_roles`
export const userRoleUrl = (userId, roleId) => `${userRolesUrl(userId)}/${roleId}`

// roles
export const rolesUrl = `${api}/roles`

// documents
export const documentsUrl = (type, id) => `${api}/documents?type=${type}&id=${id}`
export const downloadDocumentUrl = (documentId) => `${api}/documents/${documentId}/download`
export const documentUrl = (documentId) => `${api}/documents/${documentId}`

// entry fields
export const entryFieldsUrl = `${api}/entry_fields`
export const entryFieldSelectOptionsUrl = `${entryFieldsUrl}/select_options`

// reports
export const clientReportsUrl = (clientId) => `${clientUrl(clientId)}/reports`
export const clientReportUrl = (clientId, reportId) => `${clientReportsUrl(clientId)}/${reportId}`
export const clientReportGlobalDataServiceFieldsUrl = (clientId, serviceId) => `${clientReportsUrl(clientId)}/global_data_service_fields?utility_service_id=${serviceId}`
export const generatedReportsUrl = (clientId, reportId) => `${clientReportUrl(clientId, reportId)}/generated_reports`
export const generatedReportUrl = (clientId, reportId, generatedReportId) => `${generatedReportsUrl(clientId, reportId)}/${generatedReportId}`
export const generatedReportsTableUrl = (clientId) => `${clientReportsUrl(clientId)}/generated_reports`
export const generatedReportAssignablesUrl = (clientId, reportId, type) => `${generatedReportsUrl(clientId, reportId)}/assignables?type=${type}`

// bill document repository
export const clientBillDocumentRepositoryUrl = (clientId) => `${clientUrl(clientId)}/bill_documents`
export const clientBillDocumentUrl = (clientId, id) => `${clientBillDocumentRepositoryUrl(clientId)}/${id}`
