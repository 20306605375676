// src/hooks/useConfirmationModal.js

import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

const { confirm } = Modal;

const useConfirmBankValueChange = () => {
  const dispatch = useDispatch();

  const showConfirm = (onAccept, onCancel, props) => {
    const { id, currVal, newVal, attempts, isHandlingLock, lock, from } = props

    function getInfo(){
        switch(from){
            case 'input':
                if(currVal == 0){
                    return {
                        title: "Set Bank Value",
                        content: `Clicking OK will lock the bank value at it's current value of: $${newVal.toLocaleString()}`
                        // . After locking, there will be 3 opportunities today to change this value throughout this day. If all 3 attempts are used, the value will no longer be changeable for the remainder of the day.`,
                    }
                }
                else{
                    return {
                        title: "Bank Value Change",
                        content: `Change bank value to $${newVal.toLocaleString()}? ${attempts == 1 ? "This is the last change you can make today!" : ''}`
                    }
                }
            case 'button':
                if(isHandlingLock){
                    return {
                        title: 'Edit bank value?',
                        content: `There are ${attempts}/3 opportunities to edit the bank value for today.`
                    }
                }
                else{
                    return {
                        title: "Bank Value Change",
                        content: `Change bank value to $${newVal.toLocaleString()}? ${attempts == 1 ? "This is the last change you can make today!" : ''}`,
                    }
                }
            default:
                return { title: "Error", content: "There was an error." }
        }
    }
    
    confirm({
        title: getInfo().title,
        content: getInfo().content,
        onOk() {
            dispatch(onAccept(id, currVal, newVal, attempts, isHandlingLock, lock))
        },
        onCancel() {
            // onCancel()
            // console.log('Cancel');
        },
    })
  };

  return showConfirm;
};

export default useConfirmBankValueChange;
