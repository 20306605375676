import React from "react"
import { Form, Input } from "antd"
import { CloseOutlined } from "@ant-design/icons"

function SiteSurveysGearBoxesFormPiece(props) {
    return (
        <div className={"site-surveys-gear-boxes-form-piece"} key={props.key}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />
            <Form.Item {...props} name={[props.name, "name"]} fieldKey={[props.fieldKey, "name"]} label={"Name"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "ratio"]} fieldKey={[props.fieldKey, "ratio"]} label={"Ratio"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "size"]} fieldKey={[props.fieldKey, "size"]} label={"Size"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "manufacturer"]} fieldKey={[props.fieldKey, "manufacturer"]} label={"Manufacturer"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "year"]} fieldKey={[props.fieldKey, "year"]} label={"Year"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "notes"]} fieldKey={[props.fieldKey, "notes"]} label={"Notes"}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysGearBoxesFormPiece
