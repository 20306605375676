import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, Popconfirm, notification, Dropdown, Menu, Button, Modal, Checkbox, Radio  } from "antd"
import {
    UploadOutlined,
    FilterOutlined,
    FileTextOutlined,
    EditOutlined,
    DeleteOutlined,
    DownOutlined,
    FolderOutlined,
    RetweetOutlined,
    CheckOutlined,
    CloseOutlined,
    CloseSquareOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons"
import moment from "moment"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"
import Role from "../../userRoles/Role"
import { formatCurrency } from "../../../lib/utilities"
import {
    checkForPaidBatches,
    checkForVendorPayments,
    deletePaymentBatch,
    fetchGlobalBatches,
    fetchGlobalBatchesForType,
    generateCsv,
    generateGlobalVoucherCsv,
    selectAllBatchesPagination,
    selectBankValue,
    selectCheckedBatches,
    selectGlobalBatches,
    selectGlobalBatchesCount,
    selectLoading,
    selectSumOfFinalized,
    selectTodaysBatchesPagination,
    setCheckedBatches,
    unbatchBatches,
    updatePaidProcess,
    updateTempPayType,
} from "../globalBatchSlice"
import { generateVoucherCsv } from "../../financials/paymentBatches/paymentBatchesSlice"
import useExportConfirmationModal from "../../alertsPanel/ShowConfirmGlobalExport"

export default function GlobalBatchView({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    // console.log(useParams())
    const isTodaysBatches = url.indexOf("todays_batches") > 0
    const params = useParams()
    const usesType = params.type ? params.type : false

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const globalBatches = useSelector(selectGlobalBatches)
    const total = useSelector(selectGlobalBatchesCount)
    const pagination = useSelector(isTodaysBatches ? selectTodaysBatchesPagination : selectAllBatchesPagination)
    const loading = useSelector(selectLoading)
    const checkedBatches = useSelector(selectCheckedBatches)
    const bankValue = useSelector(selectBankValue)
    const sumOfFinalized = useSelector(selectSumOfFinalized)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    const [openModal, setOpenModal] = useState(false)
    const [openACHModal, setOpenACHModal] = useState(false)
    const [batchForExport, setBatchForExport] = useState(null)
    const [allBatchesSelected, setAllBatchesSelected] = useState(false)
    const [selectedACHExport, setSelectedACHExport] = useState('Standard')
    /////*** useState end ***/////

    /////*** useEffect start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialGlobalBatches()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line

    useEffect(() => {
        dispatch(setCheckedBatches([]))
        handleFetchInitialGlobalBatches()
    }, [url])
    /////*** useEffect end ***/////

    const handleFetchInitialGlobalBatches = () => {
        if (!usesType) {
            dispatch(fetchGlobalBatches(pagination, isTodaysBatches))
        } else {
            dispatch(fetchGlobalBatchesForType(pagination, usesType))
        }
    }

    const handleTableChange = (newPagination, sorter) => {
        if (!usesType) {
            dispatch(fetchGlobalBatches(newPagination, isTodaysBatches))
        } else {
            dispatch(fetchGlobalBatchesForType(newPagination, usesType))
        }
    }

    const handleSortGlobalBatchesChange = (column) => () => {
        // console.log(column)
        // let newDirection = sort.direction === "asc" ? "desc" : "asc"
        // let newSort = {
        //     column: column,
        //     direction: newDirection,
        // }
        // setSort(newSort)
        // handleFilterPaymentBatches(null, newSort)
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialGlobalBatches()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderColumnHeaderTitle = (title, columnClass) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortGlobalBatchesChange} sort={sort} columnClass={columnClass} />
    }

    const voucherCsv = async (paymentBatchId) => {
        notification.open({
            message: "Vendor Batch Voucher CSV started",
            duration: 0.8,
        })

        dispatch(generateVoucherCsv(paymentBatchId))
    }

    const showConfirm = useConfirmationModal()
    const handleDeleteClick = (id) => {
        showConfirm(deletePaymentBatch, id)
    }

    const showExportConfirm = useExportConfirmationModal()
    const handleExportBatches = async () => {
        const response = await dispatch(checkForVendorPayments())
        const checkPaidBatches = await dispatch(checkForPaidBatches())

        if (response.data.count != 0) {
            notification.open({
                type: "error",
                message: "One or more bills do not have a vendor payment",
                duration: 2,
                description: "All bills in all batches must have a vendor payment.",
            })
        } else if (checkPaidBatches.data.count == 0) {
            notification.open({
                type: "error",
                message: "No paid batches.",
                duration: 2,
                description: "There are no batches which are paid that have not already been finalized today.",
            })
        } else {
            showExportConfirm(globalVoucherCsv)
        }
    }

    const globalVoucherCsv = async () => {
        // if(!checkForPaidBatch(checkedBatches)){
        //     notification.error({
        //         message: "Unpaid batches",
        //         description: 'All selected batches must be paid before finalizing them with the export.',
        //         duration: 2,
        //     })
        //     return
        // }
        notification.open({
            message: "Quickbook export has started",
            duration: 0.8,
        })

        const batchIds = checkedBatches.map((batch, i) => {
            return batch.global_batch_id
        })

        await dispatch(generateGlobalVoucherCsv())
        await dispatch(updatePaidProcess(batchIds, usesType, true, true))
    }

    const handleModal = (record = null, open = false) => {
        setOpenModal(open)
        setBatchForExport(record)
    }

    const handleACHModal = (open = false) => {
        setOpenACHModal(open)
        setSelectedACHExport('Standard')
    }

    const handleExportAs = async (type, array = false, achType = false) => {
        const response = await dispatch(checkForVendorPayments(type))
        if (response.data.count != 0) {
            notification.open({
                type: "error",
                message: "One or more bills do not have a vendor payment",
                duration: 2,
                description: "All bills in all batches must have a vendor payment.",
            })
        } else {
            if(type == 'ACH'){
                exportACH(achType)
            }
            else{
                exportAs(type, array)
            }
            // showExportConfirm(
            //     exportAs(type, array),
            //     `Create export for ${type}?`,
            //     `Pressing OK will create an export file with the selected batches and remove them from this table. These batches will still appear in "Today's Batches" for the quickbook export.`
            // )
        }
    }

    const exportAs = async (type, array = false) => {
        const batchIds = []
        if (array && globalBatches.length == 0) {
            notification.error({
                message: "Missing batches",
                description: "Must have one or more batches to export",
                duration: 1.5,
            })
            return
        }
        let bills
        if (!array) {
            bills = batchForExport
        } else {
            const arr = typeof array == "object" ? array : globalBatches
            bills = arr.map((batch, i) => {
                batchIds.push(batch.global_batch_id)
                return batch.bills
            })
            bills = bills.flat()
        }
        const billIds = bills.map((bill, i) => {
            return bill.id
        })

        if (type != "OP" && type != "NP") {
            notification.open({
                message: `${type} export has started`,
                duration: 1,
            })
        }

        let res = ""
        // if(!array){
        //     switch (type){
        //         case 'Default':
        //             res = await dispatch(generateCsv(billIds, batchForExport.bills_payment_method, 'global_batch_default_export'))
        //             // console.log(res)
        //             return
        //         case 'Quick Books':
        //             res = await dispatch(generateCsv(billIds, 'quick_books', 'global_batch_qbo_export'))
        //             // console.log(res)
        //             return
        //         case 'OP/Check':
        //             res = await dispatch(generateCsv(billIds, 'OP', 'global_batch_op_check_export'))
        //             // console.log(res)
        //             return
        //         default:
        //             return { error: "Something went wrong" }
        //     }
        // }
        // else{
        const { temp_pay_method, bills_payment_method } = globalBatches[0]
        const paymentMethod = temp_pay_method ? temp_pay_method : bills_payment_method
        if (usesType != "OP" && usesType != "NP") await dispatch(generateCsv(billIds, paymentMethod))
        await dispatch(updatePaidProcess(batchIds, usesType, true, usesType == "NP" ? true : false))
        await dispatch(setCheckedBatches([]))
        // }
    }

    function getBatchInfoFromArr(arr) {
        const batchIds = []
        let bills = arr.map((batch, i) => {
            batchIds.push(batch.global_batch_id)
            return batch.bills
        })

        bills = bills.flat()
        const billIds = bills.map((bill, i) => {
            return bill.id
        })

        return { batchIds: batchIds, billIds: billIds, bills: bills }
    }

    const exportOP = async () => {
        const billIds = getBatchInfoFromArr(checkedBatches).billIds

        notification.open({
            message: `OP export has started`,
            duration: 1,
        })

        await dispatch(generateCsv(billIds, "OP"))
        await dispatch(setCheckedBatches([]))
    }

    const exportACH = async (type = "Standard") => {
        const { billIds, batchIds } = getBatchInfoFromArr(checkedBatches)

        notification.open({
            message: `ACH export has started`,
            duration: 1,
        })

        await dispatch(updatePaidProcess(batchIds, usesType, true))
        await dispatch(generateCsv(billIds, "ACH"))// Need to make function for the different ACH types
        await dispatch(setCheckedBatches([]))
    }

    // Made this function just in case I there's a need to export all the batches separately instead of as a group
    const exportAsIndividual = async () => {
        const batchIds = []
        notification.open({
            message: `Export creation has started`,
            duration: 1,
        })
        for (let i = 0; i < checkedBatches.length; i++) {
            const { id } = checkedBatches[i]
            batchIds.push(id)
            await dispatch(generateVoucherCsv(id))
        }
        notification.success({
            message: `Export creation has finished`,
            duration: 1,
        })
    }

    const updateChecked = (e, batch, array = false) => {
        if (e.target.checked) {
            dispatch(setCheckedBatches(checkedBatches.concat(!array ? batch : array)))
        } else {
            if (!array) {
                const { id } = batch
                dispatch(setCheckedBatches(checkedBatches.filter((batch) => batch.id !== id)))
            } else {
                const idArr = []
                for (let i = 0; i < array.length; i++) {
                    const id = array[i].id
                    idArr.push(id)
                }
                dispatch(setCheckedBatches(checkedBatches.filter((batch) => !idArr.includes(batch.id))))
            }
        }
    }

    const checkBatchId = (checkId) => {
        for (let i = 0; i < checkedBatches.length; i++) {
            const id = checkedBatches[i].id
            if (id == checkId) return true
        }
        return false
    }

    function checkForAllBatchesSelected() {
        if (globalBatches.length == 0) return false
        for (let i = 0; i < globalBatches.length; i++) {
            if (globalBatches[i].is_paid) continue
            if (!checkBatchId(globalBatches[i].id)) return false
        }
        return true
    }

    // function checkForPaidBatch(checkArr = false) {
    //     const arr = checkArr ? checkArr : globalBatches
    //     if (checkArr.length == 0) return false
    //     for (let i = 0; i < checkArr.length; i++) {
    //         if (!checkArr[i].is_paid) return false
    //     }
    //     return true
    // }

    async function handleUnbatch() {
        const ids = checkedBatches.map((batch, i) => {
            return batch.id
        })
        dispatch(unbatchBatches(ids))
        await dispatch(setCheckedBatches([]))
    }

    async function handleUpdatePayMethod(type) {
        const ids = checkedBatches.map((batch, i) => {
            return batch.global_batch_id
        })
        dispatch(updateTempPayType(ids, type, usesType))
        dispatch(setCheckedBatches([]))
    }

    function checkIfSelectionGoesToNP() {
        for (let i = 0; i < checkedBatches.length; i++) {
            const batchTotal = parseFloat(checkedBatches[i].batch_total)
            const tempMethod = checkedBatches[i].temp_pay_method
            if (batchTotal > 1 || (tempMethod == "NP" && usesType != "NP")) return false
        }
        return true
    }

    return (
        <div className="muc-table card">
            <Modal
                onCancel={() => handleACHModal()}
                open={openACHModal}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            handleExportAs(usesType, checkedBatches, selectedACHExport)
                            handleACHModal()
                            // exportAsIndividual()
                        }}>
                        Export
                    </Button>,
                    <Button type="back" onClick={() => handleACHModal()}>
                        Cancel
                    </Button>,
                ]}>
                <h4>Select export type:</h4>
                <br/>
                <Radio.Group
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        fontWeight: 'normal',
                        fontSize: 10
                    }}
                    onChange={(e) => setSelectedACHExport(e.target.value)}
                    value={selectedACHExport}
                    options={[
                        {
                            value: "Standard",
                            label: "Standard",
                        },
                        {
                            value: "CDD",
                            label: "CDD",
                        },
                        {
                            value: "CDD+",
                            label: "CDD+",
                        },
                        {
                            value: "NACHA",
                            label: "NACHA",
                        },
                        {
                            value: "NACHA+",
                            label: "NACHA+",
                        },
                    ]}
                />
            </Modal>
            <Modal
                onCancel={() => handleModal()}
                open={openModal}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            handleModal()
                            if (usesType == "ACH") {
                                setOpenACHModal(true)
                            } else {
                                handleExportAs(usesType, checkedBatches)
                            }
                            // exportAsIndividual()
                        }}>
                        OK
                    </Button>,
                    <Button
                        type="back"
                        onClick={() => {
                            handleModal()
                            // handleExportAs(usesType)
                        }}>
                        Cancel
                    </Button>,
                ]}>
                {usesType != "OP" && usesType != "NP" && (
                    <>
                        <h4>Create export for {usesType}?</h4>
                        <p>
                            Pressing OK will create an export file with all the bills in each of the selected batches and then remove these batches from this table. These batches will still appear in
                            "Today's Batches" for the quickbook export.
                        </p>
                    </>
                )}
                {usesType == "OP" && (
                    <>
                        <h4>Make {usesType} payment?</h4>
                        <p>
                            Pressing OK will create a payment for each of the selected batches and then remove these batches from this table. These batches will still appear in "Today's Batches" for
                            the quickbook export.
                        </p>
                    </>
                )}
                {usesType == "NP" && (
                    <>
                        <h4>Finalize selected batches?</h4>
                        <p>
                            Pressing OK will finalize each of the selected batches and then remove these batches from this table. These batches will NOT appear in "Today's Batches" for the quickbook
                            export.
                        </p>
                    </>
                )}
            </Modal>
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div>
                            <div className="badged-header">
                                <h2>{isTodaysBatches ? `Today's ${usesType ? usesType : ""} Batches` : "Global Batches"}</h2>
                                <div className="badge">{total}</div>
                            </div>
                            <div className="badged-header" style={{ gap: 10, color: "white" }}>
                                <h4>Bank Value After Finalized Batch Total: </h4>
                                <div className="global-batch-filter-label-text">${(bankValue.value - sumOfFinalized).toLocaleString()}</div>
                            </div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                {/* <Role action="create" model="payment_batch">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role> */}

                                {/* <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a> */}

                                {isTodaysBatches && checkedBatches.length > 0 && (
                                    <>
                                        <a href="#" onClick={() => handleUnbatch()}>
                                            <CloseSquareOutlined />
                                            Unbatch {checkedBatches.length == 1 ? "selection" : "selections"}
                                        </a>
                                        {checkIfSelectionGoesToNP() && (
                                            <a href="#" onClick={() => handleUpdatePayMethod(usesType == "NP" ? null : "NP")}>
                                                {usesType == "NP" ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                                                Move {checkedBatches.length == 1 ? "selection" : "selections"} {usesType == "NP" ? "back to default" : "to NP"}
                                            </a>
                                        )}
                                        <a href="#" onClick={() => handleUpdatePayMethod(usesType == "OP" ? null : "OP")}>
                                            {usesType == "OP" ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                                            Move {checkedBatches.length == 1 ? "selection" : "selections"} {usesType == "OP" ? "back to default" : "to OP"}
                                        </a>
                                        {usesType && (
                                            <>
                                                {usesType != "ACH" && usesType != "OP" && usesType != "NP" && (
                                                    <button disabled={loading} onClick={() => setOpenModal(true)}>
                                                        <UploadOutlined style={{ paddingRight: "10px" }} />
                                                        Export as {usesType}
                                                    </button>
                                                )}
                                                {usesType == "ACH" && (
                                                    <>
                                                        <button disabled={loading} onClick={() => setOpenModal(true)}>
                                                            <UploadOutlined style={{ paddingRight: "10px" }} />
                                                            Export {usesType} batch{checkedBatches.length > 1 && "es"} as...
                                                        </button>
                                                    </>
                                                )}
                                                {usesType == "OP" && (
                                                    <>
                                                        <button disabled={loading} onClick={() => exportOP()}>
                                                            <UploadOutlined style={{ paddingRight: "10px" }} />
                                                            Export {usesType} batch{checkedBatches.length > 1 && "es"}
                                                        </button>
                                                        <button disabled={loading} onClick={() => setOpenModal(true)}>
                                                            <UploadOutlined style={{ paddingRight: "10px" }} />
                                                            Pay {usesType} batch{checkedBatches.length > 1 && "es"}
                                                        </button>
                                                    </>
                                                )}
                                                {usesType == "NP" && (
                                                    <button disabled={loading} onClick={() => setOpenModal(true)}>
                                                        <UploadOutlined style={{ paddingRight: "10px" }} />
                                                        Finalize {usesType} batch{checkedBatches.length > 1 && "es"}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {isTodaysBatches && !usesType && checkedBatches.length == 0 && total > 0 && (
                                    <button disabled={loading} onClick={() => handleExportBatches()}>
                                        <UploadOutlined style={{ paddingRight: "10px" }} />
                                        Export paid batches
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={globalBatches} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                key="in"
                                dataIndex="in"
                                title={
                                    <Checkbox
                                        checked={checkForAllBatchesSelected()}
                                        onChange={(e) => {
                                            const updateArr = []
                                            for (let i = 0; i < globalBatches.length; i++) {
                                                const currBatch = globalBatches[i]
                                                const check = e.target.checked ? !checkBatchId(currBatch.id) : checkBatchId(currBatch.id)
                                                if (check && !currBatch.is_paid) {
                                                    updateArr.push(currBatch)
                                                }
                                            }
                                            updateChecked(e, false, updateArr)
                                        }}
                                    />
                                }
                                render={(text, record, index) => {
                                    if (!record.is_paid) {
                                        return <Checkbox disabled={record.is_paid} defaultChecked={false} checked={checkBatchId(record.id)} onChange={(e) => updateChecked(e, record)} />
                                    }
                                    // else return <Checkbox disabled={true} defaultChecked={true}/>
                                }}
                            />
                            {!usesType && (
                                <Table.Column
                                    title="Paid"
                                    key="is_paid"
                                    dataIndex="is_paid"
                                    render={(text, record, index) => {
                                        if (text) {
                                            return <CheckOutlined />
                                        } else {
                                            return <CloseOutlined />
                                        }
                                    }}
                                />
                            )}
                            <Table.Column
                                title={"Closed"}
                                key="closed"
                                dataIndex="closed"
                                render={(text, record) => <>{record.closed ? "Closed" : ""}</>}
                                // filterDropdown={({ visible }) => (
                                //     <ColumnSearchFilter
                                //         handleFetchInitial={handleFetchInitialGlobalBatches}
                                //         filterAction={filterPaymentBatches}
                                //         filterColumn={"batch_date"}
                                //         filterType={"batch_date"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //         setSort={setSort}
                                //         visible={visible}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("batch_date_cont")}
                            />
                            <Table.Column title="Payer" key="payer_name" dataIndex="payer_name" />
                            <Table.Column title="Vendors" key="vendor_names" dataIndex="vendor_names" />
                            <Table.Column
                                title="Pay Type"
                                key="bills_payment_method"
                                dataIndex="bills_payment_method"
                                render={(text, record, index) => {
                                    if (record.temp_pay_method) return `${record.temp_pay_method} (${text})`
                                    else return text
                                }}
                            />
                            <Table.Column
                                title={"Batch Date"}
                                key="batch_date"
                                dataIndex="batch_date"
                                // filterDropdown={({ visible }) => (
                                //     <ColumnDateFilter
                                //         handleFetchInitial={handleFetchInitialGlobalBatches}
                                //         filterAction={filterPaymentBatches}
                                //         filterColumn={"batch_date"}
                                //         filterType={"batch_date"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //         setSort={setSort}
                                //         visible={visible}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("batch_date_lteq" || "batch_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            {/* TODO: Make money formatting a library function */}
                            <Table.Column
                                title={"Bills Due Date"}
                                key="bills_due_date"
                                dataIndex="bills_due_date"
                                // filterDropdown={({ visible }) => (
                                //     <ColumnDateFilter
                                //         handleFetchInitial={handleFetchInitialGlobalBatches}
                                //         filterAction={filterPaymentBatches}
                                //         filterColumn={"bills_due_date"}
                                //         filterType={"bills_due_date"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //         setSort={setSort}
                                //         visible={visible}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("bills_due_date_lteq" || "bills_due_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("batch_total", "right_align_column")}
                                key="batch_total"
                                dataIndex="batch_total"
                                align="right"
                                render={(text, record, index) => formatCurrency(text)}
                            />
                            <Table.Column title="Notes" key="notes" dataIndex="notes" />
                            <Table.Column
                                title=""
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    {/* <Role action="read" model="payment_batch">
                                                            <Menu.Item key="6" icon={<UploadOutlined />} onClick={() => handleModal(record, true)}>
                                                                <Link to={`/global_batch/todays_batches`}>Export As...</Link>
                                                            </Menu.Item>
                                                        </Role> */}
                                                    {/* <Role action="read" model="payment_batch">
                                                            <Menu.Item key="1" icon={<FolderOutlined />}>
                                                                <Link to={`/clients/${record.client_id}/payment_batches/${record.id}/vendor_payments`}>Vendor Payments</Link>
                                                            </Menu.Item>
                                                        </Role> */}

                                                    <Role action="update" model="payment_batch">
                                                        <Menu.Item key="2" icon={<FolderOutlined />}>
                                                            <Link to={`/global_batch/view/${record.global_batch_id}/bills`}>Bills</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    {/* <Role action="read" model="payment_batch">
                                                            <Menu.Item key="3" icon={<FileTextOutlined />} onClick={() => voucherCsv(record.id)}>
                                                                Voucher CSV
                                                            </Menu.Item>
                                                        </Role> */}

                                                    <Role action="update" model="payment_batch">
                                                        <Menu.Item key="4" icon={<EditOutlined />}>
                                                            <Link to={`/global_batch/${record.global_batch_id}/edit`}>Edit Batch</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    {/* <Role action="destroy" model="payment_batch">
                                                            <Menu.Item
                                                                key="5"
                                                                icon={<DeleteOutlined />}
                                                                onClick={() => handleDeleteClick(record.id)}
                                                                disabled={record.has_been_closed || record.has_bills}>
                                                                Delete Global Batch
                                                            </Menu.Item>
                                                        </Role> */}
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
