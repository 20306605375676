import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient } from "../../stores/appSlice"
import { fetchBill, selectBill } from "./billsSlice"

import Role from "../userRoles/Role"

export default function BillNav({ activeTab, scopedByBill = false }) {
    const dispatch = useDispatch()
    const { clientId, billId } = useParams()
    const bill = useSelector(selectBill)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the bill we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (billId) {
            dispatch(fetchBill(billId))
        }
    }, [dispatch, billId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (billId && scopedByBill) {
                return `/clients/${clientId}/bills/${billId}${newRoute}`
            } else {
                return `/clients/${clientId}/bills${newRoute}`
            }
        } else if (billId) {
            return `/bills/${billId}${newRoute}`
        }

        return `/bills${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByBill ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Bills</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>{`Bills > ${bill.name}`}</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/facilities")}>
                            <button type="link" className={`${activeTab === "facilities" ? "selected" : ""}`}>
                                Bill's Facilities
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/documents")}>
                            <button type="link" className={`${activeTab === "documents" ? "selected" : ""}`}>
                                Documents
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Bills</h2>
                <ul className="header-tab-nav" role="navigation">
                    {/* <li>
                        <Link to={() => formatRoute("/dashboard")}>
                            <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                                Bills Dashboard
                            </button>
                        </Link>
                    </li> */}
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                Bill List
                            </button>
                        </Link>
                    </li>
                    <Role action="read" model="bill_document">
                        <li>
                            <Link to={() => formatRoute("/bill_document_repository")}>
                                <button type="link" className={`${activeTab === "repository" ? "selected" : ""}`}>
                                    Bill Document Repository
                                </button>
                            </Link>
                        </li>
                    </Role>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
