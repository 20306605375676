import React, { useState } from "react"
import { Form, DatePicker, Button, Row, Col, Tooltip, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { DownloadOutlined } from "@ant-design/icons"
import { exportAuditLog, fetchAuditLog, selectPagination, setFilter } from "../auditLogSlice"
import { searchUsers, selectLoading, selectUsers, selectTotal as selectUserTotal, loadUsers, selectUserSearch } from "../../../users/usersSlice"
import {
    fetchClients,
    searchClients,
    selectClients,
    selectClientSearch,
    selectLoading as selectClientLoading,
    selectTotal,
} from "../../../clients/clientsSlice"
import {
    fetchDivisions,
    searchDivisions,
    selectDivisions,
    selectDivisionSearch,
    selectLoading as selectDivisionLoading,
    selectTotal as selectDivisionTotal,
} from "../../../divisions/divisionsSlice"
import {
    fetchFacilities,
    searchFacilities,
    selectFacilities,
    selectFacilitySearch,
    selectLoading as selectFacilityLoading,
    selectTotal as selectFacilityTotal,
} from "../../../facilities/facilitiesSlice"
import { debounce } from "lodash"
import moment from "moment"
import PaginatedSelect from "../../../forms/components/PaginatedSelect"

const { RangePicker } = DatePicker

export default function AuditLogFilter() {
    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const clientLoading = useSelector(selectClientLoading)
    const divisionLoading = useSelector(selectDivisionLoading)
    const facilityLoading = useSelector(selectFacilityLoading)

    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [columnName, setColumnName] = useState(null)
    const [columnValue, setColumnValue] = useState(null)

    const handleRangeChange = (value) => {
        const newFrom = value ? value[0] : null
        const newTo = value ? value[1] : null

        setFrom(newFrom)
        setTo(newTo)
    }

    const handleUserSearch = (value) => {
        performSearch(value, () => dispatch(searchUsers(value)))
    }

    /**
     * Shared function for running a search consistently between user, client, division, facility search fields.
     *
     * This will just run a check on the length & presence of a search value, then call the search callback passed.
     *
     * This uses debounce to wait a second after they stop typing before firing the search.
     *
     * @param {*} value search value
     * @param {*} callback callback to call when it's a "valid" search e.g. dispatch a method
     */
    const performSearch = debounce((value, callback) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            callback()
        }
    }, 1000)

    const handleUserChange = (value) => {
        setSelectedUsers(value)
    }

    const handleClientSearch = (value) => {
        performSearch(value, () => dispatch(searchClients(value)))
    }

    const handleClientChange = (value) => {
        setSelectedClient(value)
    }

    const handleDivisionSearch = (value) => {
        performSearch(value, () => dispatch(searchDivisions(value)))
    }

    const handleDivisionChange = (value) => {
        setSelectedDivision(value)
    }

    const handleFacilitySearch = (value) => {
        performSearch(value, () => dispatch(searchFacilities(value)))
    }

    const handleFacilityChange = (value) => {
        setSelectedFacility(value)
    }

    const buildFilters = () => {
        return {
            users: selectedUsers,
            from: from ? moment(from).format() : null,
            to: from ? moment(to).format() : null,
            client: selectedClient ? Number.parseInt(selectedClient).toFixed() : selectedClient,
            division: selectedDivision ? Number.parseInt(selectedDivision).toFixed() : selectedDivision,
            facility: selectedFacility,
            key: columnName,
            value: columnValue,
        }
    }

    const handleSubmit = () => {
        const currentFilters = buildFilters()

        // reset the pagination 'current' whenever the filter form is updated because the
        // pagination is going to be invalidated by the new results. We should be able
        // to still use the user's pageSize attribute, since it will keep that and
        // just kick them back to page 1 of he newly filtered results.
        dispatch(fetchAuditLog({ current: 1, pageSize: pagination.pageSize }, currentFilters))

        // set the filters that were submitted so they can be used by pagination as well
        dispatch(setFilter(currentFilters))
    }

    const handleExport = () => {
        dispatch(exportAuditLog(buildFilters()))
    }

    return (
        <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item label="Users">
                <PaginatedSelect
                    loading={loading}
                    placeholder="Search users..."
                    value={selectedUsers}
                    onChange={handleUserChange}
                    onSearch={handleUserSearch}
                    paginationSelector={selectUsers}
                    paginationTotalSelector={selectUserTotal}
                    loadPaginatedData={loadUsers}
                    searchSelector={selectUserSearch}
                    renderOptionValue={(user) => user.email}
                    mode="multiple"
                />
            </Form.Item>

            <Form.Item label="Client">
                <PaginatedSelect
                    loading={clientLoading}
                    placeholder="Search client..."
                    value={selectedClient}
                    onChange={handleClientChange}
                    onSearch={handleClientSearch}
                    paginationSelector={selectClients}
                    paginationTotalSelector={selectTotal}
                    loadPaginatedData={fetchClients}
                    searchSelector={selectClientSearch}
                />
            </Form.Item>

            <Form.Item label="Division">
                <PaginatedSelect
                    loading={divisionLoading}
                    placeholder="Search division..."
                    value={selectedDivision}
                    onChange={handleDivisionChange}
                    onSearch={handleDivisionSearch}
                    paginationSelector={selectDivisions}
                    paginationTotalSelector={selectDivisionTotal}
                    loadPaginatedData={(pagination) => fetchDivisions(pagination, "/divisions")}
                    searchSelector={selectDivisionSearch}
                />
            </Form.Item>

            <Form.Item label="Facility">
                <PaginatedSelect
                    loading={facilityLoading}
                    placeholder="Search facility..."
                    value={selectedFacility}
                    onChange={handleFacilityChange}
                    onSearch={handleFacilitySearch}
                    paginationSelector={selectFacilities}
                    paginationTotalSelector={selectFacilityTotal}
                    loadPaginatedData={(pagination) => fetchFacilities(pagination, "/facilities")}
                    searchSelector={selectFacilitySearch}
                />
            </Form.Item>

            <Form.Item label="Column Name">
                <Input onChange={(e) => setColumnName(e.target.value)} placeholder="Search Column..." />
            </Form.Item>

            <Form.Item label="Column Value">
                <Input onChange={(e) => setColumnValue(e.target.value)} placeholder="Search Value..." />
            </Form.Item>

            <Form.Item label="Date Range" name="range">
                <RangePicker showTime onChange={handleRangeChange} style={{ width: "100%" }} />
            </Form.Item>

            <Row>
                <Col span={12}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item>
                        <Tooltip title="Export to CSV">
                            <Button style={{ float: "right" }} type="primary" shape="circle" icon={<DownloadOutlined />} onClick={handleExport} />
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
