import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Form, Input, Row, Col, Switch, Select, Skeleton, InputNumber } from "antd"
import { LeftOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { mapKeys, isEmpty } from "lodash"

import { fetchUtilityService, setUtilityService, selectUtilityService, submitUtilityService, selectLoading } from "../utilityServicesSlice"
import { fetchUnitSelectOptions, selectUnitSelectOptions } from "../../settings/units/unitsSlice"

const { Option } = Select

export default function UtilityServiceForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const loading = useSelector(selectLoading)
    const utilityService = useSelector(selectUtilityService)
    const unitSelectOptions = useSelector(selectUnitSelectOptions)
    const { utilityServiceId } = useParams()
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [usageUnitsToRemove, setUsageUnitsToRemove] = useState([])
    const [usageFactorsToRemove, setUsageFactorsToRemove] = useState([])

    useEffect(() => {
        if (utilityServiceId) {
            dispatch(fetchUtilityService(utilityServiceId))
        } else {
            dispatch(setUtilityService({}))
        }
    }, [dispatch, utilityServiceId])

    useEffect(() => {
        form.resetFields()
    }, [form, utilityService])

    // When a Division is set, we need to fetch its Client in order
    // to prepopulate the Client select with the current value
    useEffect(() => {
        form.resetFields()

        if (utilityService && !loaded) {
            setLoaded(true)
            dispatch(fetchUnitSelectOptions())
        }
    }, [dispatch, form, loaded, utilityService])

    // We must set the _destroy attribute of nested fields in order for rails
    // to mark them for deletion
    const setUsageUnitForRemoval = (removeFnc, id) => {
        const usageUnits = form.getFieldValue("usage_units")

        if (usageUnits && usageUnits[id]) {
            setUsageUnitsToRemove(usageUnitsToRemove.concat({ id: usageUnits[id].id, _destroy: true }))
        }

        removeFnc()
    }

    const removeUsageUnits = (values) => {
        const usageUnits = values.usage_units
        return { ...values, usage_units: { ...usageUnits, ...usageUnitsToRemove } }
    }

    // We must set the _destroy attribute of nested fields in order for rails
    // to mark them for deletion
    const setUsageFactorForRemoval = (removeFnc, id) => {
        const usageFactors = form.getFieldValue("usage_factors")

        if (usageFactors && usageFactors[id]) {
            setUsageFactorsToRemove(usageFactorsToRemove.concat({ id: usageFactors[id].id, _destroy: true }))
        }

        removeFnc()
    }

    const removeUsageFactors = (values) => {
        const usageFactors = values.usage_factors
        return { ...values, usage_factors: { ...usageFactors, ...usageFactorsToRemove } }
    }

    const submit = async (values) => {
        const updatedUU = removeUsageUnits(values)
        const finalValues = removeUsageFactors(updatedUU)

        // We need to update the keys to always be passing in "usage_unit_attributes"
        // and "usage_factors_attributes" when posting
        const updatedKeys = mapKeys(finalValues, (value, key) => {
            if (key === "usage_units") {
                return "usage_units_attributes"
            } else if (key === "usage_factors") {
                return "usage_factors_attributes"
            } else {
                return key
            }
        })

        const response = await dispatch(submitUtilityService(updatedKeys))

        if (response.success) {
            history.push("/utility_services")
        }
    }

    return (
        <div id="user-form-container">
            <Link to="/utility_services">
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} layout='vertical' initialValues={{ ...utilityService, inventoriable: isEmpty(utilityService) ? true : utilityService?.inventoriable }} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <Skeleton active loading={loading}>
                            <div>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Service Name" name="name" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Service Code" name="code" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="CO2 Emissions Factor" name="emissions_factor" rules={[{ required: true }]}>
                                            <InputNumber style={{ width: "100%" }} precision={4} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Inventoriable Supply" name="inventoriable" valuePropName="checked" rules={[{ required: true }]}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <h2>Usage Units</h2>
                                <Form.List name={`${utilityServiceId ? "usage_units" : "usage_units_attributes"}`}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field) => (
                                                <Row gutter={24} key={field.name}>
                                                    <Col span={8}>
                                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.usage_units_attributes !== curValues.usage_units_attributes}>
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Unit of Measure"
                                                                    name={[field.name, "unit_id"]}
                                                                    fieldKey={[field.fieldKey, "unit"]}
                                                                    rules={[{ required: true, message: "Missing Unit of Measure" }]}>
                                                                    <Select>
                                                                        {unitSelectOptions.map((unit) => (
                                                                            <Option key={unit.id} value={unit.id}>
                                                                                {unit.symbol}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={8}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Conversion Factor"
                                                            name={[field.name, "conversion_factor"]}
                                                            fieldKey={[field.fieldKey, "conversion_factor"]}
                                                            rules={[{ required: true, message: "Missing Conversion Factor" }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2}>
                                                        <Form.Item {...field} label="Standard" name={[field.name, "standard"]} fieldKey={[field.fieldKey, "standard"]} valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2}>
                                                        <MinusCircleOutlined onClick={() => setUsageUnitForRemoval(() => remove(field.name), field.name)} />
                                                    </Col>
                                                </Row>
                                            ))}

                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Add Usage Unit
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                                <h2>Usage Factors</h2>
                                <Form.List name={`${utilityServiceId ? "usage_factors" : "usage_factors_attributes"}`}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field) => (
                                                <Row gutter={24} key={field.name}>
                                                    <Col span={2}>
                                                        <Form.Item {...field} label="Active" name={[field.name, "active"]} fieldKey={[field.fieldKey, "active"]} valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Name"
                                                            name={[field.name, "name"]}
                                                            fieldKey={[field.fieldKey, "name"]}
                                                            rules={[{ required: true, message: "Missing Name" }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Description"
                                                            name={[field.name, "description"]}
                                                            fieldKey={[field.fieldKey, "description"]}
                                                            rules={[{ required: true, message: "Missing Description" }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4}>
                                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.usage_factors_attributes !== curValues.usage_factors_attributes}>
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Unit of Measure"
                                                                    name={[field.name, "unit_id"]}
                                                                    fieldKey={[field.fieldKey, "unit"]}
                                                                    rules={[{ required: true, message: "Missing Unit of Measure" }]}>
                                                                    <Select>
                                                                        {unitSelectOptions.map((unit) => (
                                                                            <Option key={unit.id} value={unit.id}>
                                                                                {unit.symbol}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={3}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Adjustment Factor"
                                                            name={[field.name, "adjustment_factor"]}
                                                            fieldKey={[field.fieldKey, "adjustment_factor"]}
                                                            valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Default Value (AF)"
                                                            name={[field.name, "default_value"]}
                                                            fieldKey={[field.fieldKey, "default_value"]}
                                                            rules={[{ required: false }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2}>
                                                        <MinusCircleOutlined onClick={() => setUsageFactorForRemoval(() => remove(field.name), field.name)} />
                                                    </Col>
                                                </Row>
                                            ))}

                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Add Usage Factor
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
