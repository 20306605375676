import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import SettingsNav from "../features/settings/SettingsNav"
// import SettingsPage from "../features/settings/SettingsPage"
import AuditLogPage from "../features/settings/auditLog/AuditLogPage"
import FacilityTypePage from "../features/settings/facilityTypes/FacilityTypePage"
import ProductionUnitPage from "../features/settings/productionUnits/ProductionUnitPage"
import CostParameterPage from "../features/settings/costParameters/CostParameterPage"
import UnitPage from "../features/settings/units/UnitPage"
// import CostParameterPage from "../features/settings/costParameters/CostParameterPage"

export default function SettingsRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            {/* just to avoid an empty page, render audit log by default */}
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="auditLog" headerText="Audit Log" />}
                    body={<AuditLogPage view="index" />}
                />
            </Route>

            {/* Facility Types ----------------------------------------------------------------------------------------------------- */}
            <Route path={`${path}/facility_types/:facilityTypeId/edit`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="facilityTypes" headerText="Facility Types" />}
                    body={<FacilityTypePage view="form" recordSelected={true} />}
                />
            </Route>

            <Route path={`${path}/facility_types/new`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="facilityTypes" headerText="Facility Types" />}
                    body={<FacilityTypePage view="form" recordSelected={false} />}
                />
            </Route>

            <Route path={`${path}/facility_types`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="facilityTypes" headerText="Facility Types" />}
                    body={<FacilityTypePage view="index" />}
                />
            </Route>

            {/* Units of Measure ------------------------------------------------------------------------------------------------- */}
            <Route path={`${path}/units/:unitId/edit`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="unitsOfMeasure" headerText="Units of Measure" />}
                    body={<UnitPage view="form" recordSelected={true} />}
                />
            </Route>

            <Route path={`${path}/units/new`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="unitsOfMeasure" headerText="Units of Measure" />}
                    body={<UnitPage view="form" recordSelected={false} />}
                />
            </Route>

            <Route path={`${path}/units`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="unitsOfMeasure" headerText="Units of Measure" />}
                    body={<UnitPage view="index" />}
                />
            </Route>

            {/* Production Units --------------------------------------------------------------------------------------------------- */}
            <Route path={`${path}/production_units/:productionUnitId/edit`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="productionUnits" headerText="Production Units" />}
                    body={<ProductionUnitPage view="form" recordSelected={true} />}
                />
            </Route>

            <Route path={`${path}/production_units/new`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="productionUnits" headerText="Production Units" />}
                    body={<ProductionUnitPage view="form" recordSelected={false} />}
                />
            </Route>

            <Route path={`${path}/production_units`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="productionUnits" headerText="Production Units" />}
                    body={<ProductionUnitPage view="index" />}
                />
            </Route>

            {/* Cost Parameters -------------------------------------------------------------------------------------------------- */}
            <Route path={`${path}/cost_parameters/:costParameterId/edit`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="costParameters" headerText="Cost Parameters" />}
                    body={<CostParameterPage view="form" recordSelected={true} />}
                />
            </Route>

            <Route path={`${path}/cost_parameters/new`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="costParameters" headerText="Cost Parameters" />}
                    body={<CostParameterPage view="form" recordSelected={false} />}
                />
            </Route>

            <Route path={`${path}/cost_parameters`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="costParameters" headerText="Cost Parameters" />}
                    body={<CostParameterPage view="index" />}
                />
            </Route>

            {/* Audit Logs --------------------------------------------------------------------------------------------------------- */}
            <Route path={`${path}/audit_log`}>
                <ConsoleLayout
                    highlightTab="settings"
                    header={<SettingsNav activeTab="auditLog" headerText="Audit Log" />}
                    body={<AuditLogPage view="index" />}
                />
            </Route>
        </Switch>
    )
}
