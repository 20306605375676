import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DashboardSelectionBar from "./DashboardSelectionBar"
import DashboardCardContainer from "./DashboardCardContainer"
import DashboardGraph from "./graph/DashboardGraph"
import { submitQuery, selectGraphDataType, selectGraphSummaryMode, selectService, selectCompareToLastYear, selectDateRangeType } from "../dashboardsSlice"

export default function Dashboard({ scopeType, scopeId, clientId }) {
    const dispatch = useDispatch()

    const graphDataType = useSelector(selectGraphDataType)
    const summaryMode = useSelector(selectGraphSummaryMode)
    const service = useSelector(selectService)
    const compare = useSelector(selectCompareToLastYear)
    const dateRangeType = useSelector(selectDateRangeType)

    useEffect(() => {
        // if we are in a billable or supply account and do not have a service, do not load any data.
        // the system will auto-select a service, this will keep it from running the 'all' query before that
        if ((scopeType === "BillableAccount" || scopeType === "SupplyAccount") && !service) {
            return
        }

        dispatch(submitQuery(null, scopeType, scopeId, clientId))
    }, [dispatch, scopeType, scopeId, clientId, graphDataType, summaryMode, service])

    return (
        <div id={"division-dashboard"}>
            <DashboardSelectionBar scopeType={scopeType} scopeId={scopeId} clientId={clientId} />

            <DashboardCardContainer scopeType={scopeType} scopeId={scopeId} />

            {/* <DashboardGraph /> */}
        </div>
    )
}
