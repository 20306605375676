import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import UtilityServiceNav from "../features/utilityServices/UtilityServiceNav"
import UtilityServicePage from "../features/utilityServices/UtilityServicePage"

export default function UtilityServiceRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="utilityServices"
                    header={<UtilityServiceNav activeTab="list" />}
                    body={<UtilityServicePage view="index" recordSelected={false} />}
                    sidebarType="utility_services"
                />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout
                    highlightTab="utilityServices"
                    header={<UtilityServiceNav activeTab="list" />}
                    body={<UtilityServicePage view="form" recordSelected={false} />}
                    sidebarType="utility_services"
                />
            </Route>

            <Route path={`${path}/:utilityServiceId/edit`}>
                <ConsoleLayout
                    highlightTab="utilityServices"
                    header={<UtilityServiceNav activeTab="list" scopedByUtilityService={false} />}
                    body={<UtilityServicePage view="form" recordSelected={true} />}
                    sidebarType="utility_services"
                />
            </Route>
        </Switch>
    )
}
