import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../lib/client"
import { channelPartnersUrl, channelPartnerUrl, channelPartnerSelectOptionsUrl, dynamicUrl, channelPartnerSearchUrl, channelPartnersCsvUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"
import { notification } from "antd"

export const ChannelPartnersSlice = createSlice({
    name: "channelPartners",
    initialState: {
        channelPartner: {},
        channelPartners: [],
        channelPartnerSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        channelPartnerSearch: [],
    },
    reducers: {
        setChannelPartner: (state, action) => {
            state.channelPartner = action.payload
        },
        setChannelPartners: (state, action) => {
            state.channelPartners = action.payload.channel_partners
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setChannelPartnerSelectOptions: (state, action) => {
            state.channelPartnerSelectOptions = action.payload
        },
        setChannelPartnerSearch: (state, action) => {
            state.channelPartnerSearch = action.payload
        },
    },
})

export const { setChannelPartner, setChannelPartners, setPagination, setLoading, setChannelPartnerSelectOptions, setChannelPartnerSearch } = ChannelPartnersSlice.actions

export function fetchChannelPartner(id) {
    return async (dispatch, getState) => {
        client.get(channelPartnerUrl(id)).then((response) => {
            dispatch(setChannelPartner(response.data))
        })
    }
}

export function fetchChannelPartners(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setChannelPartners(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchChannelPartnersFlatSelect() {
    return async (dispatch, getState) => {
        try {
            const response = await client.get(`${channelPartnersUrl}/flat`)

            return response.data
        } catch (e) {
            notification.error({
                message: "Request error",
                description: e?.response?.statusText,
                duration: 0.8,
            })
        }
    }
}

export function searchChannelPartners(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${channelPartnerSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setChannelPartnerSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

// export function fetchClientChannelPartners(clientId, pagination = null, currentPath = null) {
//     return async (dispatch, getState) => {
//         const clientChannelPartnersPath = `clients/${clientId}/channelPartners/sidebar_records`

//         const response = await client.get(`${dynamicUrl(clientChannelPartnersPath)}`)
//         return response.data.channelPartners
//     }
// }

export function submitChannelPartner(values) {
    return async (dispatch, getState) => {
        const id = getState().channelPartners.channelPartner.id
        if (id) {
            // patch - update
            const response = await client.patch(channelPartnerUrl(id), { channel_partner: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(channelPartnersUrl, { channel_partner: values })
            return response.data
        }
    }
}

export function fetchChannelPartnerSelectOptions(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        client.get(`${channelPartnerSelectOptionsUrl()}`).then((response) => {
            dispatch(setChannelPartnerSelectOptions(response.data.channelPartners))
        })
    }
}

export function filterChannelPartners(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, channelPartnersUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setChannelPartners(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(filters) {
    return async (dispatch, getState) => {
        client.get(`${channelPartnersCsvUrl}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectChannelPartner = (state) => state.channelPartners.channelPartner
export const selectChannelPartners = (state) => state.channelPartners.channelPartners
export const selectTotal = (state) => state.channelPartners.total
export const selectPagination = (state) => state.channelPartners.pagination
export const selectLoading = (state) => state.channelPartners.loading
export const selectChannelPartnerSelectOptions = (state) => state.channelPartners.channelPartnerSelectOptions
export const selectChannelPartnerSearch = (state) => state.channelPartners.channelPartnerSearch

export default ChannelPartnersSlice.reducer
