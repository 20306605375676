import React from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloud, faDollarSign, faGaugeSimple, faPercent } from "@fortawesome/free-solid-svg-icons"
import DashboardCard from "./DashboardCard"
import { selectReportData, selectLoading, selectCompareToLastYear, selectDateRangeType, selectService } from "../../../features/dashboards/dashboardsSlice"
import { Skeleton } from "antd"
import { generateAmountCardProps } from "../../../lib/dashboards"

export default function DashboardCardContainer({ scopeType, scopeId }) {
    const reportData = useSelector(selectReportData)
    const compare = useSelector(selectCompareToLastYear)
    const dateRangeType = useSelector(selectDateRangeType)
    const loading = useSelector(selectLoading)
    const service = useSelector(selectService)

    return (
        <Skeleton active loading={loading}>
            <div className={"dashboard-card-container"}>
                <DashboardCard title={"Total Utility Cost"} topIcon={<FontAwesomeIcon icon={faDollarSign} />} {...generateAmountCardProps(reportData.total_utility_cost, dateRangeType, compare)} />

                {!service && (
                    <DashboardCard
                        title={"Highest Cost Service: "}
                        titleSuffix={reportData.highest_cost_service?.name}
                        topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                        {...generateAmountCardProps(reportData.highest_cost_service, dateRangeType, compare)}
                    />
                )}

                {service && service !== "internet" && (
                    <>
                        <DashboardCard
                            title={"Usage: "}
                            titleSuffix={reportData.service_usage?.unit_symbol}
                            topIcon={null}
                            {...generateAmountCardProps(reportData.service_usage, dateRangeType, compare)}
                        />

                        {scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && (
                            <DashboardCard
                                title={"Total Unit Cost"}
                                topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                                {...generateAmountCardProps(reportData.total_unit_cost, dateRangeType, compare)}
                            />
                        )}
                    </>
                )}

                {!((scopeType === "Facility" && !!scopeId) || (scopeType === "BillableAccount" && !!scopeId) || scopeType === "SupplyAccount" || service) && (
                    <DashboardCard
                        title={"Highest MTCO2e Facility"}
                        topIcon={<FontAwesomeIcon icon={faCloud} />}
                        {...generateAmountCardProps(reportData.highest_mtc02e_facility, dateRangeType, compare, "Tonnes")}
                        bottomIcon={<span> </span>}
                        footerSuffix={null}
                        footerDataSuffix={null}
                        footer={reportData.highest_mtc02e_facility?.name}
                    />
                )}

                {/* this is hidden on water */}
                {service !== "water" && service !== "sewer" && service !== "internet" && (
                    <DashboardCard title={"Total MTCO2e"} topIcon={<FontAwesomeIcon icon={faCloud} />} {...generateAmountCardProps(reportData.total_mtc02e, dateRangeType, compare, "Tonnes")} />
                )}

                {/* this is hidden on water */}
                {service !== "water" && service !== "sewer" && service !== "internet" && service !== "fuel_oil" && scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && (
                    <DashboardCard
                        title={"Average Cost Per Day"}
                        topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                        {...generateAmountCardProps(reportData.average_cost_per_day, dateRangeType, compare)}
                    />
                )}

                {!((scopeType === "Division" && !!scopeId) || scopeType === "Facility") && scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && (
                    <DashboardCard
                        title={"Highest Cost Division"}
                        topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                        {...generateAmountCardProps(reportData.highest_cost_division, dateRangeType, compare)}
                        footer={reportData.highest_cost_division?.name}
                        footerSuffix={null}
                        footerDataSuffix={null}
                    />
                )}

                {!(scopeType === "Facility" && !!scopeId) && scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && (
                    <DashboardCard
                        title={"Highest Cost Facility"}
                        topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                        {...generateAmountCardProps(reportData.highest_cost_facility, dateRangeType, compare)}
                        footer={reportData.highest_cost_facility?.name}
                        footerSuffix={null}
                        footerDataSuffix={null}
                    />
                )}

                {scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && (
                    <DashboardCard title={"Total Active Meters"} topIcon={<FontAwesomeIcon icon={faGaugeSimple} />} bottomIcon={<span> </span>} dataNumber={reportData.total_active_meters?.current} />
                )}

                {(scopeType === "BillableAccount" || scopeType === "SupplyAccount") && scopeId && (
                    <>
                        <DashboardCard
                            title="Utility Cost Percentage"
                            topIcon={<FontAwesomeIcon icon={faPercent} />}
                            {...generateAmountCardProps(reportData.utility_cost_percentage, dateRangeType, compare)}
                            dataNumber={reportData.utility_cost_percentage?.current}
                            footerSuffix={reportData.utility_cost_percentage?.previous}
                        />

                        {reportData.distribution_unit && (
                            <DashboardCard
                                title="Distribution Unit"
                                // topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                                topIcon={null}
                                {...generateAmountCardProps(reportData.distribution_unit, dateRangeType, compare)}
                            />
                        )}

                        {reportData.supply_unit && (
                            <DashboardCard
                                title="Supply Unit"
                                // topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                                topIcon={null}
                                {...generateAmountCardProps(reportData.supply_unit, dateRangeType, compare)}
                            />
                        )}

                        {/* This card seems to always show, the data is just dynamic */}
                        <DashboardCard
                            title="Total Unit"
                            // topIcon={<FontAwesomeIcon icon={faDollarSign} />}
                            topIcon={null}
                            {...generateAmountCardProps(reportData.account_total_unit, dateRangeType, compare)}
                        />
                    </>
                )}
            </div>
        </Skeleton>
    )
}
