import { LeftOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Skeleton, Select } from "antd"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { requestNotification } from "../../lib/notifications"
import { selectUserRoles } from "../userRoles/userRolesSlice"
import { fetchUser, selectLoading, selectUser, setUser, submitUser } from "./usersSlice"
const { Option } = Select

export default function UserForm(props) {
    const dispatch = useDispatch()

    const user = useSelector(selectUser)
    const loading = useSelector(selectLoading)
    const userRoles = useSelector(selectUserRoles)

    const [form] = Form.useForm()

    const history = useHistory()

    const { userId, clientId } = useParams()
    
    const { password } = props

    const submit = async (values) => {
        
        const response = await dispatch(submitUser(values, userId))

        if (response.success) {
            if (clientId) {
                if (response.id && !userId) {
                    history.push(`/clients/${clientId}/users/${response.id}/roles`)
                } else {
                    history.push(`/clients/${clientId}/users`)
                }
            } else {
                if (response.id && !userId) {
                    history.push(`/users/${response.id}/roles`)
                } else {
                    history.push("/users")
                }
            }
        }

        requestNotification(response)
    }

    useEffect(() => {
        if (userId) {
            dispatch(fetchUser(userId))
        } else {
            dispatch(setUser({}))
        }
    }, [dispatch, userId])

    useEffect(() => {
        form.resetFields()
    }, [form, user, userRoles])

    return (
        <div id="user-form-container">
            <Link to={clientId ? `/clients/${clientId}/users` : "/users"}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form form={form} initialValues={{ ...user, user_roles_attributes: userRoles }} onFinish={submit} layout="vertical">
                <div className="card">
                    <div className="card-body">
                        <Skeleton active loading={loading}>
                            <div>
                                {
                                    password ?
                                        <Row gutter={12} style={{display: 'flex', flexDirection: 'column'}}>
                                            <Col span={12}>
                                                <Form.Item label="Password" name="password" type="password" rules={[{ required: true }]}>
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="Password Confirmation" name="password_confirmation" rules={[{ required: true }]}>
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    :
                                    <>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item label="First Name" name="first_name" rules={[{ required: false }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="Last Name" name="last_name" rules={[{ required: false }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item label="Title" name="title" rules={[{ required: false }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Status" name="status">
                                                    <Select placeholder={"- select -"} showArrow={true}>
                                                        <Option key="active" value="active" label="Active">
                                                            Active
                                                        </Option>
                                                        <Option key="in_active" value="in_active" label="Inactive">
                                                            Inactive
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
