import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Skeleton } from "antd"

import { fetchLedgerField, selectLedgerField, selectLoading as selectFieldLoading } from "./ledgerFields/ledgerFieldsSlice"
import { fetchLedgerAccount, selectLedgerAccount, selectLoading as selectAccountLoading } from "./ledgerAccounts/ledgerAccountsSlice"

export default function LedgerNav({ activeTab, headerText = null, recordSelected = null }) {
    const dispatch = useDispatch()
    const { clientId, ledgerFieldId, ledgerAccountId } = useParams()
    const ledgerField = useSelector(selectLedgerField)
    const fieldLoading = useSelector(selectFieldLoading)
    const ledgerAccount = useSelector(selectLedgerAccount)
    const accountLoading = useSelector(selectAccountLoading)

    useEffect(() => {
        if (ledgerFieldId) {
            dispatch(fetchLedgerField(ledgerFieldId))
        }
    }, [dispatch, ledgerFieldId])

    useEffect(() => {
        if (ledgerAccountId) {
            dispatch(fetchLedgerAccount(ledgerAccountId))
        }
    }, [dispatch, ledgerAccountId])

    const renderHeaderText = () => {
        if (recordSelected) {
            if (ledgerFieldId) {
                if (fieldLoading) {
                    return <Skeleton.Input style={{ width: 200 }} active />
                } else {
                    return `${headerText} > ${ledgerField.name}`
                }
            } else if (ledgerAccountId) {
                if (accountLoading) {
                    return <Skeleton.Input style={{ width: 200 }} active />
                } else {
                    return `${headerText} > ${ledgerAccount.name}`
                }
            }
        } else if (headerText) {
            return `${headerText}`
        }
    }

    return (
        <>
            <h2>{renderHeaderText()}</h2>
            <ul className="header-tab-nav" role="navigation">
                <li>
                    <Link to={`/clients/${clientId}/ledger_fields`}>
                        <button type="link" className={`${activeTab === "ledgerFields" ? "selected" : ""}`}>
                            Ledger Fields
                        </button>
                    </Link>
                </li>

                <li>
                    <Link to={`/clients/${clientId}/ledger_accounts`}>
                        <button type="link" className={`${activeTab === "ledgerAccounts" ? "selected" : ""}`}>
                            Ledger Accounts
                        </button>
                    </Link>
                </li>
            </ul>
        </>
    )
}
