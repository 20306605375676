import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Pagination, Skeleton } from "antd"
import { CaretDownOutlined, FilterOutlined } from "@ant-design/icons"
import { useRouteMatch, useHistory, useParams } from "react-router-dom"
import { debounce } from "lodash"

import { selectScopedClient, fetchAndSetScopedClient, selectScopedByClient } from "../../stores/appSlice"
import { selectClientDropdownOptions, fetchClientDropdownOptions, selectLoading, selectDropdownTotal } from "../clients/clientsSlice"
import { selectClientOnlyUser, selectUserRolesForUser } from "../userRoles/userRolesSlice"

import Role from "../userRoles/Role"
import { clientUserRootPath } from "../../lib/roles"

export default function HeaderDropdown() {
    const history = useHistory()
    const { path } = useRouteMatch()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    const loading = useSelector(selectLoading)
    const scopedClient = useSelector(selectScopedClient)
    const scopedByClient = useSelector(selectScopedByClient)
    const dropdownTotal = useSelector(selectDropdownTotal)
    const dropdownClients = useSelector(selectClientDropdownOptions)
    const clientOnlyUser = useSelector(selectClientOnlyUser)
    const userRolesForUser = useSelector(selectUserRolesForUser)

    const { clientId } = useParams()

    /**
     * Whenever the client ID is set in URL params, we need to fetch and set scoped client IF:
     * 1. We have no 'scopedByClient' (client ID) in app slice at all OR:
     * 2. The scopedByClient ID from the slice does not match the client ID in the URL params
     */
    useEffect(() => {
        if (
            path === "/clients/:clientId/edit" ||
            path === "/clients/:clientId/client_vendor_numbers" ||
            path === "/clients/:clientId/client_vendor_numbers/new" ||
            path === "/clients/:clientId/client_vendor_numbers/:clientVendorNumberId/edit"
        ) {
            return
        }

        if (clientId && (!scopedByClient || clientId !== scopedByClient)) {
            dispatch(fetchAndSetScopedClient(clientId, () => history.push("/landing")))
        }
    }, [dispatch, clientId, scopedByClient, history, path])

    useEffect(() => {
        if (open) {
            dispatch(fetchClientDropdownOptions({ current: currentPage, pageSize: 20 }, searchTerm))
        }
        // TODO: add pagination to check
        // eslint-disable-next-line
    }, [dispatch, open])

    const adminMenuOption = () => {
        return (
            <button
                type="link"
                className="muc-admin"
                onClick={() => {
                    dispatch(fetchAndSetScopedClient(null))
                    setOpen(!open)
                    history.push("/")
                }}>
                MUC Admin
            </button>
        )
    }

    // New search term
    // Pagination changes were also handled in this same function but there's no way to
    // not reset the current page back to one (on search update, clear, etc...) if both
    // searchTerm and currentPage are both in the dependency array
    useEffect(() => {
        if (searchTerm.length >= "3") {
            dispatch(fetchClientDropdownOptions({ current: 1, pageSize: 20 }, searchTerm))
            setCurrentPage(1)
        } else if (searchTerm.length === 0) {
            dispatch(fetchClientDropdownOptions({ current: 1, pageSize: 20 }))
            setCurrentPage(1)
        }
    }, [dispatch, searchTerm])

    // New Pagination
    useEffect(() => {
        if (searchTerm.length >= "3") {
            dispatch(fetchClientDropdownOptions({ current: currentPage, pageSize: 20 }, searchTerm))
        } else if (searchTerm.length === 0) {
            dispatch(fetchClientDropdownOptions({ current: currentPage, pageSize: 20 }))
        }
        // eslint-disable-next-line
    }, [dispatch, currentPage])

    const handleSearchUpdate = debounce((e) => {
        if (e.target.value) {
            setSearchTerm(e.target.value)
        } else {
            setSearchTerm("")
        }
    }, 1000)
    

    const handlePaginationUpdate = (page, pageSize) => {
        setCurrentPage(page)
    }

    const dropdownFilter = () => {
        return (
            <div className="location-filter" onClick={(event) => event.stopPropagation()}>
                <div className="location-filter-inner">
                    <FilterOutlined />
                    <input type="text" id="client-search" placeholder="Search by Client" onChange={(e) => handleSearchUpdate(e)} />
                </div>
            </div>
        )
    }

    const clientButtons = (buttons = []) => {
        if (dropdownClients && dropdownClients.clients && dropdownClients.clients.length > 0) {
            return dropdownClients.clients.map((client) => (
                <button
                    key={client.id}
                    type="link"
                    onClick={() => {
                        setOpen(!open)
                        // when the user is a client-only user, determine where to send them based on their highest permission...
                        history.push(clientOnlyUser ? clientUserRootPath(client.id, userRolesForUser) : `/clients/${client.id}/dashboard`)
                    }}>
                    {client.name}
                </button>
            ))
        } else {
            return ""
        }
    }

    return (
        <div id="navigation-dropdown" className={open ? "open" : undefined}>
            <div className="selected-company" style={{ height: "100%", width: "100%" }} onClick={() => setOpen(!open)}>
                <span className="company-name">{`${scopedClient ? scopedClient.name : clientOnlyUser ? "Select a Client" : "MUC Admin"}`}</span>
                <CaretDownOutlined />
            </div>
            <div id="navigation-dropdown-company-list">
                <Role admin={true}>{adminMenuOption()}</Role>

                {dropdownFilter()}

                <div className="client-options" style={{ flexGrow: "1", overflowY: "auto" }}>
                    <Skeleton active loading={loading}>
                        {clientButtons()}
                    </Skeleton>
                </div>

                {/* TODO: Is there a way to set this total to not be weird with custom page sizes? */}
                <Pagination style={{marginTop: 15}} simple current={currentPage} total={parseInt(dropdownTotal / 2)} onChange={handlePaginationUpdate} className="sidebar-pagination" />
            </div>
        </div>
    )
}
