import React from "react"
import { useSelector } from "react-redux"
import { selectScopedByClient } from "../../stores/appSlice"
import DocumentsTable from "../documents/components/DocumentsTable"
import { useParams } from "react-router"
import DocumentForm from "../documents/components/DocumentForm"
import Dashboard from "../../features/dashboards/components/Dashboard"
import SubscriptionsForm from "./components/SubscriptionsForm"
import UserTable from "../users/UserTable"
import UserForm from "../users/UserForm"
import UserRoleTable from "../userRoles/UserRoleTable"
import UserRoleForm from "../userRoles/UserRoleForm"

export default function ClientPage({ view }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { clientId } = useParams()

    const renderView = () => {
        switch (view) {
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            case "subscriptions":
                return renderSubscriptionsForm()
            case "dashboard":
                return renderDashboard()
            case "users":
                return renderUsers()
            case "user-form":
                return renderUserForm()
            case "roles":
                return renderRoles()
            case "role-form":
                return renderRoleform()
        }
    }

    const renderDocuments = () => {
        return <DocumentsTable type="Client" id={clientId} />
    }

    const renderSubscriptionsForm = () => {
        return <SubscriptionsForm clientId={clientId} />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="Client" id={clientId} />
    }

    const renderDashboard = () => {
        return <Dashboard scopeType="Client" scopeId={clientId} clientId={clientId} />
    }

    const renderUsers = () => {
        return <UserTable />
    }

    const renderUserForm = () => {
        return <UserForm />
    }

    const renderRoles = () => {
        return <UserRoleTable />
    }

    const renderRoleform = () => {
        return <UserRoleForm />
    }

    return <>{renderView()}</>
}
