import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"
import UserNav from "../features/users/UserNav"
import UserTable from "../features/users/UserTable"
import UserForm from "../features/users/UserForm"
import UserRoleTable from "../features/userRoles/UserRoleTable"
import UserRoleForm from "../features/userRoles/UserRoleForm"
import Profile from "../features/profiles/Profile"

export default function UserRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" />} body={<UserTable />} />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" />} body={<UserForm />} />
            </Route>

            <Route path={`${path}/:userId/edit/password`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<UserForm password={true} />} />
            </Route>
            
            <Route path={`${path}/:userId/edit`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<UserForm password={false}/>} />
            </Route>

            <Route path={`${path}/profile`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<Profile />} />
            </Route>

            <Route exact path={`${path}/:userId/roles`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<UserRoleTable />} />
            </Route>

            <Route exact path={`${path}/:userId/roles/new`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<UserRoleForm actionName="new" />} />
            </Route>

            <Route exact path={`${path}/:userId/roles/:roleId/edit`}>
                <ConsoleLayout highlightTab="users" header={<UserNav activeTab="list" index={false} />} body={<UserRoleForm actionName="edit" />} />
            </Route>
        </Switch>
    )
}
