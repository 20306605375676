import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

const { confirm } = Modal;

const useExportConfirmationModal = () => {
  const dispatch = useDispatch();

  const showConfirm = (onAccept, title = false, content = false) => {
    confirm({
        title: title ? title : "Create the global export?",
        content: content
            ? content
            : "This action will create an export for all the paid batches that were created today. By doing this, the batches will be finalized and no longer visible on this table.",
        onOk() {
            dispatch(onAccept)
        },
        onCancel() {},
    })
  };

  return showConfirm;
};

export default useExportConfirmationModal;