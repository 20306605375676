import React from "react"

import UnitTable from "./components/UnitTable"
import UnitForm from "./components/UnitForm"

export default function UnitPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <UnitTable />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <UnitForm actionName="edit" />
        }

        return <UnitForm actionName="new" />
    }

    return <>{renderView()}</>
}
