import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { documentsUrl, documentUrl, downloadDocumentUrl } from "../../lib/endpoints"

export const documentsSlice = createSlice({
    name: "documents",
    initialState: {
        documents: [],
        document: {},
        loading: false,
        total: 0,
        pagination: { current: 1, pageSize: 10 },
    },
    reducers: {
        setDocuments: (state, action) => {
            state.documents = action.payload.documents
            state.total = action.payload.total
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setDocument: (state, action) => {
            state.document = action.payload
        },
    },
})

export const { setDocuments, setLoading, setPagination, setDocument } = documentsSlice.actions

export function fetchDocuments(pagination, type, id) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${documentsUrl(type, id)}&page=${pagination.current}&per=${pagination.pageSize}`)
            .then(({ data }) => {
                dispatch(setPagination(pagination))
                dispatch(setDocuments(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchDocument(documentId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(documentUrl(documentId))
            .then(({ data }) => {
                dispatch(setDocument(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function createDocument(type, id, record) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        // the multipart/form-data compatible form object that we have to send to the server
        const bodyFormData = new FormData()

        // loop over each attribute of our record, and attach it to our body form data object with
        // the same name and value... we can't send JSON so we have to do this and it doesn't look like
        // the constructor of FormData accepts an object to map to easily
        Object.keys(record).forEach((input) => bodyFormData.append(`document[${input}]`, record[input]))

        // attach the attachable data to the request itself so it's bundled in with permitted params
        bodyFormData.append("document[attachable_type]", type)
        bodyFormData.append("document[attachable_id]", id)

        const response = await client({
            method: record.id ? "patch" : "post",
            url: record.id ? documentUrl(record.id) : documentsUrl(type, id),
            data: bodyFormData,
            config: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        })

        dispatch(setLoading(false))

        return response
    }
}

export function downloadDocument(documentId) {
    return async () => {
        client
            .get(downloadDocumentUrl(documentId))
            .then(({ data }) => {
                // right now we just open the file in another tab which will either force
                // download (excel, word, etc.) and close the tab or display the item if it's an image or a pdf
                window.open(data.url)
            })
            .catch((error) => {
                console.warn(error)
            })
    }
}

export const selectDocuments = (state) => state.documents.documents
export const selectLoading = (state) => state.documents.loading
export const selectTotal = (state) => state.documents.total
export const selectPagination = (state) => state.documents.pagination
export const selectDocument = (state) => state.documents.document

export default documentsSlice.reducer
