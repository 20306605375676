import React from "react"

import LedgerFieldTable from "./components/LedgerFieldTable"
import LedgerFieldForm from "./components/LedgerFieldForm"
import ImportForm from "../../imports/ImportForm"

export default function LedgerFieldPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "import":
                return renderImport()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <LedgerFieldTable />
    }

    const renderImport = () => {
        return <ImportForm dataType="ledger_field" />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <LedgerFieldForm actionName="edit" />
        }

        return <LedgerFieldForm actionName="new" />
    }

    return <>{renderView()}</>
}
