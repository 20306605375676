import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Select } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchDivision, setDivision, selectDivision, submitDivision, fetchBatchGroupSelectOptions, selectBatchGroupSelectOptions } from "../divisionsSlice"
import { fetchClientSelectOptions, fetchClientSelectOptionById, setClientSelectOptions } from "../../clients/clientsSlice"

import { requestNotification } from "../../../lib/notifications"

const { Option } = Select

export default function DivisionForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const division = useSelector(selectDivision)
    const batchGroupSelectOptions = useSelector(selectBatchGroupSelectOptions)
    const { clientId, divisionId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [searchTerm] = useState("")

    useEffect(() => {
        if (divisionId) {
            dispatch(fetchDivision(divisionId))
        } else {
            dispatch(setDivision({}))
        }
    }, [dispatch, divisionId])

    // When a Division is set, we need to fetch its Client in order
    // to prepopulate the Client select with the current value
    useEffect(() => {
        form.resetFields()

        if (division.client_id && !loaded) {
            setLoaded(true)
            dispatch(fetchClientSelectOptionById([division.client_id]))
        }

        dispatch(fetchBatchGroupSelectOptions(clientId))
    }, [dispatch, form, loaded, division]) // eslint-disable-line

    useEffect(() => {
        if (searchTerm.length >= "3") {
            dispatch(fetchClientSelectOptions(searchTerm))
        } else {
            dispatch(setClientSelectOptions([]))
        }
    }, [dispatch, searchTerm]) // eslint-disable-line

    // const handleSearchUpdate = (value) => {
    //     setSearchTerm(value)
    // }
    //
    // const handleClientSelect = (value) => {
    //     form.setFieldsValue({ client_id: value })
    // }

    const submit = async (values) => {
        // There's something really weird with antd onClear and submitting. Values get set to undefined rather than whatever you set them to.
        const response =
            values.batch_group_id === undefined
                ? await dispatch(submitDivision({ ...values, client_id: clientId, batch_group_id: null }, divisionId))
                : await dispatch(submitDivision({ ...values, client_id: clientId }, divisionId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}
            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={division} onFinish={submit} layout="vertical">
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Short Code" name="short_code" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Batch Group" name="batch_group_id" rules={[{ required: false }]}>
                                        <Select placeholder={"Select Batch Group"} showArrow={true} allowClear={true}>
                                            {batchGroupSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Client" name="client_id">
                                        <PaginatedSelect
                                            loading={divisionLoading}
                                            placeholder="Please select a client"
                                            onChange={handleClientSelect}
                                            onSearch={handleSearchUpdate}
                                            paginationSelector={selectClients}
                                            paginationTotalSelector={selectTotal}
                                            loadPaginatedData={fetchClients}
                                            searchSelector={selectClientSelectOptions}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
