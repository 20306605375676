import React, { useState } from "react"
import { Form, DatePicker } from "antd"
import moment from "moment"

export default function ReportDateRange({ required = false }) {
    const { RangePicker } = DatePicker

    return (
        <div>
            <Form.List name="form_data">
                {(fields) => (
                    <Form.Item label="Date Range" name="date_range" rules={[{ required: required }]}>
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                )}
            </Form.List>
        </div>
    )
}
