import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Layout, Menu, Dropdown, Popover, Button, Alert, Badge } from "antd"
import { BellOutlined } from "@ant-design/icons"

import { resetCount, selectAlerts, selectCount } from "./alertPanelSlice"

export default function HeaderDropdown() {
    const dispatch = useDispatch()
    const alerts = useSelector(selectAlerts)
    const count = useSelector(selectCount)

    const titleContent = () => {
        return "Notifications"
    }

    const panelContent = () => {
        return alerts.map((alert, i) => (
            <React.Fragment key={i}>
                <a target="_blank" href={alert.link} key={i}>
                    {alert.timestamp}: {alert.file_name}
                </a>
                <br />
            </React.Fragment>
        ))
    }

    return (
        <Badge count={count}>
            <Popover placement="bottom" title={titleContent} content={panelContent} trigger="click" overlayClassName="alert-overlay" onClick={() => dispatch(resetCount())}>
                <BellOutlined />
            </Popover>
        </Badge>
    )
}
