import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Select } from "antd"
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { selectService, setService, submitQuery, selectServices, fetchServices } from "../../../features/dashboards/dashboardsSlice"

export default function DashboardServices({ scopeType, scopeId, clientId }) {
    const dispatch = useDispatch()

    const service = useSelector(selectService)
    const services = useSelector(selectServices)

    const [init, setInit] = useState(false)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchServices(clientId))
    }, [dispatch])

    // auto select first service if we're in an account and have services and none selected
    useEffect(() => {
        if ((scopeType === "BillableAccount" || scopeType === "SupplyAccount") && services?.length && !service) {
            handleChange(services[0]?.name)
        }
    }, [dispatch, services, service])

    const handleChange = (value) => {
        dispatch(setService(value))
    }

    return (
        <div>
            <FontAwesomeIcon icon={faBoxArchive} color={"#fbb040"} />

            <Select value={service} size={"small"} onChange={handleChange}>
                {/* On billable and supply accounts, they cannot select all services. */}
                {scopeType !== "BillableAccount" && scopeType !== "SupplyAccount" && <Select.Option value="">All Services</Select.Option>}
                {services.map((s) => (
                    <Select.Option value={s.name} key={s.id}>
                        {s.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}
