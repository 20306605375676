import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import {
    divisionsUrl,
    divisionsScopedByClientUrl,
    divisionUrl,
    divisionSelectOptionsUrl,
    divisionSelectOptionByIdUrl,
    clientDivisionSelectOptionsUrl,
    dynamicUrl,
    divisionSearchUrl,
    batchGroupSelectOptionsUrl,
    clientDivisionsCsvUrl,
} from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const divisionsSlice = createSlice({
    name: "divisions",
    initialState: {
        division: {},
        divisions: [],
        divisionSelectOptions: [],
        divisionSearch: [],
        batchGroupSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setDivision: (state, action) => {
            state.division = action.payload
        },
        setDivisions: (state, action) => {
            state.divisions = action.payload.divisions
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setDivisionSelectOptions: (state, action) => {
            state.divisionSelectOptions = action.payload
        },
        setDivisionSearch: (state, action) => {
            state.divisionSearch = action.payload
        },
        setBatchGroupSelectOptions: (state, action) => {
            state.batchGroupSelectOptions = action.payload
        },
        deleteDivision: (state, action) => {
            state.divisions = state.divisions.filter((division) => division.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setDivision, setDivisions, setPagination, setLoading, setDivisionSelectOptions, setDivisionSearch, setBatchGroupSelectOptions } = divisionsSlice.actions

export function fetchDivision(id) {
    return async (dispatch, getState) => {
        client.get(divisionUrl(id)).then((response) => {
            dispatch(setDivision(response.data))
        })
    }
}

export function fetchDivisions(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        // This route may be nested so we have to use a dynamic route
        // /divisions VS /clients/client_id/divisions
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setDivisions(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientDivisions(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientDivisionsPath = `clients/${clientId}/divisions/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientDivisionsPath)}`)
        return response.data.divisions
    }
}

export function submitDivision(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(divisionUrl(id), { division: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(divisionsUrl, { division: values })
            return response.data
        }
    }
}

export function deleteDivision(divisionId) {
    return async (dispatch, getState) => {
        client
            .delete(`${divisionUrl(divisionId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(divisionsSlice.actions.deleteDivision(divisionId))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

// TODO: will need to scope this as well
// Updated 11/3/21 Added a new fetchClientDivisionSelectOptions function.
// will need to update this for consistency
export function fetchDivisionSelectOptions(clientId, searchTerm = "") {
    return async (dispatch, getState) => {
        client.get(`${divisionSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
            dispatch(setDivisionSelectOptions(response.data.divisions))
        })
    }
}

export function fetchDivisionSelectOptionById(id, currentPath) {
    return async (dispatch, getState) => {
        client.get(`${divisionSelectOptionByIdUrl}?division_id=${id}`).then((response) => {
            dispatch(setDivisionSelectOptions(response.data.division))
        })
    }
}

export function fetchClientDivisionSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${clientDivisionSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setDivisionSelectOptions(response.data.divisions))
        })
    }
}

export function searchDivisions(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${divisionSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setDivisionSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterDivisions(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, divisionsScopedByClientUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setDivisions(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchBatchGroupSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${batchGroupSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setBatchGroupSelectOptions(response.data.batch_groups))
        })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${clientDivisionsCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectDivision = (state) => state.divisions.division
export const selectDivisions = (state) => state.divisions.divisions
export const selectTotal = (state) => state.divisions.total
export const selectPagination = (state) => state.divisions.pagination
export const selectLoading = (state) => state.divisions.loading
export const selectDivisionSelectOptions = (state) => state.divisions.divisionSelectOptions
export const selectDivisionSearch = (state) => state.divisions.divisionSearch
export const selectBatchGroupSelectOptions = (state) => state.divisions.batchGroupSelectOptions

export default divisionsSlice.reducer
