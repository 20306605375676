import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import moment from "moment"

import { Button, DatePicker } from "antd"

import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"

function ColumnDateFilter(props) {
    const focusInput = useRef(null)

    /////*** redux start ***/////
    const dispatch = useDispatch()
    /////*** redux end ***/////

    /////*** router start ***/////
    let { clientId } = useParams()
    /////*** router end ***/////

    /////*** component state start ***/////
    const [dateFilter, setDateFilter] = useState(["", ""])
    /////*** component state end ***/////

    /////*** component useEffects start ***/////
    useEffect(() => {
        /// we check to see if the current column in the table is already being filtered -- if it is, we assign it
        /// to the filterProps variable.
        let gteqValue = !!props.filters?.[`${props.filterType}_gteq`] ? moment(props.filters?.[`${props.filterType}_gteq`]) : null
        let lteqValue = !!props.filters?.[`${props.filterType}_lteq`] ? moment(props.filters?.[`${props.filterType}_lteq`]) : null
        let filterProps = [gteqValue, lteqValue]

        /// if the table's column is already being filtered and that filter isn't the same as "dateFilter,"
        /// we update searchString; this ensures that the current filter shows up as text inside the filter input when
        // this component (ColumnSearchFilter) loads
        if (!!filterProps && filterProps !== dateFilter) {
            setDateFilter(filterProps)
        }
    }, [props.filters]) // eslint-disable-line
    /////*** component useEffects end ***/////

    useEffect(() => {
        // console.log(props.visible)
        if (focusInput.current != null) {
            if (props.visible) {
                setTimeout(() => focusInput.current.focus(), 100)
            }
        }
    }, [props.visible]) // eslint-disable-line

    /////*** event handler methods start ***/////
    const handleSetFilters = (fromClear) => () => {
        /// create a copy of the filters object that's passed down from props -- which are a list of filters that have
        /// already been applied to the table
        let newFilters = { ...props.filters }

        /// if we're clearing the value of the filter, we want to make sure that the value we're assigning to the
        /// filter is an empty string instead of what could've previously been in state as "dateFilter" -- although
        /// we previously reset the state to an empty string in the handleClearFilter function, this function
        /// (handleSetFilters) will trigger before the component updates.
        let startDate = !!fromClear ? "" : moment(dateFilter[0]).format("L")
        let endDate = !!fromClear ? "" : moment(dateFilter[1]).format("L")

        /// update the newFilters object with the key value pairs representing the column name (passed down from props)
        /// as part of the key and the subsequent startDate or endDate variable as its value
        newFilters[`${props.filterType}_gteq`] = startDate
        newFilters[`${props.filterType}_lteq`] = endDate

        /// pass the newFilters object back up to the parent component's state, so it can manage all the active filters
        props.setFilters(newFilters)

        /// if there are any that values that exist in the newFilters object that are not empty strings, or we have a
        /// non-default sorting method selected, we send the whole object to be filtered/sorted.
        if (!allObjectValuesEmpty(newFilters) || props.sort !== { column: "name", direction: "asc" }) {
            return dispatch(
                props.filterAction({
                    clientId: clientId,
                    filters: newFilters,
                    pagination: props.pagination,
                    sort: props.sort,
                })
            )
        }

        /// if all of the values in the newFilters object that exist are empty strings (and not caught by the for loop
        /// above), it means that all the filters have been cleared and we want to reset the filtered list back to its
        /// original state via a method passed down from props.
        props.handleFetchInitial()
    }
    /////*** event handler methods end ***/////

    return (
        <div className="column-search-filter">
            <DatePicker.RangePicker ref={focusInput} onChange={setDateFilter} allowClear={false} allowEmpty={[true, true]} value={dateFilter} format={"MM/DD/YYYY"} disabledDate={props.disabledDate ? props.disabledDate : false} />
            <Button onClick={handleSetFilters(false)}> Filter </Button>
        </div>
    )
}

export default ColumnDateFilter
