import React from "react"
import ReportDivisions from "./fields/ReportDivisions"
import ReportFacilities from "./fields/ReportFacilities"
import ReportAccountingBatches from "./fields/ReportAccountingBatches"
import ReportDateRange from "./fields/ReportDateRange"

export default function Facilities() {
    return (
        <div>
            <ReportDateRange required={false} />
            <ReportAccountingBatches />
            <ReportDivisions />
            <ReportFacilities />
        </div>
    )
}
