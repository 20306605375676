import React, { useState } from "react"
import { Timeline, Button, Tooltip, Row, Col } from "antd"
import { CloseOutlined, EyeOutlined, CloudUploadOutlined, CloudSyncOutlined, LoginOutlined, DeleteOutlined } from "@ant-design/icons"
import AuditLogEventChangeset from "./AuditLogEventChangeset"
import moment from "moment"
import capitalize from "capitalize"

export default function AuditLogEvent({ version, user }) {
    const [open, setOpen] = useState(false)

    const actionIcon = () => {
        if (open) {
            return (
                <Tooltip title="Close" placement="right">
                    <Button danger type="primary" shape="circle" icon={<CloseOutlined />} onClick={() => setOpen(false)} style={{ float: "right" }} />
                </Tooltip>
            )
        } else {
            if (version.object_changes) {
                return (
                    <Tooltip title="View" placement="right">
                        <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => setOpen(true)} style={{ float: "right" }} />
                    </Tooltip>
                )
            }

            return null
        }
    }

    const eventIcon = () => {
        const sharedProps = { className: "timeline-icon" }

        switch (version.event) {
            case "update":
                return <CloudSyncOutlined {...sharedProps} />
            case "create":
                return <CloudUploadOutlined {...sharedProps} />
            case "login":
                return <LoginOutlined {...sharedProps} />
            case "destroy":
                return <DeleteOutlined {...sharedProps} />
            default:
                return <CloudUploadOutlined {...sharedProps} />
        }
    }

    return (
        <Timeline.Item key={version.id} dot={eventIcon()} label={moment(version.created_at).format("LT")}>
            <div className="card">
                <div className="card-body">
                    <div className="log-event">
                        <Row>
                            <Col span={20}>
                                <Row>
                                    <Col span={8}>{capitalize(version.event)}</Col>
                                    <Col span={12}>{version.item_type}</Col>
                                    <Col span={4}>{version.ip_address}</Col>
                                </Row>
                                <Row>
                                    <Col span={8}>{user ? user.email : "System"}</Col>
                                    <Col span={12}>{moment(version.created_at).format("MMMM Do YYYY, h:mm:ss a")}</Col>
                                    <Col span={4}>{moment(version.created_at).fromNow()}</Col>
                                </Row>
                            </Col>
                            <Col span={4}>{actionIcon()}</Col>
                        </Row>

                        {open && <AuditLogEventChangeset changes={version.object_changes} />}
                    </div>
                </div>
            </div>
        </Timeline.Item>
    )
}
