import React from "react"
import { Form, Row, Col, Input } from "antd"

export default function AdvancedSearchOptionsInputs() {
    return (
        <div>
            <h1 className="search-heading">Search Options</h1>

            <Row gutter={24}>
                {/* account number, meter number */}
                <Col span={6}>
                    <Form.Item label="Account Number" name="account_number">
                        <Input placeholder="Enter Account Number" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Account Name" name="account_name">
                        <Input placeholder="Enter Account Name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Meter Number" name="meter_number">
                        <Input placeholder="Enter Meter Number" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                {/* service address, zip code */}
                <Col span={18}>
                    <Form.Item label="Service Address" name="address">
                        <Input placeholder="Enter Address" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Zip Code" name="zip_code">
                        <Input placeholder="Enter Zip Code" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}
