import React from "react"
import { useSelector } from "react-redux"
import { Form, Row, Col, Input } from "antd"
import { selectLedgerFieldSelectOptions } from "../../financials/ledgerFields/ledgerFieldsSlice"

export default function AdvancedSearchLedgerAccount() {
    const ledgerFields = useSelector(selectLedgerFieldSelectOptions)

    return (
        <div>
            <h1 className="search-heading">Search Ledger Accounts</h1>

            {ledgerFields.map((lf) => (
                <Row gutter={24} key={lf.id}>
                    <Col span={24}>
                        <Form.Item label={lf.description} key={lf.id} name={`ledger_field_${lf.id}`}>
                            <Input placeholder="Search Accounts" />
                        </Form.Item>
                    </Col>
                </Row>
            ))}
        </div>
    )
}
