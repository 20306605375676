import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import { Table, Tooltip, Skeleton, Dropdown, Menu, Button } from "antd"
import { PlusOutlined, EditOutlined, FilterOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons"

import { fetchPaymentMethods, selectPaymentMethods, selectTotal, selectPagination, selectLoading, deletePaymentMethod } from "../paymentMethodsSlice"
import nodePath from "path"
import useConfirmationModal from "../../../alertsPanel/ShowConfirm"

export default function PaymentMethodTable({ scopedByClient }) {
const { url } = useRouteMatch()
const dispatch = useDispatch()

const payment_methods = useSelector(selectPaymentMethods)
const total = useSelector(selectTotal)
const pagination = useSelector(selectPagination)
const loading = useSelector(selectLoading)
const [loaded, setLoaded] = useState(false)

useEffect(() => {
    if (!loaded) {
        setLoaded(true)
        handleFetchInitialPaymentMethods()
    }
}, [loaded, dispatch, pagination]) 

const handleFetchInitialPaymentMethods = () => {
    dispatch(fetchPaymentMethods(pagination, nodePath.join(url, "")))
}

const showConfirm = useConfirmationModal();
const handleDeleteClick = (id) => {
    showConfirm(deletePaymentMethod, id);
};

const handleTableChange = (newPagination, sorter) => {
    dispatch(fetchPaymentMethods(newPagination, nodePath.join(url, "")))
}

return (
    <div className="muc-table card">
        <div className="card-body">
            <div>
                <div className="card-body-heading">
                    <div className="badged-header">
                        <h2>Payment Methods</h2>
                        <div className="badge">{total}</div>
                    </div>
                    <div className="actions">
                        <div className="table-action-links">
                            <Link to="/vendors/payment_methods/new">
                                <PlusOutlined />
                                Add New
                            </Link>
                        </div>
                    </div>
                </div>

                <Skeleton active loading={loading}>
                    <Table dataSource={payment_methods} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                        <Table.Column
                            title='Name'
                            key="name"
                            dataIndex="name"
                        />
                        <Table.Column
                            title='Description'
                            key="description"
                            dataIndex="description"
                        />
                        <Table.Column
                            title="Edit"
                            key="action"
                            render={(text, record) => (
                                <>
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item key="1" icon={<EditOutlined />}>
                                                    <Link to={`/vendors/payment_methods/${record.id}/edit`}>Edit Payment Method</Link>
                                                </Menu.Item>
                                                <Menu.Item key="2" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                    Delete Payment Method
                                                </Menu.Item>
                                            </Menu>
                                        }>
                                        <Button>
                                            Actions <DownOutlined />
                                        </Button>    
                                    </Dropdown>
                                </>
                            )}
                        />
                    </Table>
                </Skeleton>
            </div>
        </div>
    </div>
)
}