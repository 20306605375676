import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"

import { clientReportsUrl, clientReportUrl, generatedReportsUrl, generatedReportAssignablesUrl, generatedReportsTableUrl, generatedReportUrl } from "../../lib/endpoints"

export const reportsSlice = createSlice({
    name: "reports",
    initialState: {
        loading: false,
        reports: [],
        report: null,
        assignables: [],
        pagination: { current: 1, pageSize: 10 },
        generatedReports: [],
        generatedReportsTotal: 0,
        generatedReport: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setReports: (state, action) => {
            state.reports = action.payload
        },
        setReport: (state, action) => {
            state.report = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setGeneratedReports: (state, action) => {
            state.generatedReports = action.payload.generated_reports
            state.generatedReportsTotal = action.payload.total
        },
        setGeneratedReport: (state, action) => {
            state.generatedReport = action.payload
        },
        updateGeneratedReportFile: (state, action) => {
            try {
                state.generatedReports = state.generatedReports.map((gr) => {
                    if (gr.id === action.payload.generated_report_id) {
                        return {
                            ...gr,
                            file_url: action.payload.generated_report_file,
                            extra_file_url: action.payload.generated_report_extra_file,
                        }
                    } else {
                        return gr
                    }
                })
            } catch (e) {
                console.error("Report generation error: ", e)
                return state
            }
        },
    },
})

export const { setReports, setLoading, setReport, setPagination, setGeneratedReports, setGeneratedReport, updateGeneratedReportFile } = reportsSlice.actions

export const selectReports = (state) => state.reports.reports
export const selectLoading = (state) => state.reports.loading
export const selectReport = (state) => state.reports.report
export const selectPagination = (state) => state.reports.pagination
export const selectGeneratedReports = (state) => state.reports.generatedReports
export const selectGeneratedReportsTotal = (state) => state.reports.generatedReportsTotal
export const selectGeneratedReport = (state) => state.reports.generatedReport

export function fetchReports(clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(clientReportsUrl(clientId))
            .then(({ data }) => {
                // console.log(data)
                dispatch(setReports(data))
            })
            .catch((e) => {
                console.warn(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchReport(clientId, reportId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(clientReportUrl(clientId, reportId))
            .then(({ data }) => {
                dispatch(setReport(data))
            })
            .catch((e) => {
                console.warn(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchGeneratedReport(clientId, reportId, generatedReportId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(generatedReportUrl(clientId, reportId, generatedReportId))
            .then(({ data }) => {
                dispatch(setGeneratedReport(data))
            })
            .catch((e) => {
                console.warn(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateReport(clientId, reportId, values) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const response = await client.post(`${generatedReportsUrl(clientId, reportId)}?connection_id=${getState().app.uid}`, {
            generated_report: values,
        })

        dispatch(setLoading(true))

        return response
    }
}

export function updateGeneratedReport(clientId, reportId, generatedReportId, values) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const response = await client.patch(`${generatedReportUrl(clientId, reportId, generatedReportId)}?connection_id=${getState().app.uid}`, {
            generated_report: values,
        })

        dispatch(setLoading(true))

        return response
    }
}

export function fetchAssignables(clientId, reportId, type) {
    return async (dispatch, getState) => {
        const response = await client.get(generatedReportAssignablesUrl(clientId, reportId, type))

        return response
    }
}

export function fetchGeneratedReports(clientId, pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(`${generatedReportsTableUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`)
            .then(({ data }) => {
                dispatch(setPagination(pagination))
                dispatch(setGeneratedReports(data))
            })
            .catch((e) => {
                console.warn(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterGeneratedReports(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, generatedReportsTableUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setGeneratedReports(response.data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export default reportsSlice.reducer
