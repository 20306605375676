import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Form, Input, InputNumber, Table, Button, Tooltip, Skeleton, Checkbox, notification } from "antd"
import { DownloadOutlined, EditOutlined, SyncOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"
import { indexOf, filter } from "lodash"

import WarningChip from "../../bills/components/subcomponents/WarningChip"
import BillAccountLink from "../../../components/utilityComponents/linkGenerators/BillAccountLink"
import BillVendorPaymentLink from "../../../components/utilityComponents/linkGenerators/BillVendorPaymentLink"
import BillAccountingBatchLink from "../../../components/utilityComponents/linkGenerators/BillAccountingBatchLink"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"

import { requestNotification } from "../../../lib/notifications"
import { formatCurrency } from "../../../lib/utilities"
import { fetchGlobalBatchBills, fetchPaymentBatchNewBills, getPaymentBatchInfo, selectGlobalBatch, selectGlobalBatchBills, selectLoading, submitGlobalBatch } from "../globalBatchSlice"

export default function BillTable() {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const loading = useSelector(selectLoading)
    const { globalBatchId } = useParams()
    const dispatch = useDispatch()

    const bills = useSelector(selectGlobalBatchBills)
    const globalBatch = useSelector(selectGlobalBatch)

    const [loaded, setLoaded] = useState(false)
    const [checkedBills, setCheckedBills] = useState([])
    const [initialFormData, setInitialFormData] = useState({})
    const [tableKey, setTableKey] = useState(1)
    const [isOP, setIsOP] = useState(false)


    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(fetchGlobalBatchBills(globalBatchId))
        }
    }, [loaded, url, dispatch])

    useEffect(() => {
        setCheckedBills([])
        const newChecked = []
        bills.forEach((bill) => newChecked.push(bill.id))
        setCheckedBills(newChecked)
        form.setFieldsValue({
            id: globalBatchId,
            bills_attributes: bills?.map((bill) => {
                return { id: bill.id, amount_paid: bill.amount_paid ? bill.amount_paid : bill.amount_due, notes: bill.notes }
            }),
        })
    }, [bills])

    useEffect(() => {
        async function getBatchInfo() {
            const batchInfo = await dispatch(getPaymentBatchInfo(globalBatchId))
            const { payment_method, temp_pay_method } = batchInfo
            if(payment_method == 'OP' || temp_pay_method == "OP") setIsOP(true)
        }
        if (globalBatchId) {
            getBatchInfo()
        }
    }, [globalBatchId])

    const updateChecked = (e, record) => {
        if (e.target.checked) {
            setCheckedBills(checkedBills.concat(record))
        } else {
            setCheckedBills(checkedBills.filter((bill) => bill !== record))
        }
    }
    // console.log(globalBatch)

    const submitBills = (values) => {
        if(checkedBills.length == 0){
            notification.error({
                duration: 2,
                description: 'Batch must always be left with at least 1 bill.',
                message: "No bills selected"
            })
            return
        }
        const newBillValues = filter(values.bills_attributes, function (o) {
            return indexOf(checkedBills, o.id) >= 0
        })

        const response = dispatch(submitGlobalBatch({ bill_ids: checkedBills, bills_attributes: [...newBillValues] }, globalBatch.id, globalBatch.client_id, true))

        requestNotification(response)
    }

    const renderColumnHeaderTitle = (title, row = null, altTitle = null, columnClass = '') => {
        return <ColumnHeaderTitle row={row} title={title} altTitle={altTitle} columnClass={columnClass} />
    }

    const fetchNewBills = async () => {
        const { client_id, id, bills_due_date } = globalBatch
        const response = await dispatch(fetchPaymentBatchNewBills(client_id, id, bills_due_date))

        if (response.success) {
            notification.open({
                message: `Found ${response.new_bills} new bill(s)`,
                description: `Total: ${formatCurrency(response.total)}`,
                duration: 0.8,
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was a problem fetching new bills. Please try again.",
                duration: 0.8,
            })
        }
    }

    return (
        <Form form={form} initialValues={initialFormData} onFinish={submitBills} layout="vertical">
            <div className="muc-table card">
                <div className="card-body">
                    <div>
                        <div className="card-body-heading">
                            <div className="badged-header">
                                <h2>Bills</h2>
                                <div className="badge">{bills.length}</div>
                            </div>

                            <div className="actions">
                                <div className="table-action-links">
                                    {/* {
                                        <a href="#" onClick={() => fetchNewBills()}>
                                            <SyncOutlined spin={false} />
                                            Check for New Bills
                                        </a>
                                    } */}
                                    <a href={`/global_batch/${globalBatchId}/newBills`}>
                                        <PlusOutlined spin={false} />
                                        Add New Bills
                                    </a>

                                    {/* {
                                        <a href="javascript:;" onClick={() => console.log("TODO: Sync payments")}>
                                            <SyncOutlined spin={false} />
                                            Sync Vendor Payments
                                        </a>
                                    }
                                    <Button type="link" onClick={() => console.log("TODO: Create csv")}>
                                        <DownloadOutlined />
                                        Export
                                    </Button> */}
                                </div>
                            </div>
                        </div>

                        <Skeleton active loading={loading}>
                            <Table dataSource={bills} pagination={false} rowKey={(r) => r.id}>
                                <Table.Column
                                    title={<Checkbox onChange={(e) => (e.target.checked ? setCheckedBills(bills.map((bill) => bill.id)) : setCheckedBills([]))} />}
                                    key="in"
                                    dataIndex="in"
                                    render={(text, record) => (
                                        <Checkbox defaultChecked={true} checked={checkedBills.includes(record.id)} onChange={(e) => updateChecked(e, record.id)} disabled={globalBatch.closed} />
                                    )}
                                />
                                <Table.Column title="Division" key="division_name" dataIndex="division_name" />

                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("vendor_payment_full_name", row, "vendor payment")}
                                    key="vendor_payment_full_name"
                                    dataIndex="vendor_payment_full_name"
                                    // render={(text, record) => (
                                    //     <BillVendorPaymentLink
                                    //         paymentBatchId={record.paymentBatchId}
                                    //         utilityPaymentId={record.vendor_payment_id}
                                    //         utilityPaymentName={record.vendor_payment_full_name}
                                    //         clientId={record.client_id}
                                    //     />
                                    // )}
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.vendor_payment_full_name?.localeCompare(b.vendor_payment_full_name),
                                    }}
                                />

                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("vendor_payment_vendor_name", row, "vendor name")}
                                    key="vendor_payment_vendor_name"
                                    dataIndex="vendor_payment_vendor_name"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.vendor_payment_vendor_name?.localeCompare(b.vendor_payment_vendor_name),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("vendor_payment_payment_methods", row, "payment method")}
                                    key="vendor_payment_payment_methods"
                                    dataIndex="vendor_payment_payment_methods"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.vendor_payment_payment_methods?.localeCompare(b.vendor_payment_payment_methods),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("accounting_batch_batch_code", row, "batch code")}
                                    key="accounting_batch_batch_code"
                                    dataIndex="accounting_batch_batch_code"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.accounting_batch_batch_code?.localeCompare(b.accounting_batch_batch_code),
                                    }}
                                    render={(text, record) => (
                                        <BillAccountingBatchLink accountingBatchId={record.accounting_batch_id} accountingBatchName={record.accounting_batch_batch_code} clientId={record.client_id} />
                                    )}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("account_number", row)}
                                    key="account_number"
                                    dataIndex="account_number"
                                    showSorterTooltip={false}
                                    render={(text, record) => (
                                        <BillAccountLink accountType={record.account_type} accountId={record.account_id} accountName={record.account_name_with_number} clientId={record.client_id} />
                                    )}
                                    sorter={{
                                        compare: (a, b) => a.account_name?.localeCompare(b.account_name),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("date", row, "bill date")}
                                    key="date"
                                    dataIndex="date"
                                    render={(text, record) => moment(record.date).format("MM/DD/YYYY")}
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => moment(a.date) - moment(b.date),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("due_date", row)}
                                    key="due_date"
                                    dataIndex="due_date"
                                    render={(text, record) => moment(record.due_date).format("MM/DD/YYYY")}
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => moment(a.due_date) - moment(b.due_date),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("qc", row)}
                                    key="qc"
                                    dataIndex="qc"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.qc?.localeCompare(b.qc),
                                    }}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("balance_forward", row, null, "right_align_column")}
                                    key="balance_forward"
                                    dataIndex="balance_forward"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.balance_forward - b.balance_forward,
                                    }}
                                    align="right"
                                    render={(text, record, index) => formatCurrency(text)}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("current_charges", row, null, "right_align_column")}
                                    key="current_charges"
                                    dataIndex="current_charges"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.current_charges - b.current_charges,
                                    }}
                                    align="right"
                                    render={(text, record, index) => formatCurrency(text)}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("amount_due", row, null, "right_align_column")}
                                    key="amount_due"
                                    dataIndex="amount_due"
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => a.amount_due - b.amount_due,
                                    }}
                                    align="right"
                                    render={(text, record, index) => formatCurrency(text)}
                                />
                                <Table.Column
                                    title={(row) => renderColumnHeaderTitle("amount_paid", row, null, "right_align_column")}
                                    key={"amount_paid"}
                                    render={(text, record, index) => {
                                        let formValues = { ...form?.getFieldsValue() }
                                        let sortedIndex = index
                                        if (formValues?.bills_attributes?.length > 0) {
                                            sortedIndex = formValues.bills_attributes.findIndex((object) => object?.id === record?.id)
                                        }
                                        // console.log(sortedIndex)
                                        return {
                                            children: (
                                                <>
                                                    <Form.Item name={["bills_attributes", sortedIndex, "amount_paid"]} className="mb-0">
                                                        {/* Why is controls not working?... */}
                                                        <InputNumber precision={2} controls={false} disabled={record.paid} style={{ float: "right" }} />
                                                    </Form.Item>
                                                    <Form.Item name={["bills_attributes", sortedIndex, "id"]} hidden>
                                                        <Input />
                                                    </Form.Item>
                                                </>
                                            ),
                                        }
                                        // const obj = form.getFieldValue().bills_attributes.filter((b) => b.id === record.id)[0]
                                        // return (
                                        //     <InputNumber
                                        //         precision={2}
                                        //         controls={false}
                                        //         disabled={record.paid}
                                        //         onChange={(value) => handleBillAmountPaidChange(record?.id, value)}
                                        //         value={obj?.amount_paid}
                                        //     />
                                        // )
                                    }}
                                    showSorterTooltip={false}
                                    sorter={{
                                        compare: (a, b) => {
                                            let aCompare = !!a.amount_paid ? a.amount_paid : a.amount_due
                                            let bCompare = !!b.amount_paid ? b.amount_paid : b.amount_due

                                            return aCompare?.localeCompare(bCompare)
                                        },
                                    }}
                                />
                                {isOP && (
                                    <Table.Column
                                        title={(row) => renderColumnHeaderTitle("notes", row, null, "right_align_column")}
                                        key={"notes"}
                                        render={(text, record, index) => {
                                            let formValues = { ...form?.getFieldsValue() }
                                            let sortedIndex = index
                                            if (formValues?.bills_attributes?.length > 0) {
                                                sortedIndex = formValues.bills_attributes.findIndex((object) => object?.id === record?.id)
                                            }
                                            // console.log(sortedIndex)
                                            return {
                                                children: (
                                                    <>
                                                        <Form.Item name={["bills_attributes", sortedIndex, "notes"]} className="mb-0">
                                                            {/* Why is controls not working?... */}
                                                            <Input controls={false} style={{ float: "right" }} />
                                                        </Form.Item>
                                                        <Form.Item name={["bills_attributes", sortedIndex, "id"]} hidden>
                                                            <Input />
                                                        </Form.Item>
                                                    </>
                                                ),
                                            }
                                        }}
                                        showSorterTooltip={false}
                                        sorter={{
                                            compare: (a, b) => {
                                                let aCompare = !!a.amount_paid ? a.amount_paid : a.amount_due
                                                let bCompare = !!b.amount_paid ? b.amount_paid : b.amount_due

                                                return aCompare?.localeCompare(bCompare)
                                            },
                                        }}
                                    />
                                )}
                                <Table.Column
                                    title="Copy"
                                    key="copy"
                                    render={(text, record) => (
                                        <>
                                            {record.file_url && (
                                                <Button type="primary" size="small" onClick={() => window.open(record.file_url, "_blank")}>
                                                    <DownloadOutlined />
                                                </Button>
                                            )}
                                        </>
                                    )}
                                />
                                <Table.Column
                                    title="Status"
                                    key="flags"
                                    dataIndex="flags"
                                    render={(flags, record) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} childFlags={record.any_child_flags} />}
                                    width={50}
                                />
                                <Table.Column
                                    title="Edit"
                                    key="action"
                                    render={(text, record) => (
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={`/bills/${record.id}/edit`} target="_blank">
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                        </div>
                                    )}
                                />
                            </Table>

                            <br />

                            <Button type="primary" icon={<CloudUploadOutlined />} htmlType="submit">
                                Update Batch
                            </Button>
                        </Skeleton>
                    </div>
                </div>
            </div>
        </Form>
    )
}
